import { Grid, Typography } from "@mui/material"
import { CheckboxField, DatePickerField, SelectField } from "../../FormFields"
import CapAdresse from "../../CapAdresse"
import { FastField, useFormikContext } from "formik"
import { InputExample } from "../../FormFields/InputExample"
import RadioButtonExample from "../../FormFields/RadioButtonExample"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import SpecialisedEducation from "../../SpecialisedEducation/SpecialisedEducation"
import moment from "moment"
import { parseISO } from "date-fns"

const PersonalInformations = (props) => {

  const {
    formField: {
      account: {
        AcademicLevel__pc,
        BACYear__pc,
        CountryOfBirth__pc,
        FirstName,
        Grant_Student__c,
        LastName,
        Nationalite__c,
        Nationalite_2__c,
        Native_Language__pc,
        PersonBirthdate,
        PersonMobilePhone,
        Phone,
        Salutation,
        Section__pc,
        SectionOfBAC__pc,
        ShippingCity,
        ShippingCountry,
        ShippingPostalCode,
        ShippingStreet
      }
    }
  } = props

  const optionsAcademicLevel = useSelector(state => state.salesforce.academicLevels)
  const optionsCountries = useSelector(state => state.salesforce.countries)
  const optionsLanguages = useSelector(state => state.salesforce.languages)
  const optionsNationalities = useSelector(state => state.salesforce.nationalities)
  const optionsSectionOfBac = useSelector(state => state.salesforce.sectionOfBac)
  const opportunitiesLength = useSelector(state => state.salesforce.opportunities).length

  const sections = useSelector(state => state.salesforce.sections)
  const [optionsSections, setOptionsSections] = useState([])
  const [optionsNationalities2, setOptionsNationalities] = useState([])
  const [maxYear, setMaxYear] = useState(null) // @todo on l'utilise si on peut à l'ouverture du datepicker avoir une valeur par défaut
  const {
    values : {
      account: {
        AcademicLevel__pc: AcademicLevel,
        BACYear__pc: BACYear,
        Nationalite__c: Nationalite,
        Nationalite_2__c: Nationalite2,
        Section__pc: Section,
        SectionOfBAC__pc: SectionOfBAC
      }
    }
  } = useFormikContext()

  const formikProps = useFormikContext() // @todo dépend si on peut vider le champs section prorprement

  useEffect(() => {
    const nationalities = [...optionsNationalities]
    nationalities.unshift({ label: '--- Sélectionnez une nationalité ---', value: undefined })
    setOptionsNationalities(nationalities)
  }, [optionsNationalities])

  useEffect(() => {
    // formikProps.setFieldValue('account.Section__pc', '') // @todo trouver une solution pour reset proprement
    const options = sections.find(el => el.academicLevel === AcademicLevel)
    if (options !== undefined) {
      setOptionsSections(options.sections)
    }
  }, [AcademicLevel, sections])

  useEffect(() => {
    const date = moment()
    const year = parseISO(date.add('year', -15).format('YYYY-MM-DD'))
    console.log(moment(year).format('YYYY-MM-DD'))
    console.log(parseISO(moment(year).format('YYYY-MM-DD')))
    setMaxYear(year)
  }, [])

  const salutations = [
    { key: 'Madame', value: 'Mme'},
    { key: 'Monsieur', value: 'M.' }
  ]

  return (
    <div>
      <Typography gutterBottom mb={5} variant="h5">
        Mes informations personnelles
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <RadioButtonExample
            label={Salutation.label}
            name={Salutation.name}
            options={salutations}
            required
            row
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={FirstName.label} name={FirstName.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={LastName.label} name={LastName.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DatePickerField fullWidth label={PersonBirthdate.label} datemax={new Date()} name={PersonBirthdate.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsCountries} fullWidth label={CountryOfBirth__pc.label} name={CountryOfBirth__pc.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsNationalities} disabled={opportunitiesLength} disableValue={Nationalite2} fullWidth label={Nationalite__c.label} name={Nationalite__c.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsNationalities2} disableValue={Nationalite} displayEmpty={true} fullWidth label={Nationalite_2__c.label} name={Nationalite_2__c.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={Phone.label} name={Phone.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={PersonMobilePhone.label} name={PersonMobilePhone.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsAcademicLevel} disabled={opportunitiesLength} fullWidth label={AcademicLevel__pc.label} name={AcademicLevel__pc.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsSections} fullWidth label={Section__pc.label} name={Section__pc.name} />
        </Grid>
        {AcademicLevel === 'Terminale (BAC)' && Section === 'Générale' && <Grid item sm={12} xs={12}>
          <SpecialisedEducation formField={props.formField} />
        </Grid>}
        {AcademicLevel !== 'Terminale (BAC)' && <Grid item sm={6} xs={12}>
          <FastField component={InputExample} InputProps={{ inputProps: { min: 1970, max: 2021 } }} label={BACYear__pc.label} name={BACYear__pc.name} type="number" />
        </Grid>}
        {AcademicLevel !== 'Terminale (BAC)' && <Grid item sm={6} xs={12}>
          <SelectField data={optionsSectionOfBac} fullWidth label={SectionOfBAC__pc.label} name={SectionOfBAC__pc.name} />
        </Grid>}
        {SectionOfBAC === 'Générale' && BACYear >= 2021 && <Grid item sm={12} xs={12}>
          <SpecialisedEducation formField={props.formField} />
        </Grid>}
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsLanguages} fullWidth label={Native_Language__pc.label} name={Native_Language__pc.name} />
        </Grid>
      </Grid>
      <Typography gutterBottom variant="h6" sx={{ mb: 4, mt: 4 }}>
        Mon adresse postale
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsCountries} fullWidth label={ShippingCountry.label} name={ShippingCountry.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={ShippingStreet.label} minRows={2} multiline name={ShippingStreet.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={ShippingPostalCode.label} name={ShippingPostalCode.name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FastField component={InputExample} label={ShippingCity.label} name={ShippingCity.name} />
        </Grid>
        {/* <CapAdresse formField={props.formField} /> */}
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <CheckboxField label={Grant_Student__c.label} name={Grant_Student__c.name} />
        </Grid>
      </Grid>
    </div>
  )
}

export default PersonalInformations
