import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { cyan } from "@mui/material/colors"
let theme = createTheme(adaptV4Theme({
  status: {
    danger: '#e53e3e'
  },
  palette: { mode: 'dark', primary: cyan, secondary: cyan, neutral: { main: '#5c6ac4' } }
}))
theme = responsiveFontSizes(theme)
const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    //   width: 600
    // }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(36),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3)
    // }
  }
}))

export { theme, useStyles }
