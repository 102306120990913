import Course from "./Course"
import PersonnalInformations from "./PersonalInformations"

const Summary = () => {
  return (
    <>
      <PersonnalInformations />
      <Course />
    </>
  )
}

export default Summary
