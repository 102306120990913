import * as Yup from "yup"
import ionisConnectFormModel from "./ionisConnectFormModel"

const {
  formField: {
    password,
    passwordConfirm
  }
} = ionisConnectFormModel

const validationSchemaModify =
  Yup.object().shape({
    [password.name]: Yup.string().trim().required(`${password.requiredErrorMsg}`),
    [passwordConfirm.name]: Yup.string().trim().required(`${passwordConfirm.requiredErrorMsg}`).oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas.'),
  })

export default validationSchemaModify
