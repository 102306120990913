import { Alert, Button, CircularProgress, Grid, Snackbar } from "@mui/material"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Salesforce from "../../../services/Salesforce"
import { InputField } from "../../FormFields"
import useStyles from "../../Portal/styles"
import { updateIonisConnect } from "../../../Store/salesforceSlice"
import formInitialValues from "../FormModel/formInitialValues"
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel"
import validationSchemaModify from "../FormModel/validationSchemaModify"
import validationSchemaModifyConnected from "../FormModel/validationSchemaModifyConnected"

const ModifyPassword = ({ token }) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const payload = useSelector(state => state.salesforce.token)
  const isLogged = useSelector(state => state.salesforce.isLogged)
  const email = useSelector(state => state.salesforce.account.PersonEmail)
  const division = useSelector(state => state.salesforce.division)
  const validation = isLogged ? validationSchemaModifyConnected : validationSchemaModify
  const { formField } = ionisConnectFormModel
  const {
    currentPassword,
    password,
    passwordConfirm
  } = formField
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')
  const [error, setError] = useState('')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    dispatch(updateIonisConnect(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLogged) {
      Salesforce.verifySecurityToken(payload, token).catch(e => setError('Le lien de réinitialisation de votre mot de passe est incorrect ou a expiré.'))
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  async function _handleSubmit(values, actions) {
    console.log(values)
    const currentPassword = values.currentPassword
    const password = values.password
    const modifyPassword = await Salesforce.updatePassword(payload, password, token, email, currentPassword, division)
    console.log(modifyPassword)
    setMessage(modifyPassword.message)
    setOpen(true)
    if (modifyPassword.success) {
      setSeverity('success')
    } else {
      setSeverity('error')
    }
  }

  return (
    <>
      {error !== '' && !isLogged &&

      <div>
        <Alert elevation={6} severity="error" sx={{ mb: 2 }} variant="filled">
          {error}
        </Alert>

        <Button className={classes.button} component={Link} to="/connexion" variant="contained">Se connecter</Button>
        <Button className={classes.button} component={Link} to="/mot-de-passe-oublie" variant="outlined">Réinitialiser son mot de passe</Button>
      </div>
      }
      {((error === '' && !isLogged) || isLogged) && <Formik
        initialValues={formInitialValues}
        onSubmit={_handleSubmit}
        validationSchema={validation}
      >
        {({isSubmitting}) => (
          <Form id="modify-password">
            <Grid container spacing={3}>
              {isLogged && <Grid item xs={12}>
                <InputField fullWidth label={currentPassword.label} name={currentPassword.name} type="password" />
              </Grid>}
              <Grid item xs={12}>
                <InputField fullWidth label={password.label} name={password.name} type="password" />
              </Grid>
              <Grid item xs={12}>
                <InputField fullWidth label={passwordConfirm.label} name={passwordConfirm.name} type="password" />
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <div className={classes.wrapper}>
                {(severity === 'success' || isLogged) && <Button className={classes.button} component={Link} to={isLogged ? "/" : "/connexion"} variant={isLogged ? "text" : "contained"}>{isLogged ? 'Revenir au formulaire' : 'Se connecter'}</Button>}
                {severity !== 'success' && <Button color="primary" className={classes.button} disabled={isSubmitting} type="submit" variant="contained">
                  Réinitialiser mon mot de passe
                </Button>}
                {isSubmitting && (
                  <CircularProgress className={classes.buttonProgress} size={24} />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>}
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} onClose={handleClose} open={open}>
        <Alert elevation={6} onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ModifyPassword
