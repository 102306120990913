import { Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Diplomas from "../../Diplomas"
import { SelectField } from "../../FormFields"
import Languages from "../../Languages"
import WorkExperience from "../../WorkExperience"

const Course = (props) => {
  return (
    <>
      <Typography gutterBottom variant="h2">Mon parcours</Typography>
      <Typography gutterBottom variant="h6">Mes derniers diplômes</Typography>
      <Diplomas />
      <Typography gutterBottom variant="h6" sx={{ mt: 2 }}>Mon expérience professionnelle</Typography>
      <WorkExperience />
      <Languages formField={props.formField} />
    </>
  )
}

export default Course
