import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import useStyles from "../Portal/styles"

const ConfirmationApplication = ({ openPopup, closeOpportunity, hasErrors }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // const handleClickOpen = () => {
  //   setOpen(true)
  // }

  const handleClose = () => {
    setOpen(false)
    closeOpportunity(false)
  }

  const handleCloseOpportunity = () => {
    // setOpen(false)
    setIsSubmitting(true)
    closeOpportunity(true)
    // setIsSubmitting(false)
  }

  useEffect(() => {
    if (openPopup !== undefined) {
      setOpen(openPopup)
    }
  }, [openPopup])

  useEffect(() => {
    if (hasErrors) {
      console.log('ya des  erreurs on vire le submit')
      setIsSubmitting(false)
    }
  }, [hasErrors])

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmation de votre candidature
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            En cliquant sur le bouton <strong>Confirmer ma candidature</strong>, vous ne pourrez plus modifier vos informations personnelles ainsi que votre candidature. Vous pourrez cependant candidater à d'autres programmes et gérer vos justificatifs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseOpportunity} variant="contained">
            Confirmer ma candidature
          </Button> */}
          <Button className={classes.button} onClick={handleClose}>Modifier ma candidature</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} disabled={isSubmitting} onClick={handleCloseOpportunity} variant="contained">
                Confirmer ma candidature
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationApplication
