import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import ExpandMoreIcon  from "@mui/icons-material/ExpandMore"
import { useSelector } from "react-redux"
import moment from "moment"
import portalFormModel from "../Portal/FormModel/portalFormModel"

const PersonnalInformations = () => {

  const {
    formField: {
      account: {
        AcademicLevel__pc,
        BACYear__pc,
        CountryOfBirth__pc,
        FirstName,
        Grant_Student__c,
        LastName,
        Nationalite__c,
        Nationalite_2__c,
        Native_Language__pc,
        PersonBirthdate,
        PersonMobilePhone,
        Phone,
        Salutation,
        Section__pc,
        SectionOfBAC__pc,
        ShippingCity,
        ShippingCountry,
        ShippingPostalCode,
        ShippingStreet
      }
    }
  } = portalFormModel

  const account = useSelector(state => state.salesforce.account)

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          aria-controls={`panel1a-content`}
          // id="panel1a-header"
          id={`panel1a-header`}
        >
          Mes informations personnelles
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{Salutation.label}</Typography>
              {account.Salutation}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{FirstName.label}</Typography>
              {account.FirstName}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{LastName.label}</Typography>
              {account.LastName}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{PersonBirthdate.label}</Typography>
              {moment(account.PersonBirthdate).format('DD/MM/YYYY')}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{CountryOfBirth__pc.label}</Typography>
              {account.CountryOfBirth__pc}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{Nationalite__c.label}</Typography>
              {account.Nationalite__c}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{Nationalite_2__c.label}</Typography>
              {account.Nationalite_2__c}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{Phone.label}</Typography>
              {account.Phone}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{PersonMobilePhone.label}</Typography>
              {account.PersonMobilePhone}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{AcademicLevel__pc.label}</Typography>
              {account.AcademicLevel__pc.split('_')[0]}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{Section__pc.label}</Typography>
              {account.Section__pc}
            </Grid>
            {account.Section__pc === 'Générale' && <Grid item sm={12} xs={12}>
              <Typography variant="subtitle2">Enseignements de spécialité</Typography>
            </Grid>}
            {account.AcademicLevel__pc !== 'Terminale (BAC)_ISG BS France' && <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{SectionOfBAC__pc.label}</Typography>
              {account.SectionOfBAC__pc}
            </Grid>}
            {account.AcademicLevel__pc !== 'Terminale (BAC)_ISG BS France' && <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{BACYear__pc.label}</Typography>
              {account.BACYear__pc}
            </Grid>}
            <Grid item xs={12}>
              <Typography variant="subtitle2">{Native_Language__pc.label}</Typography>
              {account.Native_Language__pc}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Mon adresse postale</Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{ShippingCountry.label}</Typography>
              {account.ShippingCountry}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{ShippingStreet.label}</Typography>
              {account.ShippingStreet}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{ShippingPostalCode.label}</Typography>
              {account.ShippingPostalCode}
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="subtitle2">{ShippingCity.label}</Typography>
              {account.ShippingCity}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{Grant_Student__c.label}</Typography>
              {account.Grant_Student__c === true ? 'Oui' : 'Non'}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PersonnalInformations
