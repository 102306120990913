import {
  Button,
  Grid,
  Icon,
  Tooltip,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Typography,
  Alert,
  AlertTitle,
  Box,
  Snackbar,
  CircularProgress,
  Backdrop,
  useMediaQuery,
} from "@mui/material";

import { styled, useTheme } from '@mui/material/styles';
import useStyles from "../styles"
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, useFormikContext, ErrorMessage } from "formik";
import Salesforce from "../../../services/Salesforce";

const SupportingDocument = (props) => {

  const classes = useStyles()
  const account = useSelector((state) => state.salesforce.account);
  let opportunity = useSelector((state) => state.salesforce.opportunity);
  const payload = useSelector((state) => state.salesforce.token); //payload = token
  const hasOneOppyCompleted = useSelector(state => state.salesforce.hasOneOppyCompleted);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [filesToGet, setFilesToGet ] = useState(["Pièce d'identité", "Bulletins de notes année N", "Bulletins de notes année N-1", "Diplômes obtenus", "CV", "Lettre de motivation", "Avis d'attribution de bourse"].sort());
  const [gotFiles, setGotFiles ] = useState([]);
  const [filesBySupportingDocument, setFilesBySupportingDocument ] = useState(new Map([
    ["Pièce d'identité", 0],
    ["Bulletins de notes année N", 0],
    ["Bulletins de notes année N-1", 0],
    ["Diplômes obtenus", 0],
    ["CV", 0],
    ["Lettre de motivation", 0],
    ["Avis d'attribution de bourse", 0],
  ]));
  const [files, setFilesValue] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false)
  const [fieldArrayError, setFieldArrayError] = useState([]);
  const [errors, setErrors] = useState(false);
  const [selectedFile, setSelectedFile] = useState("Pièce d'identité");

  const mapSupportingDocument = new Map([
    ["Pièce d'identité", "Identity Document"],
    ["Bulletins de notes année N", "Grades year N"],
    ["Bulletins de notes année N-1", "Grades year N-1"],
    ["Diplômes obtenus", "diplomas obtained"],
    ["CV", "CV"],
    ["Lettre de motivation", "Motivation letter"],
    ["Avis d'attribution de bourse", "Notification grant scholarship"],
  ]);

  const formikProps = useFormikContext();

  useEffect(() => {
    async function getAttachements(mounted) {
      if( !mounted ) {
        console.log("get attachement account :", account);
        let supportingDocument;
        if ( formikProps.initialValues.SupportingDocument__c.length > 0 ) {
          supportingDocument = formikProps.initialValues.SupportingDocument__c;
          let filesToGetList = filesToGet;
          let gotFilesList = gotFiles;
          let filesList = files;
          // if( hasOneOppyCompleted ) {
          //   for( let i = supportingDocument.length - 1; i >= 0; i-- ) {
          //     if( supportingDocument[i].supportingDocument === "CV" || supportingDocument[i].supportingDocument === "Lettre de motivation") {
          //       let index = supportingDocument.indexOf(supportingDocument[i].supportingDocument);
          //       supportingDocument.splice(index, 1);
          //     }
          //   }
          // }
          for( let i = 0; i < supportingDocument.length; i++ ) {
            if( filesToGetList.includes( supportingDocument[i].supportingDocument ) ) {
              let index = filesToGetList.indexOf(supportingDocument[i].supportingDocument);
              filesToGetList.splice(index, 1);
              gotFilesList.push(supportingDocument[i].supportingDocument);
              for( let j = 0; j < supportingDocument[i].files.length; j++ ) {
                filesList.push(supportingDocument[i].files[j]);
              }
            }
          }
          console.log("filesToGetList");
          console.log(filesToGetList);
          console.log("gotFilesList");
          console.log(gotFilesList);
          console.log("filesList");
          console.log(filesList);
          setFilesToGet(filesToGetList.sort());
          setFilesValue(filesList);
          if ( filesList.length > 0 ) {
            setButtonDisabled(false);
          }
          if( filesToGetList.length === 0 ) {
            setSelectedFile(filesToGetList);
          }
          else {
            setSelectedFile(filesToGetList[0]);
          }
        }
        mounted = true;
      }
    }
    let mounted = false
    getAttachements(mounted);
  }, [])

  useEffect(()=> {
    if( hasOneOppyCompleted ) {
      setFilesToGet(["CV", "Lettre de motivation"]);
      setSelectedFile("CV");
    }
  },[])

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackOpen(false)
  }

  const handleChange = (event) => {
    console.log('***************************')
    console.log(event)
    setSelectedFile(event.target.value);
  };

  const uploadFiles = async () => {
    setButtonDisabled(true);
    setLoading(true);
    console.log("uploadFiles");
    console.log(formikProps);
    formikProps.setFieldValue('account.Reports_Cards__c', true, false);
    formikProps.setFieldValue('account.Copy_of_Diploma__c', true);
    formikProps.setFieldValue('account.ID_Proof__c', true);
    formikProps.submitForm();
    while(fieldArrayError.length > 0) {
      fieldArrayError.pop();
    }
    setFieldArrayError(fieldArrayError);
    console.log("formikProps.errors");
    console.log(formikProps.errors);
    console.log(formikProps.errors.SupportingDocument__c);
    console.log(formikProps.errors.account);
    if (typeof formikProps.errors.SupportingDocument__c !== 'undefined') {
      if ( typeof formikProps.errors.SupportingDocument__c === 'object' ) {
        for ( let i = 0; i < formikProps.errors.SupportingDocument__c.length; i++ ) {
          if ( typeof formikProps.errors.SupportingDocument__c[i] !== 'undefined' ) {
            fieldArrayError.push({
              name : formikProps.values.SupportingDocument__c[i].supportingDocument,
              error : formikProps.errors.SupportingDocument__c[i].files
            });
          }
        }
        setFieldArrayError(fieldArrayError);
        setButtonDisabled(false);
        setLoading(false);
      }
      else if ( typeof formikProps.errors.SupportingDocument__c === 'string' ) {
        fieldArrayError.push({ error : formikProps.errors.SupportingDocument__c });
        setFieldArrayError(fieldArrayError);
        setButtonDisabled(false);
        setLoading(false);
      }
    }
    else if ( gotFiles.length === 0 ) {
      fieldArrayError.push({ error : "Les 4 différents types de pièces jointe doivent être présent"});
      setFieldArrayError(fieldArrayError);
      setButtonDisabled(false);
      setLoading(false);
    }
    else {
      if( account.Grant_Student__c && filesToGet.includes("Avis d'attribution de bourse") ) {
        fieldArrayError.push({ error : "L'avis d'attribution de bourse est obligatoire" });
        setFieldArrayError(fieldArrayError);
        setButtonDisabled(false);
        setLoading(false);
      }
      else {
        let updateAttachmentAccount;
        let renamedFile = null;
        let newNameFile;
        let filesForAttachment = [];
        let filesToKeepFromSalesforce = [];
        formikProps.setFieldValue('opportunity.CV__c', false, false)
        formikProps.setFieldValue('opportunity.MotivationLetter__c', false, false)
        formikProps.setFieldValue('account.Grant_Proof__c', false, false)
        for ( let i = 0; i < gotFiles.length; i++ ) {
          for( let j = 0; j < files.length; j++ ) {
            if( files[j].fileValue === gotFiles[i]) {
              console.log(files[j].fileValue)
              if (files[j].fileValue === 'CV') {
                formikProps.setFieldValue('opportunity.CV__c', true, false)
              }
              if (files[j].fileValue === 'Lettre de motivation') {
                formikProps.setFieldValue('opportunity.MotivationLetter__c', true, false)
              }
              if (files[j].fileValue === 'Avis d\'attribution de bourse') {
                formikProps.setFieldValue('account.Grant_Proof__c', true, false)
              }
              if ( typeof files[j].Id !== 'undefined' ) {
                console.log(files[j].Id);
                filesToKeepFromSalesforce.push(files[j]);
              }
              else {
                newNameFile = await changeNameFile(gotFiles[i], files[j].type);
                renamedFile = await new File([files[j]], newNameFile, {type: files[j].type});
                renamedFile.fileValue = gotFiles[i];
                filesForAttachment.push(renamedFile);
              }
            }
          }
        }
        console.log("filesForAttachment");
        console.log(filesForAttachment);
        console.log(filesToKeepFromSalesforce);
        if( hasOneOppyCompleted && props.opportunity !== null) {
          opportunity = props.opportunity;
        }
        let deletedFiles = await Salesforce.deleteAttachmentToSobject(payload, filesToKeepFromSalesforce, account, opportunity);
        console.log("deletedFiles");
        console.log(deletedFiles);
        updateAttachmentAccount = await Salesforce.addAttachmentToSobject(
          payload,
          filesForAttachment,
          account,
          opportunity
        ).catch((e) => {
          console.log(e);
          setErrors(true)
        });
        console.log("updateAttachmentAccount");
        console.log(updateAttachmentAccount);
        if ( updateAttachmentAccount ) {
          setSnackOpen(true);
        }
        if ( hasOneOppyCompleted && props.opportunity !== null ) {
          props.handleClose();
        }
      }
    }
  };

  const changeNameFile = async (fileName, fileType) => {
    let englishName = mapSupportingDocument.get(fileName);
    let numberOfFile = filesBySupportingDocument.get(fileName);
    filesBySupportingDocument.set(fileName, numberOfFile + 1);
    setFilesBySupportingDocument(filesBySupportingDocument);
    let newNameFile;
    if( fileName === "CV" || fileName === "Lettre de motivation") {
      if( hasOneOppyCompleted && props.opportunity !== null) {
        opportunity = props.opportunity;
      }
      newNameFile = `${account.LastName}_${account.FirstName}_${opportunity.Id}_${englishName}_${numberOfFile + 1}`.replace(/\s+/g, '').toUpperCase();
    }
    else {
      newNameFile = `${account.LastName}_${account.FirstName}_${account.Id}_${englishName}_${numberOfFile + 1}`.replace(/\s+/g, '').toUpperCase();
    }
    console.log(newNameFile);
    return newNameFile;
  };

  const Input = styled('input')({
    display: 'none',
  });

  const doneIconStyle = {
    color:'green',
    marginLeft: '10px',
    marginRight: '8px'
  }

  const deleteIconStyle = {
    cursor : 'pointer',
    marginTop: '17px',
    marginLeft: '5px'
  }

  return (
    <>
    <Grid ml={2}  mt={2} container spacing={2}>
      <Icon >file_present</Icon>
      <Typography gutterBottom ml={2} mb={5} variant="h5">
        Pièces Justificatives
      </Typography>
    </Grid>

      {errors && (
        <Box mt={2}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>Une erreur est survenue</AlertTitle>
            Une erreur est survenue lors de l'envoi des fichiers. Veuillez
            réessayer.
          </Alert>
        </Box>
      )}
      {fieldArrayError.length > 0 && (
        <Box mt={2}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>Une ou des erreur(s) doivent être résolu :</AlertTitle>
            <ul>
              { fieldArrayError.map((errorObject, id) => {
                console.log("fieldArrayError");
                console.log(fieldArrayError);
                  return (
                    <>
                    { typeof errorObject.name !== 'undefined' ?
                      <li key={id}>{errorObject.error} pour l'élément : {errorObject.name}</li>
                      :
                      <li key={id}>{errorObject.error}</li>
                    }
                    </>
                  )
                })
              }
            </ul>
          </Alert>
        </Box>
      )}
      <Grid container>
        <Grid item sm={6} xs={12}>
          <h3>La liste des pièces justificatives</h3>
          <ul>
            <li>La photocopie d'une pièce d'identité (en cours de validité)<strong> obligatoire</strong></li>
            <li>Les bulletins de notes de l'année en cours (N) et de l'année N-1<strong> obligatoire</strong></li>
            <li>La photocopie des diplômes obtenus (Baccalauréat compris) et des crédits ECTS validés au titre des études supérieures <i>(pour admissions parallèles en 2e, 3e, 4e ou 5e année)</i><strong> obligatoire</strong></li>
            <li>Un CV et une lettre de motivation (optionnel)</li> {/* <i>(exclusivement pour une intégration du programme Masters of Science et MBA)</i> */}
            <li>Pour les boursiers, présentation d'un justificatif (optionnel)</li>
          </ul>
        </Grid>
        <Grid item sm={6} xs={12} mb={2}>
          <FormControl component="fieldset">
            <FormLabel sx={{ marginBottom : '10px' }} component="legend">Choix de la pièces jointes à ajouter</FormLabel>
            <InputLabel id="demo-simple-select-label">pièces jointes</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedFile}
              label="pièces jointes"
              onChange={handleChange}
            >
              { filesToGet.length > 0 &&
                  filesToGet.map(( fileName, ind ) => {
                    return(
                      <MenuItem key={ind} value={fileName}> {fileName}</MenuItem>
                    )
                  })
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FieldArray name="SupportingDocument__c">
        {({ form, ...fieldArrayHelpers }) => {
          const onChangeFileValue = (event) => {
            event.preventDefault();
            setButtonDisabled(false);
            console.log("onChangeFileValue");
            let filesList = files;
            let filesToAdd = [];
            for (let i = 0; i < event.target.files.length; i++) {
              event.target.files[i].fileValue = selectedFile;
              filesList.push(event.target.files[i]);
              filesToAdd.push(event.target.files[i]);
            }
            console.log("filesToAdd");
            console.log(filesToAdd);
            let supportingDocumentToAdd = {
              supportingDocument : selectedFile,
              files : filesToAdd
            }
            console.log("supportingDocumentToAdd");
            console.log(supportingDocumentToAdd);
            if (selectedFile === 'Avis d\'attribution de bourse') {
              formikProps.setFieldValue('account.Grant_Proof__c', true, false)
            }
            console.log("files");
            console.log(filesList);
            let filesToGetList = filesToGet;
            let gotFilesList = gotFiles;
            let index = filesToGetList.indexOf(selectedFile);
            filesToGetList.splice(index, 1);
            gotFilesList.push(selectedFile);
            setFilesToGet(filesToGetList.sort());
            setGotFiles(gotFilesList);
            setFilesValue(filesList);
            console.log("filesToGetList");
            console.log(filesToGetList);
            if( filesToGetList.length === 0 ) {
              setSelectedFile(filesToGetList);
            }
            else {
              setSelectedFile(filesToGetList[0]);
            }
            fieldArrayHelpers.push(supportingDocumentToAdd);
            console.log("fieldArrayHelpers");
            console.log(fieldArrayHelpers);
          }
          const removeFile = (e, fileIndex, value) => {
            //TODO : problème avec la multi-seletion de fichiers on les supprime pas tous faire comme pour dans la classe Salesforce je pense !!
            e.preventDefault();
            console.log(value);
            if (value === 'Avis d\'attribution de bourse') {
              formikProps.setFieldValue('account.Grant_Proof__c', false, false)
            }
            let filesList = files;
            let filesToGetList = filesToGet;
            let gotFilesList = gotFiles;
            let indexAttachmentsToRemove = [];
            for ( let i = 0; i < filesList.length; i++ ) {
              if ( filesList[i].fileValue === value ) {
                console.log(filesList[i].fileValue);
                indexAttachmentsToRemove.push(i);
              }
            }
            indexAttachmentsToRemove.sort(function(a, b) {
              return b- a;
            });
            console.log("indexAttachmentsToRemove");
            console.log(indexAttachmentsToRemove);
            for(let j = 0; j < indexAttachmentsToRemove.length; j++) {
              filesList.splice(indexAttachmentsToRemove[j], 1);
            }
            let index = gotFilesList.indexOf(value);
            gotFilesList.splice(index, 1);
            console.log(" remove filesList");
            console.log(filesList);
            filesToGetList.push(value);
            console.log(filesToGetList);
            setFilesToGet(filesToGetList.sort());
            setGotFiles(gotFilesList);
            setFilesValue(filesList);
            setSelectedFile(filesToGetList[filesToGetList.length - 1]);
            fieldArrayHelpers.remove(fileIndex);
            if( filesList.length === 0 ) {
              setButtonDisabled(true);
            }
          }
          return(
            <>
              {/* <Box mt={2}>
                <ErrorMessage name='SupportingDocument__c' render={msg => <Alert severity="error">{msg}</Alert>} />
              </Box> */}
              <Grid sx={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                <label htmlFor="contained-button-file">
                    <Input accept="image/*,.pdf" id="contained-button-file" multiple type="file"  onChange={onChangeFileValue}/>
                    { filesToGet.length !== 0 &&
                      <Tooltip title="Vous pouvez sélectionner plusieurs fichiers en même temps en laissant appuyez 'ctl' ou 'cmd' en choissant vos fichiers" placement="bottom-end">
                        <Button sx={{ marginLeft : '10px' }} variant="contained" component="span">
                          Ajouter une ou des pièce(s) jointe(s)
                        </Button>
                      </Tooltip>
                    }
                  </label>
              </Grid>
              <Grid container>
                <Typography sx={{ mt: 4, mb: 2, width : '100%', textAlign : 'center' }} variant="h6" component="div">
                  Les pièces ajoutées
                </Typography>
                <List dense={true} xs={12}>
                  { form.values.SupportingDocument__c &&
                    form.values.SupportingDocument__c.map((formFile, index) => {
                      // console.log("form.values.SupportingDocument__c");
                      // console.log(file);
                      return (
                        <ListItem key={formFile.supportingDocument} sx={{mr: 2}}>
                          <Icon style={doneIconStyle}>done</Icon>
                          <ListItemText sx={matches ? { width: '100%'} : { width: '150px', overflow : 'hidden'}}
                            primary={formFile.supportingDocument}
                            secondary={ files.map((file, inx) => {
                              // console.log("$$file");
                              // console.log(file);
                              return (
                                <>
                                { file.fileValue === formFile.supportingDocument && <span key={file.name}>{file.name}<br/></span> }
                                </>
                              )
                            })}
                          />
                          { ( !hasOneOppyCompleted ||  ( hasOneOppyCompleted && ( formFile.supportingDocument === 'CV' || formFile.supportingDocument === 'Lettre de motivation' ) ) ) &&
                            <ListItemIcon>
                              <p ></p>
                              <Icon style={deleteIconStyle} onClick={(e) => removeFile(e, index, formFile.supportingDocument)}>delete</Icon>
                            </ListItemIcon>
                          }
                        </ListItem>
                      )
                    })
                  }
                </List>
              </Grid>
            </>
          )
        }}
      </FieldArray>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <div className={classes.buttons}>
          <div style={{display:'flex', justifyContent:'end'}} className={classes.wrapper}>
            <Button variant="contained" disabled={buttonDisabled} onClick={uploadFiles}>
              Envoyer les fichiers
            </Button>
            { loading && <CircularProgress className={classes.buttonProgress} size={24} style={{top: '20%'}}/> }
          </div>
        </div>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <div className={classes.buttons}>
          <div style={{display:'flex', justifyContent:'end'}} className={classes.wrapper}>
            <Button variant="contained" disabled={buttonDisabled} onClick={uploadFiles}>
              Envoyer les fichiers
            </Button>
            { loading && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                          <CircularProgress color="inherit" />
                         </Backdrop>
            }
          </div>
        </div>
      </Box>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} onClose={handleCloseSnack} open={snackOpen}>
        <Alert elevation={6} onClose={handleCloseSnack} severity="success">Tous les fichiers ont été envoyés avec succès</Alert>
      </Snackbar>
    </>
  );
};

export default SupportingDocument;
