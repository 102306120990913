import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material"
import { Alert } from '@mui/material';
import { Form, Formik } from "formik"
import { CheckboxField, InputField, SelectField } from "../../FormFields"
import validationSchema from "../FormModel/validationSchema"
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel"
import formInitialValues from "../FormModel/formInitialValues"
import useStyles  from "../styles"
import { useSelector, useDispatch } from "react-redux"
import Salesforce from "../../../services/Salesforce"
import { updateIonisConnect } from "../../../Store/salesforceSlice"
import { useEffect } from "react"
import Cookie from "js-cookie"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"

const Registration = () => {
  const classes = useStyles()
  const { formId, formField } = ionisConnectFormModel
  const {
    academicLevel,
    cgu,
    email,
    firstName,
    lastName,
    nationality,
    password,
    passwordConfirm
  } = formField

  const history = useHistory()
  const payload = useSelector(state => state.salesforce.token)
  const division = useSelector(state => state.salesforce.division)
  const optionsAcademicLevels = useSelector(state => state.salesforce.academicLevels)
  const optionsNationalities = useSelector(state => state.salesforce.nationalities)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateIonisConnect(false))
    // console.log('test', Cookie.get('ISG_infos'), JSON.parse(Cookie.get('ISG_infos')))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = async (values, actions) => {
    const user = await Salesforce.checkUser(payload, values.email, division)
    console.log(user)
    if (!user.exist) {
      // Création du compte
      const createUser = await Salesforce.createUser(payload, values)
      console.log(createUser)
      actions.setStatus({ userCreated: createUser })
    } else {
      actions.setStatus({ userExist: true })
    }
    actions.setSubmitting(false)
  }

  const switchToLogin = () => {
    // dispatch(updateIonisConnect(true))
    history.push('/connexion')
  }

  return (
    <>
      <Typography align="center" gutterBottom variant="h4">
        Avant de commencer
      </Typography>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting, status, values }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <InputField fullWidth label={firstName.label} name={firstName.name} />
            </Grid>
            <Grid item md={3} xs={12}>
              <InputField fullWidth label={lastName.label} name={lastName.name} />
            </Grid>
            <Grid item md={3} xs={12}>
              <SelectField data={optionsAcademicLevels} fullWidth label={academicLevel.label} name={academicLevel.name} />
            </Grid>
            <Grid item md={3} xs={12}>
              <SelectField data={optionsNationalities} fullWidth label={nationality.label} name={nationality.name} />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={email.label} name={email.name} type="email" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={password.label} name={password.name} type="password" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={passwordConfirm.label} name={passwordConfirm.name} type="password" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={<div>En cochant cette case, je reconnais avoir pris connaissance des <Link className={classes.link} to={{ pathname: 'https://www.isg.fr/mentions-legales' }} target="_blank">Conditions Générales d'Utilisation</Link> et je les accepte.</div>} name={cgu.name} />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} color="primary" disabled={isSubmitting || (status && status.userCreated)} type="submit" variant="contained">
                S'inscrire
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
          { status && status.userCreated && <div><Box mt={2} mb={2}><Alert severity="warning" variant="outlined">Un email de confirmation a été envoyé à l'adresse <strong>{values.email}</strong>.<br />Veuillez cliquer sur le lien de confirmation qui se trouve dans cet e-mail afin de finaliser la création de votre compte.</Alert></Box>

              <Button className={classes.button} color="primary" onClick={switchToLogin} variant="contained">J'ai validé mon adresse email</Button>
            </div>
          }
          { status && status.userExist && <div><Box mt={2} mb={2}><Alert severity="warning" variant="outlined">Un compte est déjà associé à cette adresse email.</Alert></Box>

              <Button className={classes.button} color="primary" onClick={switchToLogin} variant="contained">Se connecter</Button>

            </div>
          }
        </Form>
        )}
      </Formik>
    </>
  )
}

export default Registration
