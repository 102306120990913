import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material"
import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import LogicMscMba from "../../services/LogicMscMba"

const TrackMajor = ({ formField, year }) => {

  const [majors, setMajors] = useState([])
  const [majorName, setMajorName] = useState([]);

  const {
    opportunity: {
      TrackMajor__c
    }
  } = formField

  const {
    values : {
      application: {
        trackMajor,
        trackMajorDisabled
      },
      opportunity
    }
  } = useFormikContext()

  useEffect(() => {
    if (opportunity !== null && opportunity.Track__c !== undefined) {
      const index = LogicMscMba.track.findIndex(x => x.value === opportunity.Track__c)
      // On recherche les majors
      if (index > -1) {
        const majorsList = LogicMscMba.years.find(x => x.year === year && x.intake === false)
        if (majorsList !== undefined) {
          const majors = majorsList.trackMajor[index]
          setMajors(majors)
        }
      }
    }
  }, [opportunity, year])

  useEffect(() => {
    if (trackMajor !== undefined && trackMajor.length === 0) {
      setMajorName([])
    } else {
      setMajorName(trackMajor)
    }
  }, [trackMajor])

  const formikProps = useFormikContext()

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMajorName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    formikProps.setFieldValue('opportunity.TrackMajor__c', value.join(', '))
    formikProps.setFieldValue('application.trackMajor', value)
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">{TrackMajor__c.label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          disabled={trackMajorDisabled}
          id="demo-multiple-checkbox"
          multiple
          name="trackMajor"
          value={majorName}
          onChange={handleChange}
          input={<OutlinedInput label={TrackMajor__c.label} />}
          renderValue={(selected) => selected.join(', ')}
          // MenuProps={MenuProps}
        >
          {majors.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={majorName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default TrackMajor
