import { Typography } from "@mui/material"
import ForgotPasswordCmp from "../components/IonisConnect/Forms/ForgotPassword"

const ForgotPassword = () => {
  return (
    <>
      <Typography gutterBottom variant="h3">Mot de passe oublié</Typography>

      <Typography gutterBottom variant="h6">Vous avez oublié votre mot de passe ? Saisissez votre email ci-dessous. Vous recevrez un email pour redéfinir un nouveau mot de passe.</Typography>
      <ForgotPasswordCmp />
    </>
  )
}

export default ForgotPassword
