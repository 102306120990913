import { AppBar, Box, Button, Toolbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
// import { updateIonisConnect } from "../../Store/salesforceSlice"
import useStyles from "./styles"
import store from '../../Store/Store'
import { persistStore } from "redux-persist"
// import { Language } from "../Buttons"
import Parameters from "../Buttons/Parameters"
import { useHistory } from "react-router"

const Header = () => {
  const classes = useStyles()
  const history = useHistory()
  const isLogged = useSelector(state => state.salesforce.isLogged)
  const isLogin = useSelector(state => state.salesforce.isLogin)
  let persistor = persistStore(store)

  const registration = () => {
    history.push('/inscription')
  }

  const login = ()  => {
    history.push('/connexion')
  }

  const logout = () => {
    persistor.purge().then(() => {
      document.location.href = '/'
    })
  }

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar>
        {/* <Typography className={classes.title} color="inherit" noWrap variant="h6">
          ISG
        </Typography> */}
        <Box
          alt="ISG"
          // className={classes.title}
          component="img"
          src="/logo-isg.jpg"
          sx={{ display: 'flex', marginTop: 2, marginBottom: 2, height: 64, width: 64 }}
        >
        </Box>
        {/* { isLogged && <ButtonNewApplication  /> } */}
        {/* <Language /> */}
        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
          { isLogged && <Parameters /> }
          { isLogged && <Button color="secondary" onClick={logout} variant="outlined">Déconnexion</Button> }
          { !isLogged && isLogin  && <Button color="primary" onClick={registration} variant="contained">Créer son compte</Button> }
          { !isLogged && !isLogin  && <Button color="primary" onClick={login} variant="contained">Se connecter</Button> }
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
