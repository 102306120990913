import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import Salesforce from "../../services/Salesforce"
import useStyles from "../Portal/styles"
import { Link } from "react-router-dom"

const JPC = () => {

  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const payload = useSelector(state => state.salesforce.token)
  const accountId = useSelector(state => state.salesforce.account.PersonContactId)
  const campaignId = useSelector(state => state.salesforce.campaignId)
  const campaignMember = useSelector(state => state.salesforce.campaignMember)
  const statusPayment = useSelector(state => state.salesforce.statusPayment)
  const redirectToPayement = async () => {
    console.log('le paiementttttttttttttttttttttttt')
    setIsSubmitting(true)
    const campaignMemberCreated = await Salesforce.createCampaignMember(payload, accountId, campaignId)
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', campaignMemberCreated)
    if (campaignMemberCreated) {
      setOpen(false)
      history.push('/reglement-frais-jpc')
    }
  }

  return (
    <>
      {campaignMember.id === undefined && campaignMember.Id === undefined && statusPayment !== 'OK' && <Paper sx={{ p : 2 }}>
        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil, hic fugit, nobis esse temporibus est consequuntur deleniti impedit saepe dolorem, expedita officia! Aliquam accusamus deleniti eveniet, distinctio unde esse praesentium!</p>

        <Button onClick={handleOpen} variant="contained">Vous souhaitez participer à une JPC ?</Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Participer à une JPC
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              En cliquant sur le bouton <strong>Payer mes frais de JPC</strong>, vous allez régler vos frais de JPC.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseOpportunity} variant="contained">
              Confirmer ma candidature
            </Button> */}
            <Button className={classes.button} onClick={handleClose}>Annuler</Button>
            <div className={classes.buttons}>
              <div className={classes.wrapper}>
                <Button className={classes.button} disabled={isSubmitting} onClick={redirectToPayement} variant="contained">Payer mes frais de JPC</Button>
                {isSubmitting && (
                  <CircularProgress className={classes.buttonProgress} size={24} />
                )}
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </Paper>}

      {(campaignMember.id !== undefined || campaignMember.Id !== undefined) && statusPayment !== 'OK' &&

        <Box>
          <Alert elevation={6} severity="error">Il semblerait que vous n'ayez pas réglé vos frais de JPC.</Alert>

          <Button component={Link} to="/reglement-frais-jpc" sx={{ mt: 2 }} variant="contained">Payer mes frais de JPC</Button>
        </Box>
      }
    </>
  )
}

export default JPC
