import LogicYear from "./LogicYear"
import LogicProgram from "./LogicProgram"
import moment from "moment"

class LogicApplication {

  /**
   * Récupère la liste des programmes proposés selon le niveau académique et la nationalité
   * @param {string} academicLevel Niveau académique
   * @param {*} nationality Nationalité
   * @returns Liste des programmes
   */
  static getPrograms(academicLevel, nationality) {
    const nationalityFilter = nationality === 'Française' ? 'Française' : ''
    const found = LogicProgram.find(el => el.academicLevel === academicLevel && el.nationality === nationalityFilter)
    if (found) {
      return found.programs
      // checkExistingProgram() // @todo Aie
    }

    return []
  }

  /**
   * Récupère la liste des campus et des années proposées selon le programme et niveau académique sélectionné
   * @param {string} programName Nom du programme
   * @param {string} academicLevel Niveau académique
   * @returns Liste des campus et années proposées
   */
  static getYearCampus(programName, academicLevel) {
    const programYears = LogicYear.find(el => el.division === programName)
    if (programYears !== undefined) {
      const years = programYears.years
      const campus = programYears.campus
      const y = years.filter(el => el.academicLevel.indexOf(academicLevel) > -1)
      let finalYears = []
      for (let i = 0; i < y.length; i++) {
        finalYears.push(y[i].year)
      }

      return { campus, years: finalYears }
    }

    return { campus: [], years: [] }
  }

  /**
   *
   * @param {*} program
   * @param {*} year
   * @param {*} campus
   * @param {*} sessions
   * @returns
   */
  static getSessions(program, year, campus, sessions) {
    if (sessions !== undefined) {
      const _sessions = sessions.filter(el => el.Division__c === program && el.Campus__c === campus && el.Program__r.Year__c === year)
      let sessionsFilter = []
      for (let i = 0; i < _sessions.length; i++) {
        sessionsFilter.push(
          {
            label: moment(_sessions[i].StartDate__c).format('MMMM yy'),
            value: _sessions[i].Id
          }
        )
      }

      return sessionsFilter
    }

    return []
  }

  /**
   *
   * @param {*} program
   * @param {*} year
   * @param {*} campus
   * @param {*} sessions
   * @param {*} trackProgram
   * @returns
   */
  static getSessionsMsc(program, year, campus, sessions, trackProgram) {
    if (sessions !== undefined) {
      const _sessions = sessions.filter(el => el.Division__c === program && el.Campus__c === campus && el.Program__r.Year__c === year && el.Program__r.FullName__c === trackProgram)
      let sessionsFilter = []
      for (let i = 0; i < _sessions.length; i++) {
        sessionsFilter.push(
          {
            label: moment(_sessions[i].StartDate__c).format('MMMM yy'),
            value: _sessions[i].Id
          }
        )
      }

      return sessionsFilter
    }

    return []
  }

  static isParcoursup(programName, academicLevel, year) {
    const yearsFilter = LogicYear.find(el => el.division === programName)
    if (yearsFilter !== undefined) {
      const years = yearsFilter.years
      const isParcoursup = years.find(el => el.year.value === year)
      return isParcoursup.parcoursup
    }
    return false
  }
  
  static getCommercialId(sessions, sessionId) {

    console.log('*******')
    console.log(sessions)
    console.log(sessionId)
    if (sessionId !== undefined) {
      const session = sessions.find(el => el.Id === sessionId)
      return session.Commercial__c
    }

    return ''
  }
}

export default LogicApplication
