import { Alert, Button, CircularProgress, Drawer, Snackbar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Formik, Form, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Salesforce from "../../services/Salesforce"
import { STAGE_COMPLETE, STAGE_COURS, STAGE_QUALIFICATION } from "../../services/StageNameOpportunity"
import portalFormModel from "../Portal/FormModel/portalFormModel"
import validationSchema from "../Portal/FormModel/validationSchema"
import Application from "../Portal/Forms/Application"
import useStyles from "../Portal/styles"
import { getStatusPayment, updateCampaignMember, updateOpportunity, updateOpportunities, updateSupportingDocument } from "../../Store/salesforceSlice"
import AddIcon from '@mui/icons-material/Add'
import SupportingDocument from "../Portal/Forms/SupportingDocument"
import Payment from "../Portal/Forms/Payment"
import { useRef } from "react"

const NewApplication = () => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [wantJPC, setWantJPC] = useState(false)
  const [opportunityCreated, setOpportunityCreated] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setOpportunityCreated(false)
  }
  const [criticalErrors, setCriticalErrors] = useState(false)
  const { formId, formField } = portalFormModel
  const account = useSelector(state => state.salesforce.account)
  const SupportingDocument__c = useSelector(state => state.salesforce.supportingDocument)
  const hasPayed = useSelector(state => state.salesforce.statusPayment) === "OK"
  const payload = useSelector(state => state.salesforce.token)
  const recordTypeId = useSelector(state => state.salesforce.recordTypeOpportunity)
  const campaignId = useSelector(state => state.salesforce.campaignId)
  const actualOpportunity = useSelector(state => state.salesforce.opportunity)
  const paymentType = useSelector(state => state.salesforce.paymentType)
  const campaignMember = useSelector(state => state.salesforce.campaignMember)
  const currentValidationSchema = validationSchema[1]
  const validationSchemaSupportingDocuments = validationSchema[4]
  const [documents, setDocuments] = useState([])

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackOpen(false)
  }

  const opportunity = {
    AccountId: account.Id,
    CloseDate: Date.now(),
    OnlineApplication__c: true,
    RecordTypeId: recordTypeId,
    Session__c: '',
    StageName: hasPayed ? STAGE_COURS : STAGE_QUALIFICATION
  }

  useEffect(() => {
    const docs = SupportingDocument__c.filter(x => x.supportingDocument !== 'CV' && x.supportingDocument !== 'Lettre de motivation')
    setDocuments(docs)
  }, [SupportingDocument__c])

  async function _handleSubmit(values, action) {
    const opportunity = values.opportunity
    const JPCWished = values.JPCWished
    setWantJPC(JPCWished)
    const opportunityUpdated = await Salesforce.updateOpportunity(payload, opportunity, JPCWished)
    console.log('youhou', opportunityUpdated)
    if (opportunityUpdated.success) {
      dispatch(updateOpportunity(opportunityUpdated.opportunity))
      dispatch(updateOpportunities(opportunityUpdated.opportunities))
      if (JPCWished && (campaignMember && Object.keys(campaignMember).length === 0 && Object.getPrototypeOf(campaignMember) === Object.prototype)) {
        const campaignMemberCreated = await Salesforce.createCampaignMember(payload, account.PersonContactId, campaignId)
        dispatch(updateCampaignMember(campaignMemberCreated.campaignMember))
      }
      // dispatch(getStatusPayment(opportunityUpdated.opportunities))
      // handleClose()
      // setSnackOpen(true)
      setOpportunityCreated(true)
    }
  }

  async function _handleSumitPJ(values, action) {
    console.log('********************************** YOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO')
    console.log(values)
    const opportunityUpdate = {
      AccountId: account.Id,
      Id: actualOpportunity.Id,
      StageName: STAGE_COMPLETE
    }
    const opportunityMerged = {...values.opportunity, ...opportunityUpdate}
    console.log(opportunityUpdate)
    console.log(opportunityMerged)
    const opportunityUpdated = await Salesforce.updateOpportunity(payload, opportunityMerged)
    console.log('youhou', opportunityUpdated)
    if (opportunityUpdated.success) {
      dispatch(updateOpportunity(opportunityUpdated.opportunity))
      dispatch(updateOpportunities(opportunityUpdated.opportunities))
      handleClose()
      setSnackOpen(true)
    }
    // console.log(opp)
  }

  const handleCloseSupportingDocument = async () => {
    console.log("handleCloseSupportingDocument");
    setOpen(false);
    const supportingDocument = await Salesforce.getAttachmentFromSobject(payload, account, actualOpportunity).catch(e => setCriticalErrors(true));
    console.log("NewSupportingDocument====!!!");
    console.log(supportingDocument);
    if (supportingDocument.supportingDocument.length > 0) {
      dispatch(updateSupportingDocument(supportingDocument.supportingDocument))
      console.log("SupportingDocument__c******");
      console.log(SupportingDocument__c);
    }
    // setUpdatedAttachements(true);
  }

  const application = {
    campaignDisabled: true,
    campusDisabled: true,
    optionsCampus: [],
    optionsSessions: [],
    optionsYears: [],
    sessionsDisabled: true,
    trackMajor: [],
    trackMajorDisabled: true,
    yearDisabled: true
  }

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />} variant="contained">Nouvelle candidature</Button>
      <Box
        // onClick={handleClose}
        onKeyDown={handleClose}
      >
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
        >
          {!opportunityCreated && <Box sx={{ padding: 3 }}>
            <Typography gutterBottom variant="h5">Candidater pour un autre programme</Typography>
            <Formik
              initialValues={{JPCWished: false, application, opportunity}}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
              <Form id={formId}>
                <Application formField={formField} />
                <div className={classes.buttons}>
                  <div className={classes.wrapper}>
                    <Button className={classes.button} color="primary" disabled={isSubmitting} type="submit" variant="contained">
                      Confirmer ma candidature
                    </Button>
                    {isSubmitting && (
                      <CircularProgress className={classes.buttonProgress} size={24} />
                    )}
                    <Button className={classes.button} color="primary" onClick={handleClose} variant="outlined">Annuler</Button>
                  </div>
                </div>
              </Form>
              )}
            </Formik>
          </Box>}
          {opportunityCreated && (wantJPC || paymentType === 'Candidature') && !hasPayed && <Box sx={{ padding: 3}}>
            <Formik
              initialValues={{account}}
            >
              <Form id={formId}>
                <Payment formField={formField} />
              </Form>
            </Formik>
          </Box>}
          {opportunityCreated && (hasPayed || (!wantJPC && paymentType === 'JPC')) && <Box sx={{ padding: 3}}>
            <Formik
              initialValues={{account, SupportingDocument__c: documents}}
              validationSchema={validationSchemaSupportingDocuments}
              onSubmit={_handleSumitPJ}
            >
              {({ isSubmitting }) => (
              <Form id={formId}>
                <SupportingDocument opportunity={actualOpportunity} handleClose={handleCloseSupportingDocument} formField={formField} />
                <Button className={classes.button} color="primary" onClick={handleClose} variant="outlined">Annuler</Button>
              </Form>
              )}
            </Formik>
          </Box>}
        </Drawer>
      </Box>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} onClose={handleCloseSnack} open={snackOpen}>
        <Alert elevation={6} onClose={handleCloseSnack} severity="success">Votre candidature a bien été prise en compte.</Alert>
      </Snackbar>
    </>
  )
}

export default NewApplication
