import { at } from "lodash"
import { FieldArray, useField } from "formik"
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid } from "@mui/material"

const CheckboxListField = ({options, ...props}) => {
  const { label, ...rest} = props
  const [field, meta] = useField(props)
  // const { setValue } = helper
  const { value: formikValue } = field

  const renderOptions = (options, arrayHelpers) => {
    // let checked = field.value.includes(option)
    return options.map((option) => {
      let checked = field.value.includes(option)
      return <Grid item key={option} sm={6}><FormControlLabel
        checked={checked}
        control={<Checkbox />}
        label={option}
        name={field.name}
        onChange={e => {
          const values = formikValue || []
          if (e.target.checked) {
            arrayHelpers.push(option)
          } else {
            const idx = values.indexOf(option)
            arrayHelpers.remove(idx)
          }
        }}
        value={option}
      >
      </FormControlLabel></Grid>
    })
  };

  const [touched, error] = at(meta, 'touched', 'error')

  // function _renderHelperText() {
  //   console.log('klhgfdhjqsdjkhfsqdhkfqjmksqjkfghk')
  //   const [touched, error] = at(meta, 'touched', 'error')
  //   console.log('************************')
  //   console.log(touched)
  //   console.log(error)
  //   console.log('**************************')
  //   if (touched && error) {
  //     return <FormHelperText>{error}</FormHelperText>
  //   }
  // }

  // function _onChange(e) {
  //   setValue(e.target.checked)
  // }

  return (
    <FormControl error={error && touched && true} {...rest}>
      <FormLabel>{label}</FormLabel>
      <FormGroup row={true}>
        <FieldArray
          {...field}
          render={arrayHelpers => (renderOptions(options, arrayHelpers))}
        >
          {renderOptions(options)}
        </FieldArray>
      </FormGroup>
      <FormHelperText>{touched && error ? error : ''}</FormHelperText>
      {/* {_renderHelperText} */}
      {/* <div className="totootoototo">{_renderHelperText}</div> */}
      {/* <FormControlLabel
        checked={field.checked}
        control={<Checkbox {...field} onChange={_onChange} />}
        label={label}
        value={field.checked}
      >
        {_renderHelperText}
      </FormControlLabel> */}
    </FormControl>
  )
}

export default CheckboxListField
