import { configureStore } from "@reduxjs/toolkit"
// import storage from "redux-persist/lib/storage"
import storageSession from "redux-persist/lib/storage/session"
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import { applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import counterReducer from "./counterSlice"
import salesforceReducer from "./salesforceSlice"
import capAdresseReducer from "./capAdresseSlice"
import buttonsReducer from "./buttonsSlice"
import themeReducer from "./themeSlice"

const reducers = combineReducers({
  buttons: buttonsReducer,
  capAdresse: capAdresseReducer,
  counter: counterReducer,
  salesforce: salesforceReducer,
  theme: themeReducer
})

const persistConfig = {
  key: 'root',
  storage: storageSession,
  // blacklist: ['isOnline']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default configureStore({
  // reducer: {
  //   counter: counterReducer,
  //   salesforce: salesforceReducer
  // }
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
}, composeWithDevTools(
  applyMiddleware(thunk)
))
