import { useFormikContext } from "formik"
import { useEffect, useState } from "react"
import LogicApplication from "../../services/LogicApplication"
import LogicMscMba from "../../services/LogicMscMba"
import SelectField from "../FormFields/SelectField"

const TrackProgram = ({ campus, program, sessions, year, formField }) => {

  const [optionsTrack, setOptionsTrack] = useState([])
  const [trackDisabled, setTrackDisabled] = useState(true)

  const {
    opportunity: {
      Track__c
    }
  } = formField

  const formikProps = useFormikContext()

  useEffect(() => {
    if (year !== undefined) {
      const tracks = LogicMscMba.track
      setOptionsTrack(tracks)
    }
  }, [year])

  useEffect(() => {
    if (campus !== undefined && campus !== '') {
      setTrackDisabled(false)
    }
  }, [campus])

  return (
    <>
      <SelectField
        data={optionsTrack}
        disabled={trackDisabled}
        fullWidth
        label={Track__c.label}
        name={Track__c.name}
        onChange={e => {
          const { value } = e.target
          formikProps.setFieldValue(Track__c.name, value)
          formikProps.setFieldValue('application.sessionsDisabled', false)
          formikProps.setFieldValue('application.trackMajorDisabled', true)
          formikProps.setFieldValue('application.trackMajor', [])
          formikProps.setFieldValue('opportunity.TrackMajor__c', '')
          const _sessions = LogicApplication.getSessionsMsc(program, year, campus, sessions, value)
          formikProps.setFieldValue('application.optionsSessions', _sessions)
          if (_sessions.length === 1) {
            formikProps.setFieldValue('opportunity.Session__c', _sessions[0].value)
          }
        }}
      />
    </>
  )
}

export default TrackProgram
