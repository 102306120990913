import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import Salesforce from "../services/Salesforce"
import { STAGE_COMPLETE_POURCENT } from "../services/StageNameOpportunity"

export const Salesforcelogin = createAsyncThunk(
  'salesforce/login',
  async () => {
    const response = await Salesforce.login()
    // console.log(response)
    return {
      accessToken: response.accessToken,
      instanceUrl: response.instanceUrl
    }
  }
)

export const SalesforceTest = createAsyncThunk(
  'truc/tructest',
  async (test) => {
    console.log(test)
    const response = await Salesforce.test(test)
    console.log(response)
    return response
  }
)

export const SalesforceGetData = createAsyncThunk(
  'salesforce/getData',
  async (payload) => {
    console.log('loading load data')
    const response = await Salesforce.getSalesforceData(payload)
    return response
  }
)

export const SalesforceUpdateStageStop = createAsyncThunk(
  'salesforce/updateStageStop',
  async (obj) => {
    const response = await Salesforce.updateStageStop(obj.payload, obj.accountId, obj.newStep)
    return response
  }
)

export const SalesforceGoToPayment = createAsyncThunk(
  'salesforce/goToPayment',
  async (obj) => {
    const response = await Salesforce.goToPayment(obj.payload, obj.opportunityId)
    return response
  }
)

export const salesforceSlice = createSlice({
  name: 'salesforce',
  initialState: {
    academicInformation: [],
    academicLevels: [],
    account: {},
    applicationPrice: 0,
    campaignId: '',
    campaignMember: {},
    countries: [],
    division: process.env.REACT_APP_SCHOOL,
    firstInformationSource: [],
    hasOneOppyCompleted: false,
    isLogged: false,
    isLogin: true,
    language: 'fr',
    languageLevels: [],
    languages: [],
    nationalities: [],
    opportunities: [],
    opportunity: {},
    paymentType: 'JPC',
    recordTypeAccount: '',
    recordTypeOpportunity: '',
    returnPages: {
      confirmation: '/confirmation-candidature',
      payment_ko: '',
      payment_ok: ''
    },
    sectionOfBac: [],
    sections: [],
    sessions: [],
    school: process.env.REACT_APP_SCHOOL,
    status: 'loading',
    statusPayment: '',
    supportingDocument: [],
    token: null,
    value: null,
    workExperience: [],
  },
  reducers: {
    /**
     * @todo ID en dur pour la récupération du code. Idéalement soit via le candidat ou alors je récupère le premier statut de la première candidature
     */
    getStatusPayment: (state, action) => {
      const opportunities = action.payload
      // const opportunitiesPaymentNoPayment = opportunities.find(el => el.payment_status_candidature__c === 'N/A')
      const opportunitiesPaymentKo = opportunities.find(el => el.payment_status_candidature__c === 'KO')
      const opportunitiesPaymentOk = opportunities.find(el => el.payment_status_candidature__c === 'OK')
      const opportunitiesPaymentReached = opportunities.find(el => el.payment_status_candidature__c === 'reached_bank_website')
      // console.log('val pauelent', found)

      // if (opportunitiesPaymentNoPayment) {
      //   state.statusPayment = 'N/A'
      // }

      if (opportunitiesPaymentReached) {
        state.statusPayment = 'reached_bank_website'
      }

      if (opportunitiesPaymentKo) {
        state.statusPayment = 'KO'
      }
      if (opportunitiesPaymentOk) {
        state.statusPayment = 'OK'
      }
    },
    /**
     *
     */
    hasOneOppyCompleted: (state, action) => {
      const opportunities = action.payload
      // const found = opportunities.find(el => el.StageName === STAGE_COMPLETE)
      const found = opportunities.find(el => el.Probability >= STAGE_COMPLETE_POURCENT)
      state.hasOneOppyCompleted = found !== undefined
    },
    login: state => {
      state.value = 'coucou'
      // state.value = Salesforce.login()
    },
    isLogged: state => {
      state.isLogged = !state.isLogged
    },
    removeOpportunities: (state, action) => {
      state.opportunities = []
    },
    updateAcademicInformation: (state, action) => {
      state.academicInformation = action.payload
    },
    updateAccount: (state, action) => {
      state.account = action.payload
    },
    updateCampaignMember: (state, action) => {
      state.campaignMember = action.payload
    },
    updateLanguage: (state, action) => {
      state.language = action.payload
    },
    updateIonisConnect: (state, action) => {
      state.isLogin = action.payload
    },
    updatePaymentType: (state, action) => {
      state.paymentType = action.payload
    },
    updateOpportunities: (state, action) => {
      const opportunity = action.payload
      console.log(opportunity)
      if (opportunity !== undefined && opportunity[0].Id !== undefined) {
        const index = state.opportunities.findIndex(el => el.Id === opportunity[0].Id)
        if (index !== -1) {
          state.opportunities = state.opportunities[index] = action.payload
        } else {
          state.opportunities = state.opportunities.concat(action.payload)
        }
      }
    },
    updateOpportunity: (state, action) => {
      state.opportunity = action.payload
    },
    updateStatus: (state, action) => {
      state.status = action.payload
    },
    updateWorkExperience: (state, action) => {
      state.workExperience = action.payload
    },
    updateSupportingDocument: (state, action) => {
      state.supportingDocument = action.payload
    },
  },
  extraReducers: {
    [Salesforcelogin.pending]: (state, action) => {
      // state.allStatus.token = 'loading'
      // state.status = 'loading'
    },
    [Salesforcelogin.fulfilled]: (state, action) => {
      // state.allStatus.token = 'success'
      // state.status = 'success'
      state.token = action.payload
    },
    [Salesforcelogin.rejected]: (state, action) => {
      // state.allStatus.token = 'failed'
      // state.status = 'failed'
    },
    [SalesforceTest.fulfilled]: (state, action) => {
      state.account = action.payload
    },
    [SalesforceGetData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [SalesforceGetData.fulfilled]: (state, action) => {
      state.status = 'success'
      // const payload = action.payload
      state.academicLevels = action.payload.academicLevels
      state.applicationPrice = action.payload.price
      state.campaignId = action.payload.campaignId
      state.countries = action.payload.countries
      state.firstInformationSource = action.payload.firstInformationSource
      state.languages = action.payload.languages
      state.languageLevels = action.payload.languageLevels
      state.nationalities = action.payload.nationalities
      state.recordTypeAccount = action.payload.recordTypeAccount
      state.recordTypeOpportunity = action.payload.recordTypeOpportunity
      state.sectionOfBac = action.payload.sectionOfBac
      state.sections = action.payload.sections
      state.sessions = action.payload.sessions
    },
    [SalesforceGetData.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [SalesforceUpdateStageStop.pending]: (state, action) => {
      console.log('pending stage stop')
    },
    [SalesforceUpdateStageStop.fulfilled]: (state, action) => {
      state.account.StageStop__c = action.payload.stage
      console.log('stage stop ok', state, action)
    },
    [SalesforceUpdateStageStop.rejected]: (state, action) => {
      console.log('error stage stop')
    },
    [SalesforceGoToPayment.pending]: (state, action) => {
      // console.log('pending stage stop')
    },
    [SalesforceGoToPayment.fulfilled]: (state, action) => {
      state.statusPayment = action.payload.status
      // console.log('stage stop ok', state, action)
    },
    [SalesforceGoToPayment.rejected]: (state, action) => {
      // console.log('error stage stop')
    }
  }
})

export const { getStatusPayment, hasOneOppyCompleted, isLogged, login, removeOpportunities, updateAcademicInformation, updateAccount, updateCampaignMember, updateIonisConnect, updateLanguage, updateOpportunities, updateOpportunity, updatePaymentType, updateStatus, updateSupportingDocument, updateWorkExperience} = salesforceSlice.actions

export default salesforceSlice.reducer
