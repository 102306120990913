import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    left: '50%',
    position: 'absolute',
    top: '50%'
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))
