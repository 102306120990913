import { Alert, Button, Drawer, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AddIcon from '@mui/icons-material/Add'
import { Box } from "@mui/system"
import SupportingDocument from "../Portal/Forms/SupportingDocument"
import { Form, Formik } from "formik"
import validationSchema from "../Portal/FormModel/validationSchema"
import portalFormModel from "../Portal/FormModel/portalFormModel"
import Salesforce from "../../services/Salesforce"
import { updateOpportunities, updateOpportunity, updateSupportingDocument } from "../../Store/salesforceSlice"
import { STAGE_COMPLETE } from "../../services/StageNameOpportunity"

const NewSupportingDocument = (props) => {
  console.log("NewSupportingDocument");
  console.log(props.opportunity);
  // const classes = useStyles()
  const dispatch = useDispatch()
  const { formId, formField } = portalFormModel
  const [open, setOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [updatedAttachements, setUpdatedAttachements] = useState(false)
  const handleOpen = () => setOpen(true)
  const [criticalErrors, setCriticalErrors] = useState(false)

  const account = useSelector(state => state.salesforce.account)
  const payload = useSelector(state => state.salesforce.token)
  const SupportingDocument__c = useSelector(state => state.salesforce.supportingDocument)
  // const actualOpportunity = useSelector(state => state.salesforce.opportunity)
  const validationSchemaSupportingDocuments = validationSchema[4]

  useEffect(()=> {
    async function updateAttachments() {
      // dispatch(updateSupportingDocument([]));
      console.log("validationSchemaSupportingDocuments :", validationSchemaSupportingDocuments);
      const supportingDocument = await Salesforce.getAttachmentFromSobject(payload, account, props.opportunity).catch(e => setCriticalErrors(true));
      console.log("NewSupportingDocument");
      console.log(supportingDocument);
      if (supportingDocument.supportingDocument.length > 0) {
        dispatch(updateSupportingDocument(supportingDocument.supportingDocument))
        console.log("SupportingDocument__c");
        console.log(SupportingDocument__c);
      }
      setUpdatedAttachements(true);
    }
    updateAttachments();
  }, []);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackOpen(false)
  }
  const handleClose = async () => {
    setOpen(false);
    const supportingDocument = await Salesforce.getAttachmentFromSobject(payload, account, props.opportunity).catch(e => setCriticalErrors(true));
    console.log("NewSupportingDocument");
    console.log(supportingDocument);
    if (supportingDocument.supportingDocument.length > 0) {
      dispatch(updateSupportingDocument(supportingDocument.supportingDocument))
      console.log("SupportingDocument__c");
      console.log(SupportingDocument__c);
    }
    setUpdatedAttachements(true);
  }

  async function _handleSubmit(values, action) {
    // setUpdatedAttachements(false);
    // handleClose()
    const opportunityUpdate = {
      AccountId: account.Id,
      Id: props.opportunity.Id,
      StageName: STAGE_COMPLETE
    }
    console.log(opportunityUpdate)
    const opportunityUpdated = await Salesforce.updateOpportunity(payload, opportunityUpdate)
    console.log('youhou', opportunityUpdated)
    if (opportunityUpdated.success) {
      console.log('BA ALORS SNACCKKK')
      setSnackOpen(true)
      handleClose()
      setTimeout(() => {
        dispatch(updateOpportunity(opportunityUpdated.opportunity))
        dispatch(updateOpportunities(opportunityUpdated.opportunities))
      }, 5000);
    }
  }

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />} variant="contained">Ajouter des pièces jointes</Button>
      <Box
        // onClick={handleClose}
        onKeyDown={handleClose}
      >
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
        >
        { updatedAttachements &&
          <Box sx={{ padding: 3}}>
            <Formik
              initialValues={{account, SupportingDocument__c}}
              validationSchema={validationSchemaSupportingDocuments}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
              <Form id={formId}>
                <SupportingDocument opportunity={props.opportunity} handleClose ={handleClose}/>
                <Button color="primary" onClick={handleClose} variant="outlined">Annuler</Button>
              </Form>
              )}
            </Formik>
          </Box>
        }
        </Drawer>
      </Box>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} onClose={handleCloseSnack} open={snackOpen}>
        <Alert elevation={6} onClose={handleCloseSnack} severity="success">Votre candidature a bien été prise en compte.</Alert>
      </Snackbar>
    </>
  )
}

export default NewSupportingDocument
