import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  appBar: {
    // position: 'relative'
  },
  title: {
    flexGrow: 1
  }
}))
