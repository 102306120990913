import { Form, Formik } from "formik"
import { useSelector } from "react-redux"
import portalFormModel from "../components/Portal/FormModel/portalFormModel"
import Payment from "../components/Portal/Forms/Payment"

const ReglementFraisJPC = () => {

  const { formId, formField } = portalFormModel
  const account = useSelector(state => state.salesforce.account)

  return (
    <>
      <Formik
        initialValues={{account}}
      >
        <Form id={formId}>
          <Payment formField={formField} />
        </Form>
      </Formik>
    </>
  )
}

export default ReglementFraisJPC
