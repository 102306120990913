import { AlertTitle, Box, Button, CircularProgress, Grid } from "@mui/material"
import { Alert } from '@mui/material';
import { Form, Formik } from "formik"
import { InputField } from "../../FormFields"
import loginValidationSchema from "../FormModel/loginValidationSchema"
import ionisConnectFormModel from "../FormModel/ionisConnectFormModel"
import formInitialValues from "../FormModel/formInitialValues"
import useStyles  from "../styles"
import Salesforce from "../../../services/Salesforce"
import { useDispatch, useSelector } from "react-redux"
import { getStatusPayment, hasOneOppyCompleted, isLogged, updateAcademicInformation, updateAccount, updateCampaignMember, updateIonisConnect, updateOpportunities, updateOpportunity, updatePaymentType, updateSupportingDocument, updateWorkExperience } from "../../../Store/salesforceSlice"
// import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useHistory } from "react-router"

const Login = () => {

  const classes = useStyles()
  const { formId, formField } = ionisConnectFormModel
  const {
    email,
    password,
  } = formField

  const dispatch = useDispatch()
  const history = useHistory()
  const payload = useSelector(state => state.salesforce.token)
  const division = useSelector(state => state.salesforce.division)
  const campaignId = useSelector(state => state.salesforce.campaignId)
  const [criticalErrors, setCriticalErrors] = useState(false)
  // const { i18n, t } = useTranslation()

  useEffect(() => {
    dispatch(updateIonisConnect(true))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const _handleSubmit = async (values, actions) => {
    setCriticalErrors(false)
    actions.setStatus({ wrongPassword: false })
    const verifyUser = await Salesforce.verifyUser(payload, values.email, values.password, division).catch(e => setCriticalErrors(true))
    if (verifyUser.passwordMatches) {
      const ionisConnectId = verifyUser.ionisConnectId
      const account = await Salesforce.getAccount(payload, ionisConnectId, values.email, division).catch(e => setCriticalErrors(true))
      const campaignMember = await Salesforce.getCampaignMember(payload, account.PersonContactId, campaignId).catch(e => setCriticalErrors(true))
      const opportunities = await Salesforce.getOpportunities(payload, account.Id).catch(e => setCriticalErrors(true))
      console.log("opportunities");
      console.log(opportunities);
      const academicInformation = await Salesforce.getAcademicInformation(payload, account.Id).catch(e => setCriticalErrors(true))
      const workExperience = await Salesforce.getWorkExperience(payload, account.Id).catch(e => setCriticalErrors(true))
      const supportingDocument = await Salesforce.getAttachmentFromSobject(payload, account, opportunities[0]).catch(e => setCriticalErrors(true))
      if (account !== null) {
        dispatch(updateAccount(account))
        dispatch(updateCampaignMember(campaignMember))
        dispatch(updateAcademicInformation(academicInformation))
        dispatch(updateWorkExperience(workExperience))
        if (opportunities.length > 0) {
          dispatch(getStatusPayment(opportunities))
          dispatch(updateOpportunities(opportunities))
          dispatch(updateOpportunity(opportunities[0]))
          dispatch(updateSupportingDocument(supportingDocument.supportingDocument))
          dispatch(hasOneOppyCompleted(opportunities))
          if ((opportunities[0].ProgramYear__c === '4' && opportunities[0].Division__c === 'ISG BS France') || opportunities[0].Division__c === 'ISG MSC et MBA') {
            dispatch(updatePaymentType('Candidature'))
          } else {
            dispatch(updatePaymentType('JPC'))
          }
        }
        dispatch(isLogged())
        history.push('/')
      }
    } else {
      actions.setStatus({ wrongPassword: true })
    }
  }

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={loginValidationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting, status }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField fullWidth label={email.label} name={email.name} type="email" />
            </Grid>
            <Grid item xs={12}>
              <InputField fullWidth label={password.label} name={password.name} type="password" />
            </Grid>
          </Grid>
          <Button className={classes.buttonForgotPassword} component={Link} to="/mot-passe-oublie">Mot de passe oublié ?</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button color="primary" className={classes.button} disabled={isSubmitting || (status && status.userCreated)} type="submit" variant="contained">
                Se connecter
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
          { status && status.wrongPassword && <Box mt={2}><Alert severity="error" variant="outlined">L'email ou le mot de passe que vous avez renseigné est incorrect.</Alert></Box>
          }
          {criticalErrors && <Alert severity="error" variant="outlined">
            <AlertTitle>Une erreur est survenue</AlertTitle>
            Une erreur est survenue lors de la soumission du formulaire. Veuillez réessayer ou retenter un peu plus tard.
          </Alert>}
        </Form>
        )}
      </Formik>
    </>
  )
}

export default Login
