import jsforce from "jsforce"
import CryptoJS from "crypto-js"
import hmacSHA512 from "crypto-js/hmac-sha512"
import Base64 from "crypto-js/enc-base64"
import errorCode from "./ErrorCode"
import { STAGE_PROSPECT } from "./StageNameAccount"
import JSZip from 'jszip'
// import FileSaver from 'file-saver'
import axios from 'axios'
import { STAGE_COURS, STAGE_QUALIFICATION } from "./StageNameOpportunity"
import store from "../Store/Store"
import { updateSupportingDocument } from "../Store/salesforceSlice"

class Salesforce {

  /**
   * Connection à Salesforce
   * @returns {object} The connection with the access token and the instance url
   */
  static async login() {
    const connection = new jsforce.Connection({
      oauth2: {
        loginUrl: 'https://test-ionis-sf.herokuapp.com',
        clientId: process.env.REACT_APP_CLIENTID,
        clientSecret: process.env.REACT_APP_CLIENTSECRET,
        redirectUri: 'http://localhost:3000/auth'
      }
    })

    /**
     * Devweb
     * 3MVG9T992fY2Y4vtJVd0B7zjYuVivw08zF9UZs6km1EvdDAxIQelb3AoKUOBZgZ8aGSgK6TO_yRp9v1vO61lC
     * 77BBC5135B8CCA968EDFD8A10400CA74AF587068F0BBACA5C6203E0AC7FC5625
     * alesforce@ionis-group.com.devweb
     * OHENjk926tmpH6WzzDYwpzWhEQ9AjUWZ
     */

    return new Promise((resolve, reject) => {
      connection.login(process.env.REACT_APP_EMAIL, process.env.REACT_APP_PASSWORD, (err, userInfo) => {
        if (err) { reject(new Error("Connexion impossible")) }
        if (!err) {
          console.log("Connexion SF OK")
          console.log(connection);
          resolve(connection)
        }
      })
    })
  }

  /**
   * Create a connection for all Salesforce methods
   * @param {object} payload accessToken and instanceUrl
   * @returns {object}
   */
  static connection(payload) {
    const connection = new jsforce.Connection({
      accessToken: payload.accessToken,
      instanceUrl: payload.instanceUrl
    })

    return connection
  }

  /**
   * Get all the data from Salesforce : Countries, AcademicLevels, Sections, Nationalities...
   * @param {object} payload accessToken and instanceUrl
   * @returns {object} All the data with academic levels, countries, nationalities, sections and sessions
   */
  static async getSalesforceData(payload) {
    return new Promise( async (resolve, reject) => {
      const recordTypeAccount = await this.getRecordTypeId(payload, process.env.REACT_APP_RECORDTYPE_ACCOUNT, 'Account')
      const recordTypeOpportunity = await this.getRecordTypeId(payload, process.env.REACT_APP_RECORDTYPE_OPPORTUNITY, 'Opportunity')
      const academicLevels = await this.getPicklistValues(payload, 'Account', recordTypeAccount, 'AcademicLevel__pc')
      const countries = await this.getPicklistValues(payload, 'Account', recordTypeAccount, 'Country_of_birth__pc')
      const firstInformationSource = await this.getPicklistValues(payload, 'Opportunity', recordTypeOpportunity, 'First_information_source__c')
      const languages = await this.getPicklistValues(payload, 'Account', recordTypeAccount, 'Native_Language__pc')
      const languageLevels = await this.getPicklistValues(payload, 'Account', recordTypeAccount, 'Niveau_langue_1__c')
      const nationalities = await this.getPicklistValues(payload, 'Account', recordTypeAccount, 'Nationalite__c')
      const price = await this.getApplicationPrice(payload, 'ISGPriceJPC')
      const sectionOfBac = await this.getPicklistValues(payload, 'Account', recordTypeAccount, 'SectionOfBAC__pc')
      const sections = await this.getSections(payload, academicLevels, process.env.REACT_APP_DIVISION)
      const sessions = await this.getSessions(payload, process.env.REACT_APP_SCHOOL)
      const campaignId = await this.getCampaign(payload, process.env.REACT_APP_CAMPAIGN_NAME, process.env.REACT_APP_SCHOOL)
      resolve({ academicLevels, campaignId, countries, firstInformationSource, languages, languageLevels, nationalities, price, recordTypeAccount, recordTypeOpportunity, sectionOfBac, sections, sessions })
    })
  }

  /**
   * Verify if the IonisConnect exist
   * @param {object} payload accessToken and instanceUrl
   * @param {string} email student email
   * @param {string} division division of the school
   * @returns {Promise} Promise who return if the IonisConnect exist and the IonisConnect object
   */
  static async checkUser(payload, email, division) {
    const connection = this.connection(payload)

    return new Promise((resolve, reject) => {
      connection.query('SELECT Id, Email__c, EmailChecked__c, Password__c, PrivateKey__c FROM IonisConnect__c WHERE Email__c = \'' + email + '\' AND School__c = \'' + division + '\' AND EmailChecked__c = true AND EmailDeactivated__c = false LIMIT 1', (err, result) => {
        if (err) { reject(new Error('Erreur')) }
        if (!err) {
          resolve({
            exist: result.records.length > 0,
            ionisConnect: result.records[0]
          })
        }
      })
    })
  }

  /**
   * Create the IonisConnect and the Account
   * @param {object} payload accessToken and instanceUrl
   * @param {object} user user information
   * @returns Account Id
   * @todo modifier la valeur de retour en dynamique
   */
  static async createUser(payload, user) {
    const connection = this.connection(payload)
    const {
      email,
      password
    } = user

    const passwordSalt = this.getHashdPasswordSalt(password)

    return new Promise( async (resolve, reject) => {
      const ionisConnect = {
        CGU__c: true,
        ConfirmationToken__c: this.generateSecurityToken(),
        Email__c: email,
        Origin__c: process.env.REACT_APP_SCHOOL,
        OriginUrl__c: document.location.href,
        Password__c: passwordSalt.passwordHashed,
        PrivateKey__c: passwordSalt.salt,
        School__c: process.env.REACT_APP_SCHOOL,
      }
      const createIonisConnect = await connection.sobject('IonisConnect__c').create(ionisConnect)

      console.log(createIonisConnect)

      const accounts = await connection.query('SELECT Id FROM Account WHERE PersonEmail = \'' + email + '\' AND Historise__c = false LIMIT 1')

      if (accounts.records.length === 0) {
        const account = await this.createAccount(payload, user)
        console.log(account)
        resolve(true)
      } else {
        // @todo on modifie eventuellement le compte ????
        resolve(true)
      }

    })
  }

  /**
   * Verify the confirmation token on IonisConnect to verify the email address
   * @param {object} payload accessToken and instanceUrl
   * @param {string} token token provided in GET url params
   * @returns
   */
  static async verifyConfirmationToken(payload, token) {
    const connection = this.connection(payload)

    return new Promise( async (resolve, reject) => {
      try {
        const users = await connection.query('SELECT Id, EmailChecked__c, ConfirmationToken__c, Email__c, CreatedDate FROM IonisConnect__c WHERE ConfirmationToken__c = \'' + token + '\' AND EmailChecked__c = false AND EmailDeactivated__c = false LIMIT 1')

        if (token !== '' && users.records.length === 1) {
          const user = users.records[0]
          const userId = user.Id
          const email = user.Email__c

          const lastUser = await connection.query('SELECT Id FROM IonisConnect__c WHERE Email__c = \'' + email + '\' AND EmailDeactivated__c = false ORDER BY CreatedDate DESC LIMIT 1')

          if (lastUser.records.length === 1) {
            const lastId = lastUser.records[0].Id
            if (userId === lastId) {
              const ionisConnect = {
                Id: userId,
                ConfirmationToken__c: ' ',
                EmailChecked__c: true
              }

              const accounts = await connection.query('SELECT Id FROM Account WHERE PersonEmail = \'' + email + '\' AND Historise__c = false LIMIT 1')

              if (accounts.records.length === 1) {

                // debugger
                const account = {
                  Id: accounts.records[0].Id,
                  IonisConnect__c: userId
                }
                const updateAccount = await connection.sobject('Account').update(account)
                const updateIonisConnect = await connection.sobject('IonisConnect__c').update(ionisConnect)

                console.log(updateAccount)
                console.log(updateIonisConnect)
              } else {
                reject('ACCOUNT INEXISTANT')
              }
            } else {
              const ionisConnect = {
                Id: userId,
                ConfirmationToken__c: ' ',
                EmailDeactivated__c: true,
              }
              const updateIonisConnect = await connection.sobject('IonisConnect__c').update(ionisConnect)
              console.log(updateIonisConnect)
              reject('MAUVAIS TOKEN')
            }
          }
        } else {
          console.log('token invalide')
          reject('TOKEN INVALIDE')
        }

      } catch(ex) {
        reject(errorCode.ERROR_1)
      }
    })
  }

  /**
   * Check if the email and password match on his IonisConnect
   * @param {object} payload accessToken and instanceUrl
   * @param {string} email email of the user
   * @param {string} password password of the user
   * @param {string} division division of the school
   * @returns {object} with a boolean if the login and password is a success and the IonisConnect Id
   */
  static async verifyUser(payload, email, password, division) {
    return new Promise( async (resolve, reject) => {
      // Check ionisconnect
      const user = await this.checkUser(payload, email, division)
      if (user.exist) {
        const ionisConnect = user.ionisConnect
        const passwordHashed = ionisConnect.Password__c
        const salt = ionisConnect.PrivateKey__c

        const inputPassword = this.hashPassword(password, salt)


        const passwordMatches = passwordHashed === inputPassword

        const result = {
          passwordMatches: passwordMatches,
          ionisConnectId: user.ionisConnect.Id
        }

        resolve(result)
      } else {
        const result = {
          passwordMatches: false,
          ionisConnectId: undefined
        }
        resolve(result)
      }
    })
  }

  static async resetPassword(payload, email, division) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const user = await this.checkUser(payload, email, division)
      if (user.exist) {
        const securityToken = this.generateSecurityToken()
        console.log('security token', securityToken)

        const ionisConnect = user.ionisConnect

        console.log(ionisConnect)
        ionisConnect.SecurityToken__c = securityToken

        console.log(ionisConnect)

        const ionisConnectUpdated = await connection.sobject('IonisConnect__c').upsert(ionisConnect, 'Id')

        console.log(ionisConnectUpdated)
        if (ionisConnectUpdated.success) {
          resolve({ success: true })
        } else {
          reject(false)
        }
      } else {
        resolve({ success: false })
      }
    })
  }

  static async updatePassword(payload, password, token, email, currentPassword, division) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      let ionisConnectId = undefined
      if (currentPassword === '') {
        const ionisConnect = await connection.query(`SELECT Id FROM IonisConnect__c WHERE SecurityToken__c = '${token}' AND EmailChecked__c = true AND EmailDeactivated__c = false`)
        ionisConnectId = ionisConnect.records[0].Id
      } else {
        console.log(email, currentPassword, division)
        const user = await this.verifyUser(payload, email, currentPassword, division)
        console.log('usseeeer', user)
        if (user.passwordMatches) {
          ionisConnectId = user.ionisConnectId
        } else {
          resolve({ success: false, message: 'Votre mot de passe actuel est incorrect.' })
        }
      }
      if (ionisConnectId !== undefined) {
        const passwordSalt = this.getHashdPasswordSalt(password)
        const user = {
          Id: ionisConnectId,
          Password__c: passwordSalt.passwordHashed,
          PrivateKey__c: passwordSalt.salt,
          SecurityToken__c: ''
        }

        const ionisConnectUpdated = await connection.sobject('IonisConnect__c').upsert(user, 'Id')

        console.log(ionisConnectUpdated)
        if (ionisConnectUpdated.success) {
          resolve({ success: true, message: 'Votre mot de passe a bien été modifié' })
        } else {
          reject(false)
        }
      } else {
        resolve({ success: false, message: 'Une erreur est survenue lors de la modification de votre mot de passe' })
      }
    })
  }

  /**
   * Generate a random String using for password and Confirmation / Security token
   * @returns {string} A random string
   */
  static generateSecurityToken() {
    const key = CryptoJS.lib.WordArray.random(32).toString()
    return key
  }

  /**
   * Get the password hashed and the salt
   * @param {string} password Password provided by the user
   * @returns {object} An object with the salt and the password hashed
   */
  static getHashdPasswordSalt(password) {
    const salt = this.generateSecurityToken()
    const passwordHashed = this.hashPassword(password, salt)
    const passwordSalt = {
      salt: salt,
      passwordHashed: passwordHashed
    }

    return passwordSalt
  }

  /**
   * Hash a password with a salt
   * @param {string} password Password provided by the user
   * @param {string} salt Salt generated by the getHashdPasswordSalt function
   * @returns {string} A hashed password
   */
  static hashPassword(password, salt) {
    const passwordSalted = password+salt
    const hmacDigest = Base64.stringify(hmacSHA512(passwordSalted, salt))
    return hmacDigest
  }

  static async verifySecurityToken(payload, token) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const tokenValid = await connection.query(`SELECT Id FROM IONISConnect__c WHERE SecurityToken__c = '${token}' AND EmailChecked__c = true AND EmailDeactivated__c = false LIMIT 1`)
      if (tokenValid.records.length === 1) {
        resolve(true)
      } else {
        reject(false)
      }
    })
  }

  static async getApplicationPrice(payload, name) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const priceLabel = await connection.request('/services/data/v47.0/tooling/query/?q=SELECT+Id,Name,Value+FROM+CustomLabel+WHERE+Name=\'' + name + '\'')
      if (priceLabel.records.length > 0) {
        resolve(priceLabel.records[0].Value)
      }
      reject('no price')
    })
  }

  // static async getFirstInformationSource(paylaod) {
  //   const connection = this.connection(paylaod)
  //   return new Promise( async (resolve, reject) => {
  //     const firstInformationSource = await connection.request('/services/data/v47.0/ui-api/object-info/Opportunity/picklist-values/0129E000000qTq0QAE/First_information_source__c')
  //     const firstInformationSourceFilter = firstInformationSource.values.map(({attributes, validFor, ...keepAttrs}) => keepAttrs)
  //     resolve(firstInformationSourceFilter)
  //   })
  // }

  /**
   * Get the recordId of Salesforce object
   * @param {object} payload accessToken and instanceUrl
   * @param {string} name name of recordId
   * @returns {string} record Id
   */
  static async getRecordTypeId(payload, name, object) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const recordType = await connection.query('Select Id From RecordType WHERE Name = \'' + name + '\' AND SobjectType = \'' + object + '\' LIMIT 1')
      if (recordType.records.length > 0) {
        resolve(recordType.records[0].Id)
      }
    })
  }

  static async getPicklistValues(paylaod, object, recordTypeId, field) {
    const connection = this.connection(paylaod)
    return new Promise( async (resolve, reject) => {
      const values = await connection.request('/services/data/v47.0/ui-api/object-info/' + object + '/picklist-values/' + recordTypeId + '/' + field)
      const valuesFilter = values.values.map(({attributes, validFor, ...keepAttrs}) => keepAttrs)
      resolve(valuesFilter)
    })
  }

  static async getCampaign(payload, campaignName, school) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const campaign = await connection.query(`Select Id FROM Campaign WHERE Name = '${campaignName}' AND School__c = '${school}' AND Detail__c = 'JPC'`)
      // console.log(campaignId)
      if (campaign.records.length > 0) {
        resolve(campaign.records[0].Id)
      }
    })
  }

  /**
   * Get all sessions of a school
   * @param {object} payload accessToken and instanceUrl
   * @param {string} school name of the school
   * @returns {object[]} all sessions related to the school
   */
  static async getSessions(payload, school) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const sessions = await connection.query('SELECT Id, Active__c, Campus__c, Commercial__c, Division__c, MidYearSession__c, Name, Program__r.FullName__c, Program__r.Year__c, School__c, School_Year__c, StartDate__c FROM Session__c WHERE Active__c = true AND School__c = \'' + school + '\'')
      resolve(sessions.records)
    })
  }

  /**
   * Get all academic levels provided by the Account object
   * @param {object} payload accessToken and instanceUrl
   * @param {string} school name of the school
   * @returns {object[]} academic levels
   */
  static async getAcademicLevels(payload, school, recordTypeId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const academics = await connection.request('/services/data/v47.0/ui-api/object-info/Account/picklist-values/' + recordTypeId + '/AcademicLevel__pc')
      const academicLevelsFilter = academics.values.filter(el => el.label.includes(school))
      let academicLevels = []
      for (let i = 0; i < academicLevelsFilter.length; i++) {
        academicLevels.push({
          label: academicLevelsFilter[i].label.split('_')[0],
          value: academicLevelsFilter[i].value
        })
      }
      resolve(academicLevels)
    })
  }

  /**
   * Get all sections provided by the Account object
   * @param {*} payload
   * @param {*} academicLevels acamdelic levels
   * @param {*} school name of the school
   * @returns sections
   */
  static async getSections(payload, academicLevels, school) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const sectionsResults = await connection.request('/services/data/v47.0/ui-api/object-info/Account/picklist-values/0129E000000qUbkQAE/Section__pc')
      let sections = []
      for (let i = 0; i < academicLevels.length; i++) {
        const academicLevel = academicLevels[i].label
        const dependantSections = this.getDependantFields(academicLevel, sectionsResults.controllerValues, sectionsResults.values)
        let sectionsFormatted = []
        for (let j = 0; j < dependantSections.length; j++) {
          sectionsFormatted.push({
            label: dependantSections[j].label,
            value: dependantSections[j].value
          })
        }
        sections.push({
          academicLevel : academicLevels[i].value,
          sections: sectionsFormatted
        })
      }
      resolve(sections)
    })
  }

  /**
   *
   * @param {*} value Value selected
   * @param {*} controllerValues Values controlled
   * @param {*} values ALl values
   * @returns
   */
  static getDependantFields(value, controllerValues, values) {
    const fields = controllerValues[value]
    const dependantFields = values.filter(el => el.validFor.indexOf(fields) > -1)
    return dependantFields
  }

  static getSpecialisedEducationValues() {
    return ['Arts', 'Humanités, littérature et philosophie', 'Littérature et langues et cultures de l\'antiquité', 'Sciences de l\'ingénieur', 'Langues, littératures et culture étrangères et régionales', 'Mathématiques', 'Physique chimie', 'Sciences économiques et sociales', 'Histoire géographie, géopolitique et sciences politiques', 'Numérique et sciences informatiques', 'Sciences de la vie et de la terre', 'Biologie écologie']
  }

  static getSpecialisedEducation(account) {
    const optionsSpecialites = this.getSpecialisedEducationValues()
    let specialities = []
    if(account.TermGEnsSpeArts__c) {
      specialities.push(optionsSpecialites[0])
    }
    if(account.TermGEnsSpeBioEcologie__c) {
      specialities.push(optionsSpecialites[11])
    }
    if(account.TermGEnsSpeHistGeoSciencesPo__c) {
      specialities.push(optionsSpecialites[8])
    }
    if(account.TermGEnsSpeHumaniteLitteraturePhilo__c) {
      specialities.push(optionsSpecialites[1])
    }
    if(account.TermGEnsSpeLanguesLitteCultEtr__c) {
      specialities.push(optionsSpecialites[4])
    }
    if(account.TermGEnsSpeLittelanguesCultAntiq__c) {
      specialities.push(optionsSpecialites[2])
    }
    if(account.TermGEnsSpeMaths__c) {
      specialities.push(optionsSpecialites[5])
    }
    if(account.TermGEnsSpeNumSciencesInfo__c) {
      specialities.push(optionsSpecialites[9])
    }
    if(account.TermGEnsSpePhysiqueChimie__c) {
      specialities.push(optionsSpecialites[6])
    }
    if(account.TermGEnsSpeSciencesEco__c) {
      specialities.push(optionsSpecialites[7])
    }
    if(account.TermGEnsSpeSciencesInge__c) {
      specialities.push(optionsSpecialites[3])
    }
    if(account.TermGEnsSpeSVT__c) {
      specialities.push(optionsSpecialites[10])
    }

    return specialities
  }

  /**
   *
   * @param {*} payload
   * @param {*} ionisConnectId
   * @param {*} email
   * @param {*} division
   * @returns
   */
  static async getAccount(payload, ionisConnectId, email, division) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      try {
        const account = await connection.query('SELECT Id, AcademicLevel__pc, BACYear__pc, CountryOfBirth__pc, Copy_of_Diploma__c, Description, FirstName, Grant_Proof__c, Grant_Student__c, ID_Proof__c, Langue_1__c, Langue_2__c, Langue_3__c, LastName, Nationalite__c, Nationalite_2__c, Native_Language__pc, Niveau_langue_1__c, Niveau_langue_2__c, Niveau_Langue_3__c, PersonBirthdate, PersonContactId, PersonEmail, PersonMobilePhone, Phone, Reports_Cards__c, Salutation, Section__pc, SectionOfBAC__pc, ShippingCity, ShippingCountry, ShippingPostalCode, ShippingStreet, StageStop__c, TermGEnsSpeArts__c, TermGEnsSpeBioEcologie__c, TermGEnsSpeHistGeoSciencesPo__c, TermGEnsSpeHumaniteLitteraturePhilo__c, TermGEnsSpeLanguesLitteCultEtr__c, TermGEnsSpeLittelanguesCultAntiq__c, TermGEnsSpeMaths__c, TermGEnsSpeNumSciencesInfo__c, TermGEnsSpePhysiqueChimie__c, TermGEnsSpeSciencesEco__c, TermGEnsSpeSciencesInge__c, TermGEnsSpeSVT__c FROM Account WHERE PersonEmail = \'' + email + '\' AND IONIS_School__pc = \'' + division + '\' AND IonisConnect__c = \'' + ionisConnectId + '\' AND Historise__c = false LIMIT 1')
        console.log(account.records[0])
        if (account.records.length > 0) {
          const record = JSON.parse(JSON.stringify(account.records[0]).replace(/:null/gi, ":\"\""))
          // @todo voir si SF peut gérer ça ???
          if (record.StageStop__c === '') {
            record.StageStop__c = '0'
          }
          console.log(record)
          resolve(record)
        }
      }
      catch(ex) {
        reject(ex)
      }
    });
  }

  static async getCampaignMember(payload, accountId, campaignId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const campaignMember = await connection.query(`SELECT Id FROM CampaignMember WHERE ContactId = '${accountId}' AND CampaignId = '${campaignId}'`)
      if (campaignMember.records.length > 0) {
        resolve(campaignMember.records[0])
      }
      resolve({})
    })
  }

  static async getOpportunity(paylaod, opportunityId) {
    const connection = this.connection(paylaod)
    return new Promise( async (resolve, reject) => {
      const opportunity = await connection.query('SELECT Id, AccountId, Division__c, First_information_source__c, payment_status_candidature__c, Probability, ProgramFullName__c, ProgramYear__c, Session__c, SessionCampus__c, SessionStartDate__c, SessionStartDateFormated__c, StageName, Track__c, TrackMajor__c FROM Opportunity WHERE Id = \'' + opportunityId + '\'')
      resolve(opportunity.records[0])
    })
  }

  static async getOpportunities(payload, accountId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const opportunities = await connection.query('SELECT Id, AccountId, Division__c, First_information_source__c, payment_status_candidature__c, Probability, ProgramFullName__c, ProgramYear__c, Session__c, SessionCampus__c, SessionStartDate__c, SessionStartDateFormated__c, StageName, Track__c, TrackMajor__c FROM Opportunity WHERE AccountId = \'' + accountId + '\' AND OnlineApplication__c = true')
      resolve(opportunities.records)
    })
  }

  static async getAcademicInformation(payload, accountId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const academicInformation = await connection.query('SELECT Id, EstablishmentName__c, Location__c, NameOfInstitution__c, Type__c, YearOfGraduation__c FROM AcademicInformation__c WHERE Applicant__c = \'' + accountId + '\'')
      resolve(academicInformation.records)
    })
  }

  static async getWorkExperience(payload, accountId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const workExperience = await connection.query('SELECT Id, Description__c, Employer__c, EndDate__c, Location__c, Position__c, StartDate__c FROM WorkExperience__c WHERE Applicant__c = \'' + accountId + '\'')
      const records = JSON.parse(JSON.stringify(workExperience.records).replace(/:null/gi, ":\"\""))
      resolve(records)
    })
  }

  static async createAccount(payload, user) {
    const connection = this.connection(payload)
    return new Promise ( async (resolve, reject) => {
      const {
        academicLevel,
        email,
        firstName,
        lastName,
        nationality,
      } = user

      const recordTypeId = await this.getRecordTypeId(payload, process.env.REACT_APP_RECORDTYPE_ACCOUNT, 'Account')

      const account = {
        AcademicLevel__pc: academicLevel,
        Division__pc: process.env.REACT_APP_DIVISION, // Devrait être ISG mais bon on s'en sert plus
        FirstName: firstName,
        IONIS_School__pc: process.env.REACT_APP_SCHOOL,
        LastName: lastName,
        Nationalite__c: nationality,
        PersonEmail: email,
        RecordTypeId: recordTypeId,
        StageStop__c: '0',
        Statut__c: STAGE_PROSPECT
      }

      const createUser = await connection.sobject('Account').create(account)
      if (createUser.success) {
        resolve(true)
      }
      console.log(createUser)
    })
  }

  static async createTask(payload, opportunityId, message) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {

      const task = {
        Description: message,
        // IsPrivate: true,
        Subject: 'Question concernant cette candidature',
        WhatId: opportunityId
      }

      const taskCreated = await connection.sobject('Task').create(task)
      if (taskCreated.success) {
        resolve(true)
      }
    })
  }

  static async createCampaignMember(payload, contactId, campaignId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const campaignMember = {
        CampaignId: campaignId,
        ContactId: contactId,
        Status: 'Registered'
      }

      const campaignMemberCreated = await connection.sobject('CampaignMember').create(campaignMember)
      if (campaignMemberCreated.success) {
        console.log('MEMBRE DE CAMPAGNE CREE', campaignMemberCreated)
        resolve({ success: true, campaignMember: campaignMemberCreated})
      }
    })
  }

  static async updateAccount(payload, account, activeStep, JPCWished = false, paymentType = 'JPC', lastStep = false) {
    const connection = this.connection(payload)
    // let cloneAccount = {PersonContactId, ...account}
    const { attributes, PersonContactId, ...accountFilter } = account
    if (!lastStep) {
      let currentStageStop = parseInt(accountFilter.StageStop__c)
      console.log('********************', activeStep, JPCWished, paymentType)
      if (activeStep === 1 && !JPCWished && paymentType === 'JPC') { // Si nous sommes à l'étape de candidature et qu'on ne souhaite pas participer aux JPC, on skip le paiement
        currentStageStop = currentStageStop + 2
      } else {
        currentStageStop = currentStageStop + 1
      }
      accountFilter.StageStop__c = currentStageStop
    }

    return new Promise((resolve, reject) => {
      connection.sobject('Account').upsert(accountFilter , 'Id', (err, rets) => {
        console.log('erreur account ?', err)
        if (err) { reject(new Error(err)) }
        if (!err) {
          const newAcount = {PersonContactId, ...accountFilter}
          resolve({ success: true, account: newAcount })
        }
      })
    })
  }

  static async updateOpportunity(payload, opportunity, JPCWished = false, paymentType = 'JPC') {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      const { attributes, Probability, ProgramFullName__c, ProgramYear__c, Division__c, SessionCampus__c, SessionStartDate__c, SessionStartDateFormated__c, ...opportunityFilter } = opportunity
      if (!JPCWished && opportunity.StageName === STAGE_QUALIFICATION) {
        opportunityFilter.StageName = STAGE_COURS
        console.log('aie aie aie')
      }
      if (opportunityFilter.OwnerId === '') {
        delete opportunityFilter.OwnerId
      }
      if (opportunityFilter.Id === undefined) {
        const createOpportunity = await connection.sobject('Opportunity').create(opportunityFilter)
        if (createOpportunity.success) {
          const opportunityId = createOpportunity.id
          const opportunityCreated = await this.getOpportunity(payload, opportunityId)
          const opportunities = await this.getOpportunities(payload, opportunity.AccountId)
          resolve({ success: true, opportunity: opportunityCreated, opportunities })
        } else {
          reject('erreur creation oppy')
        }
      } else {
        const updateOpportunity = await connection.sobject('Opportunity').upsert(opportunityFilter, 'Id')
        if (updateOpportunity.success) {
          const opportunityId = opportunityFilter.Id
          const opportunityUpdated = await this.getOpportunity(payload, opportunityId)
          const opportunities = await this.getOpportunities(payload, opportunity.AccountId)
          resolve({ success: true, opportunity: opportunityUpdated, opportunities })
        } else {
          reject('erreur maj oppy')
        }
      }
    })
  }

  static async updateStageName(payload, opportunityId, stageName) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      try {
        const opportunity = {
          Id: opportunityId,
          StageName: stageName
        }
        const stageNameUpdated = await connection.sobject('Opportunity').update(opportunity)
        if (stageNameUpdated.success) {
          resolve(true)
        }
      }
      catch(ex) {
        reject(ex)
      }
    })
  }

  static async updateAcademicInformation(payload, academicInformation, accountId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      if (academicInformation.length > 0) {
        // On cherche les objets existants pour supprimer ceux que l'utilisateur a supprimé
        const allAcademicInformation = await this.getAcademicInformation(payload, accountId)
        const idsAcademicInformation = academicInformation.map(value => value.Id)
        const idsAllAcademicInformation = allAcademicInformation.map(value => value.Id)
        const idsToDelete = idsAllAcademicInformation.filter(x => !idsAcademicInformation.includes(x))
        let deletedAcademicInformation = []
        if (idsToDelete.length > 0) {
          deletedAcademicInformation = await connection.sobject('AcademicInformation__c').destroy(idsToDelete)
        }
        const existingAcademicInformation = academicInformation.filter(el => el.hasOwnProperty('Id'))
        const newAcademicInformation = academicInformation.filter(el => !el.hasOwnProperty('Id'))
        console.log('existingAcademicInformation', existingAcademicInformation)
        console.log('newAcademicInformation', newAcademicInformation)
        let academicInformationCreated = undefined
        if (newAcademicInformation !== undefined) {
          academicInformationCreated = await connection.sobject('AcademicInformation__c').create(newAcademicInformation)
          console.log(academicInformationCreated)
          // result.concat(academicInformationCreated)

        }
        let academicInformationUpdated = undefined
        if (existingAcademicInformation !== undefined) {
          academicInformationUpdated = await connection.sobject('AcademicInformation__c').upsert(existingAcademicInformation, 'Id')
          console.log(academicInformationUpdated)
          // result.concat(academicInformationUpdated)
        }
        const result = [...academicInformationCreated, ...academicInformationUpdated, ...deletedAcademicInformation]
        console.log(result)
        const hasErrors = result.find(el => el.success === false)
        const academicInformationResult = await this.getAcademicInformation(payload, accountId)
        console.log('gfdgkdghdgh', academicInformationResult)
        if (hasErrors === undefined) {
          resolve({ success: true, academicInformation: academicInformationResult })
        } else {
          resolve({ success: false })
        }
      } else {
        const academicInformationResult = await this.getAcademicInformation(payload, accountId)
        if (academicInformationResult.length === 1) {
          const id = academicInformationResult[0].Id
          await connection.sobject('AcademicInformation__c').destroy(id)
        }
        resolve({ success: true, academicInformation: [] })
      }
    })
  }

  static async updateWorkExperience(payload, workExperience, accountId) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      if (workExperience.length > 0) {
        // On cherche les objets existants pour supprimer ceux que l'utilisateur a supprimé
        const allWorkExperience = await this.getWorkExperience(payload, accountId)
        const idsWorkExperience = workExperience.map(value => value.Id)
        const idsAllWorkExperience = allWorkExperience.map(value => value.Id)
        const idsToDelete = idsAllWorkExperience.filter(x => !idsWorkExperience.includes(x))
        let deletedWorkExperience = []
        if (idsToDelete.length > 0) {
          deletedWorkExperience = await connection.sobject('WorkExperience__c').destroy(idsToDelete)
        }
        const existingWorkExperience = workExperience.filter(el => el.hasOwnProperty('Id'))
        const newWorkExperience = workExperience.filter(el => !el.hasOwnProperty('Id'))
        console.log('existingWorkExperience', existingWorkExperience)
        console.log('newWorkExperience', newWorkExperience)
        let workExperienceCreated = undefined
        if (newWorkExperience !== undefined) {
          workExperienceCreated = await connection.sobject('WorkExperience__c').create(newWorkExperience)
          console.log(workExperienceCreated)
          // result.concat(academicInformationCreated)

        }
        let workExperienceUpdated = undefined
        if (existingWorkExperience !== undefined) {
          workExperienceUpdated = await connection.sobject('WorkExperience__c').upsert(existingWorkExperience, 'Id')
          console.log(workExperienceUpdated)
          // result.concat(workExperienceUpdated)
        }
        const result = [...workExperienceCreated, ...workExperienceUpdated, ...deletedWorkExperience]
        console.log(result)
        const hasErrors = result.find(el => el.success === false)
        const workExperienceResult = await this.getWorkExperience(payload, accountId)
        console.log('gfdgkdghdgh', workExperienceResult)
        if (hasErrors === undefined) {
          resolve({ success: true, workExperience: workExperienceResult })
        } else {
          resolve({ success: false })
        }
      } else {
        const workExperienceResult = await this.getWorkExperience(payload, accountId)
        if (workExperienceResult.length === 1) {
          const id = workExperienceResult[0].Id
          await connection.sobject('WorkExperience__c').destroy(id)
        }
        resolve({ success: true, workExperience: [] })
      }
    })
  }

  static async getAttachmentFromSobject(payload, account, opportunity) {
    console.log("getAttachmentFromSobject");
    const connection = await this.connection(payload)
    return new Promise ( async (resolve, reject) => {
      const resultAccount = await connection.query(`SELECT Id, Name, Description, Body FROM Attachment WHERE ParentId = '${account.Id}'`);
      let resultOpportunities;
      if( opportunity !== undefined ) {
        resultOpportunities = await connection.query(`SELECT Id, Name, Description, Body FROM Attachment WHERE ParentId = '${opportunity.Id}'`);
      }

      console.log(resultAccount);
      console.log(resultOpportunities);
      let records = [];
      for( let x = 0; x < resultAccount.records.length; x++ ) {
        records.push(resultAccount.records[x]);
      }
      if ( resultOpportunities !== undefined ) {
        for( let y = 0; y < resultOpportunities.records.length; y++ ) {
          records.push(resultOpportunities.records[y]);
        }
      }
      console.log(records);
      let supportingDocumentInSalesforce = [];
      if ( records.length > 0 ) {
        let filesIdentityDocument = [];
        let filesGradesYearN = [];
        let filesGradesYearN1 = [];
        let filesDiplomasObtained = [];
        let filesCV = [];
        let filesMotivationLetter = [];
        let filesNotificationGrantScholarship = [];
        for ( let i = 0; i < records.length; i++ ) {
          if ( records[i].Description === "Pièce d'identité" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesIdentityDocument.push(records[i]);
          }
          if ( records[i].Description === "Bulletins de notes année N" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesGradesYearN.push(records[i]);
          }
          if ( records[i].Description === "Bulletins de notes année N-1" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesGradesYearN1.push(records[i]);
          }
          if ( records[i].Description === "Diplômes obtenus" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesDiplomasObtained.push(records[i]);
          }
          if ( records[i].Description === "CV" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesCV.push(records[i]);
          }
          if ( records[i].Description === "Lettre de motivation" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesMotivationLetter.push(records[i]);
          }
          if ( records[i].Description === "Avis d'attribution de bourse" ) {
            records[i].fileValue = records[i].Description;
            records[i].name = records[i].Name;
            filesNotificationGrantScholarship.push(records[i]);
          }
        }
        let supportingDocumentToAdd;

        //pièce d'identité
        if ( filesIdentityDocument.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesIdentityDocument[0].Description,
            files : filesIdentityDocument
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }

        //Bulletins de notes année N
        if ( filesGradesYearN.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesGradesYearN[0].Description,
            files : filesGradesYearN
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }

        //Bulletins de notes année N-1
        if ( filesGradesYearN1.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesGradesYearN1[0].Description,
            files : filesGradesYearN1
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }

        //Diplômes obtenus
        if ( filesDiplomasObtained.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesDiplomasObtained[0].Description,
            files : filesDiplomasObtained
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }

        //CV
        if ( filesCV.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesCV[0].Description,
            files : filesCV
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }

        //Lettre de motivation
        if ( filesMotivationLetter.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesMotivationLetter[0].Description,
            files : filesMotivationLetter
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }

        //Avis d'attribution de bourse
        if ( filesNotificationGrantScholarship.length > 0 ) {
          supportingDocumentToAdd = {
            supportingDocument :  filesNotificationGrantScholarship[0].Description,
            files : filesNotificationGrantScholarship
          }
          supportingDocumentInSalesforce.push(supportingDocumentToAdd);
        }
      }
      console.log('supportingDocumentInSalesforce');
      console.log(supportingDocumentInSalesforce);
      resolve({success : true, supportingDocument: supportingDocumentInSalesforce}); //
    })
  }

  static async getAttachmentsForDeletion( payload, account, opportunity ) {
    console.log("getAttachmentsForDeletion");
    const connection = this.connection(payload)
    return new Promise ( async (resolve, reject) => {
      const resultAccount = await connection.query(`SELECT Id, Name, Description, Body FROM Attachment WHERE ParentId = '${account.Id}'`);
      const resultOpportunities = await connection.query(`SELECT Id, Name, Description, Body FROM Attachment WHERE ParentId = '${opportunity.Id}'`);
      console.log(resultAccount);
      console.log(resultOpportunities);
      let records = [];
      for( let x = 0; x < resultAccount.records.length; x++ ) {
        records.push(resultAccount.records[x]);
      }
      for( let y = 0; y < resultOpportunities.records.length; y++ ) {
        records.push(resultOpportunities.records[y]);
      }
      // console.log("records");
      // console.log(records);
      resolve(records);
    })
  }

  static async deleteAttachmentToSobject(payload, files, account, opportunity) {
    const connection = await this.connection(payload)
    return new Promise ( async (resolve, reject) => {
      let attachments = await Salesforce.getAttachmentsForDeletion(payload, account, opportunity);
      let results = [];
      let indexAttachmentsToRemove = [];
      console.log("deleteAttachmentToSobject");
      console.log(files);
      console.log(attachments);
      if ( attachments.length > 0 ) {
        // do difference between attachments in salesforce and from app
        for( let i = 0; i < files.length; i++ ) {
          for ( let j = 0; j < attachments.length; j++ ) {
            if( files[i].Id === attachments[j].Id ) {
              indexAttachmentsToRemove.push(j);
            }
          }
        }
        indexAttachmentsToRemove.sort(function(a, b) {
          return b- a;
        });
        console.log("indexAttachmentsToRemove");
        console.log(indexAttachmentsToRemove);
        for( let x = 0; x < indexAttachmentsToRemove.length; x++ ) {
          attachments.splice(indexAttachmentsToRemove[x], 1);
        }
        console.log(attachments);
        for ( let y = 0; y < attachments.length; y++ ) {
          let result = await connection.sobject('Attachment').delete(attachments[y].Id);
          console.log(result);
          results.push(result);
        }
      }
      resolve(results);
    })
  }

  static async addAttachmentToSobject(payload, files, account, opportunity) {
    const connection = await this.connection(payload);
    // const connection = await this.login();
    console.log("connection");
    console.log(connection);
    console.log(connection.accessToken);
    console.log("addAttachmentToSobject");
    console.log(files);
    const zip = new JSZip();
    let requestTxtString = "[\n";
    // zip.folder("files");
    for( let i = 0; i < files.length; i++ ) {
      zip.file(`${files[i].name}`, files[i]);
      if ( files[i].fileValue === 'CV' || files[i].fileValue === 'Lettre de motivation' ) {
        requestTxtString = requestTxtString + `  {\n"Name": "${files[i].name}",\n"ParentId": "${opportunity.Id}",\n"Description": "${files[i].fileValue}",\n"Body": "#${files[i].name}",\n"ContentType": "${files[i].type}"\n  }`;
      }
      else {
        requestTxtString = requestTxtString + `  {\n"Name": "${files[i].name}",\n"ParentId": "${account.Id}",\n"Description": "${files[i].fileValue}",\n"Body": "#${files[i].name}",\n"ContentType": "${files[i].type}"\n  }`;
      }
      if ( i !== files.length - 1 ) {
        requestTxtString = requestTxtString + `,\n`;
      }
      else {
        requestTxtString = requestTxtString + `\n`;
      }
    }
    requestTxtString = requestTxtString + "]";
    console.log('requestTxtString');
    console.log(requestTxtString);
    let jobTxt = `<?xml version="1.0" encoding="UTF-8"?><jobInfo xmlns="http://www.force.com/2009/06/asyncapi/dataload"><operation>insert</operation><object>Attachment</object><contentType>ZIP_JSON</contentType></jobInfo>`;
    // let jobResult = await axios ({
    //   method : 'POST',
    //   url : `${connection.instanceUrl}/services/async/47.0/job`,
    //   headers : {
    //     "X-SFDC-Session": connection.accessToken,
    //     "Content-Type" : "application/xml;charset=UTF-8",

    //   },
    //   data : jobTxt //
    // })
    let data = { "url" : connection.instanceUrl, "accessToken" : connection.accessToken, "jobTxt" : jobTxt }
    let headerOption =  { headers : {
      "Content-Type" : "application/json;charset=UTF-8",
    }}
    console.log("localhost3000");
    let jobResult = await axios.post(`https://inscriptions.isg.fr/createJob`, data, headerOption)
    console.log("jobResult");
    console.log(jobResult.data);
    let jobId;
    if(jobResult.data !== null ) {
      jobId = jobResult.data.split("<id>")[1];
      jobId = jobId.split("</id>")[0];
    }
    let blob = new Blob([requestTxtString], { type: "text/plain;charset=utf-8" });
    console.log('blob');
    console.log(blob);
    zip.file('request.txt', blob);
    let requestZip = await zip.generateAsync({ type: 'blob' });
    let zipInfos = await zip.loadAsync(requestZip);
    console.log("zipInfos");
    console.log(zipInfos);
    let batchResult = await axios ({
      method :'post',
      url : `${connection.instanceUrl}/services/async/53.0/job/${jobId}/batch`,
      headers : {
        "X-SFDC-Session": connection.accessToken,
        "Content-Type" : "zip/json",
      },
      data : requestZip
    });
    console.log("batchResult");
    console.log(batchResult);
    let batchId;
    if(batchResult.data !== null ) {
      batchId = batchResult.data.id;
    }
    let checkResult = await this.checkBatch(connection, jobId, batchId, account, opportunity);
    console.log("checkResult");
    console.log(checkResult);
    let result = true;
    //gérer la vérification du batch !! avec un poll d'appel.
    // result = await this.resultBatch(payload, jobId, batchId);
    let closedResultJob = await this.closeJob(connection, jobId);
    console.log("closedResultJob");
    console.log(closedResultJob);
    console.log("result");
    console.log(result);
    return result;
  }

  static async checkBatch(connection, jobId, batchId, account, opportunity) {
    return new Promise( async (resolve, reject) => {
      console.log("checkBatch");
      let queuedResult = await axios ({
        method :'get',
        url : `${connection.instanceUrl}/services/async/53.0/job/${jobId}/batch/${batchId}`,
        headers : {
          "X-SFDC-Session": connection.accessToken,
        },
      });
      console.log("queuedResult");
      console.log(queuedResult);
      if ( queuedResult.data.state === 'Queued' || queuedResult.data.state === 'InProgress') {
        // await new Promise(resolve => setTimeout(resolve, 10000));
        // resolve(queuedResult);
        console.log('in progress')
        setTimeout(() => {
          this.checkBatch(connection, jobId, batchId, account, opportunity)
        }, 1000);
      }

      if (queuedResult.data.state === 'Completed' || queuedResult.data.state === 'Failed') {
        console.log('comptedted batch')
        console.log(queuedResult)
        const supportingDocuments = await this.getAttachmentFromSobject(connection, account, opportunity)
        store.dispatch(updateSupportingDocument(supportingDocuments.supportingDocument))
        resolve(queuedResult)
        console.log('on est pas censé arriver ici')
      }
    });
  }

  static async resultBatch(payload, jobId, batchId) {
    const connection = this.connection(payload);
    return new Promise( async (resolve, reject) => {
      console.log("resultBatch");
      let result = await axios ({
        method: 'POST',
        url : `${connection.instanceUrl}/services/async/53.0/job/${jobId}/batch/${batchId}/result`,
        headers : {
          "X-SFDC-Session": connection.accessToken,
        },
      });
      console.log("result");
      console.log(result);
      resolve(result);
    });
  }

  static async closeJob(connection, jobId) {
    return new Promise( async (resolve, reject) => {
      console.log("closeJob");
      let closeJobTxt = `<?xml version="1.0" encoding="UTF-8"?><jobInfo xmlns="http://www.force.com/2009/06/asyncapi/dataload"><state>Closed</state></jobInfo>`;
      let closeJobResult = await axios ({
        method : 'post',
        url : `${connection.instanceUrl}/services/async/53.0/job/${jobId}`,
        headers : {
          "Access-Control-Allow-Origin": "*",
          "X-SFDC-Session": connection.accessToken,
          "Content-Type": "application/xml;charset=UTF-8",
        },
        data : closeJobTxt
      });
      console.log("closeJobResult");
      console.log(closeJobResult);
      resolve(closeJobResult);
    });
  }

  static async updateStageStop(payload, id, stage) {
    const connection = this.connection(payload)
    const account = {
      Id: id,
      StageStop__c: parseInt(stage)
    }
    return new Promise( async (resolve, reject) => {
      connection.sobject('Account').upsert(account, 'Id', (err, rets) => {
        if (err) { reject(new Error(err)) }
        if (!err) {
          resolve({ success: true, stage: stage })
        }
      })
    })
  }

  static async goToPayment(payload, id) {
    const connection = this.connection(payload)
    const opportunity = {
      Id: id,
      Payment_mode__c: 'CB',
      payment_status_candidature__c: 'reached_bank_website'
    }
    return new Promise( async (resolve, reject) => {
      connection.sobject('Opportunity').upsert(opportunity, 'Id', (err, rets) => {
        if (err) { reject(new Error(err)) }
        if (!err) {
          resolve({ sucess: true, status: 'reached_bank_website' })
        }
      })
    })
  }

  static async test(payload) {
    return new Promise((resolve, reject) => {
      const connection = new jsforce.Connection({
        accessToken: payload.accessToken,
        instanceUrl: payload.instanceUrl
      })
      connection.sobject('Account').describe( (err, meta) => {
        if (err) { reject(new Error("Erreur")) }
        if (!err) {
          // console.log(meta)
          for (let i = 0; i < meta.fields.length; i++) {
            if (meta.fields[i].name === 'Division__pc') {
              console.log(meta.fields[i])
            }
            if (meta.fields[i].name === 'AcademicLevel__pc') {
              console.log(meta.fields[i])
            }
          }
        }
      })
    })
  }

  static async simulatePayment(payload, opportunities, accountId, campaignMemberId, hasOneOppyCompleted) {
    const connection = this.connection(payload)
    return new Promise( async (resolve, reject) => {
      let oppys = []
      for (let i = 0; i < opportunities.length; i++) {
        let updateOppy = {
          Id: opportunities[i].Id,
          AccountId: accountId,
          ApplicationPayment__c: true,
          Payment_mode__c: 'CB',
          PaymentReferences__c: 'ref paiement',
          ApplicationPaymentDate__c: new Date(),
          payment_status_candidature__c: 'OK'
        }
        if (opportunities[i].StageName === 'Qualification') {
          updateOppy.StageName = 'En cours'
        }
        oppys.push(updateOppy)
      }
      // if (hasOneOppyCompleted) {
      //   updateOppy.StageName = 'Candidature complète'
      // }
      console.log(oppys)
      const updateOppys = await connection.sobject('Opportunity').upsert(oppys, 'Id')
      console.log('!!!!!!!!uufduf')
      console.log(updateOppys)

      const statusCampaign = {
        Id: campaignMemberId,
        Status: 'Paid'
      }

      const updateCampaignMember = await connection.sobject('CampaignMember').upsert(statusCampaign, 'Id')

      const oppy = await this.updateOpportunity(payload, oppys[0])
      // connection.sobject('Opportunity').upsert(updateOppy, 'Id')
      resolve({ success: true, opportunity: oppy.opportunity, opportunities: oppy.opportunities })
    })
  }
}

export default Salesforce
