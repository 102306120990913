import React from 'react';
import { Field } from 'formik';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

const renderOptions = (options, disableValue, field) => {
  return options.map((option) => (
    <FormControlLabel
      // checked={field.value === option.value}
      disabled={disableValue !== undefined ? disableValue.includes(option.value) : false}
      key={option.key}
      value={option.value}
      control={<Radio />}
      label={option.key}
    />
  ));
};

const RadioButtonExample = (props) => {
  const { label, name, options, disableValue, ...rest } = props;
  return (
    <div>
      {/* <label htmlFor={name}>{label}</label> */}
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            const { value: selectedValue } = field;
            return(
              <FormControl>
                <FormLabel>{label}</FormLabel>
                <RadioGroup {...field} {...rest} name={name} row value={selectedValue ? selectedValue : ''}>
                  {options ? renderOptions(options, disableValue, field) : ''}
                </RadioGroup>
              </FormControl>
            )
            // return options.map(option => {
            //   return (
            //     <React.Fragment key={option.key}>
            //       <input
            //         type='radio'
            //         id={option.id}
            //         {...field}
            //         value={option.value}
            //         checked={field.value === option.value}
            //       />
            //       <label htmlFor={option.id}>{option.key}</label>

            //     </React.Fragment>
            //   );
            // })
          }
        }
      </Field>
    </div>
  );
};

export default RadioButtonExample
