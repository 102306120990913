import { Button } from "@mui/material"
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

const ButtonBack = ({ handleBack, activeStep, isSubmitting, isSubmittingBack }) => {
  return (
  <Button size="small" onClick={handleBack} disabled={(activeStep === 0) || isSubmitting || isSubmittingBack}>
    <KeyboardArrowLeft />
    Précédent
  </Button>
  )
}

const ButtonNext = ({ activeStep, maxSteps, isSubmitting }) => {
  return (
  <Button
    disabled={(activeStep === maxSteps - 1) || isSubmitting}
    size="small"
    type="submit"
  >
    Suivant
    <KeyboardArrowRight />
  </Button>
  )
}

export {
  ButtonBack,
  ButtonNext
}
