const currentYear = new Date().getFullYear()

const portalFormModel = {
  formId: 'portalForm',
  formField: {
    account: {
      AcademicLevel__pc: {
        label: 'Niveau académique*',
        name: 'account.AcademicLevel__pc',
        requiredErrorMsg: 'Le niveau académique est requis'
      },
      BACYear__pc: {
        label: 'Année d\'obtention du BAC*',
        name: 'account.BACYear__pc',
        requiredErrorMsg: 'L\'année du BAC est requise',
        minMaxErrorMsg: 'L\'année du BAC doit être comprise entre 1970 et ' + currentYear
      },
      CGV__c: {
        name: 'account.CGV__c'
      },
      CountryOfBirth__pc: {
        label: 'Pays de naissance*',
        name: 'account.CountryOfBirth__pc',
        requiredErrorMsg: 'Le pays de naissance est requis'
      },
      Description: {
        label: 'Si vous avez une remarque ou une question',
        name: 'account.Description'
      },
      FirstName: {
        label: 'Prénom*',
        name: 'account.FirstName',
        requiredErrorMsg: 'Le prénom est requis',
        maxLengthErrorMsg: 'Le prénom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Grant_Student__c: {
        label: 'Je suis boursier de l\'éducation nationale',
        name: 'account.Grant_Student__c'
      },
      Langue_1__c: {
        label: 'Langue vivante 1*',
        name: 'account.Langue_1__c',
        requiredErrorMsg: 'La langue vivante 1 est requise',
        notSameValue: 'Vous ne pouvez pas sélectionner deux langues vivantes identiques'
      },
      Langue_2__c: {
        label: 'Langue vivante 2',
        name: 'account.Langue_2__c',
        requiredErrorMsg: 'La langue vivante 2 est requise',
        notSameValue: 'Vous ne pouvez pas sélectionner deux langues vivantes identiques'
      },
      Langue_3__c: {
        label: 'Langue vivante 3',
        name: 'account.Langue_3__c',
        requiredErrorMsg: 'La langue vivante 3 est requise',
        notSameValue: 'Vous ne pouvez pas sélectionner deux langues vivantes identiques'
      },
      LastName: {
        label: 'Nom*',
        name: 'account.LastName',
        requiredErrorMsg: 'Le nom est requis',
        maxLengthErrorMsg: 'Le nom doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Nationalite__c: {
        label: 'Nationalité*',
        name: 'account.Nationalite__c',
        requiredErrorMsg: 'La nationalité est requise'
      },
      Nationalite_2__c: {
        label: 'Autre nationalité',
        name: 'account.Nationalite_2__c'
      },
      Native_Language__pc: {
        label: 'Langue maternelle*',
        name: 'account.Native_Language__pc',
        requiredErrorMsg: 'La langue maternelle est requise'
      },
      Niveau_langue_1__c: {
        label: 'Niveau de langue*',
        name: 'account.Niveau_langue_1__c',
        requiredErrorMsg: 'Le niveau de langue est requis'
      },
      Niveau_langue_2__c: {
        label: 'Niveau de langue',
        name: 'account.Niveau_langue_2__c',
        requiredErrorMsg: 'Le niveau de langue est requis'
      },
      Niveau_Langue_3__c: {
        label: 'Niveau de langue',
        name: 'account.Niveau_Langue_3__c',
        requiredErrorMsg: 'Le niveau de langue est requis'
      },
      PersonBirthdate: {
        label: 'Date de naissance*',
        name: 'account.PersonBirthdate',
        requiredErrorMsg: 'La date de naissance est requise'
      },
      PersonMailingCity: {
        label: 'Ville',
        name: 'account.PersonMailingCity',
        requiredErrorMsg: 'La ville est requise'
      },
      PersonMobilePhone: {
        label: 'Mobile',
        name: 'account.PersonMobilePhone',
        requiredErrorMsg: 'Le téléphone fixe ou le mobile est requis ',
        formatInvalid: 'Le format du mobile est incorrect',
        maxLengthErrorMsg: 'Le mobile doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Phone: {
        label: 'Téléphone fixe',
        name: 'account.Phone',
        requiredErrorMsg: 'Le téléphone fixe ou le mobile est requis ',
        formatInvalid: 'Le format du téléphone fixe est incorrect',
        maxLengthErrorMsg: 'Le téléphone fixe doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      Salutation: {
        label: 'Civilité',
        name: 'account.Salutation',
        requiredErrorMsg: 'La civilité est requise'
      },
      Section__pc: {
        label: 'Section*',
        name: 'account.Section__pc',
        requiredErrorMsg: 'La section est requise'
      },
      SectionOfBAC__pc: {
        label: 'Type de BAC*',
        name: 'account.SectionOfBAC__pc',
        requiredErrorMsg: 'Le type de BAC est requis'
      },
      ShippingCity: {
        label: 'Ville*',
        name: 'account.ShippingCity',
        requiredErrorMsg: 'La ville est requise',
        maxLengthErrorMsg: 'La ville doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      ShippingCountry: {
        label: 'Pays de résidence*',
        name: 'account.ShippingCountry',
        requiredErrorMsg: 'Le pays de résidence est requis'
      },
      ShippingPostalCode: {
        label: 'Code postal*',
        name: 'account.ShippingPostalCode',
        requiredErrorMsg: 'Le code postal est requis',
        maxLengthErrorMsg: 'Le code postal doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      ShippingStreet: {
        label: 'Adresse*',
        name: 'account.ShippingStreet',
        requiredErrorMsg: 'L\'adresse est requise',
        maxLengthErrorMsg: 'L\'adresse postale doit contenir au maximum ${max} caractères' // eslint-disable-line no-template-curly-in-string
      },
      lieuDit: {
        label: 'Lieu dit',
        name: 'account.lieuDit'
      },
      complementAdresse1: {
        label: 'Complément adresse 1',
        name: 'account.complement1'
      },
      complementAdresse2: {
        label: 'Complément adresse 2',
        name: 'account.complement2'
      },
      Reports_Cards__c: {
        label: 'Bulletins de notes',
        name: 'account.Reports_Cards__c',
        requiredErrorMsg: 'Une pièce jointe des bulletins de note est obligatoire'
      },
      Copy_of_Diploma__c: {
        label: 'Copie du diplôme',
        name: 'account.Copy_of_Diploma__c',
        requiredErrorMsg: 'Une pièce jointe des diplômes obtenus est obligatoire'
      },
      ID_Proof__c: {
        label: "Justificatif d'identité",
        name: 'account.ID_Proof__c',
        requiredErrorMsg: "Une pièce jointe des d'un justificatif d'identité est obligatoire"
      },
      Grant_Proof__c: {
        label: "Avis d'attribution de bourse",
        name: 'account.Grant_Proof__c',
        requiredErrorMsg: "L'avis d'attribution de bourse est obligatoire"
      }
    },
    opportunity: {
      First_information_source__c: {
        label: 'Comment avez-vous connu l\'école ?',
        name: 'opportunity.First_information_source__c'
      },
      Session__c: {
        label: 'Rentrée',
        name: 'opportunity.Session__c',
        requiredErrorMsg: 'Vous devez saisir tous les champs pour pouvoir continuer'
      },
      Track__c: {
        label: 'Filière',
        name: 'opportunity.Track__c'
      },
      TrackMajor__c: {
        label: 'Programmes',
        name: 'opportunity.TrackMajor__c'
      }
    },
    SpecialisedEducation: {
      label: 'Enseignement de spécialités',
      name: 'SpecialisedEducation',
      minErrorMsg: 'Vous devez choisir au minimun 2 spécialités',
      maxErrorMsg: 'Vous devez choisir au maximum 2 spécialités'
    }
  }
}

export default portalFormModel
