import { FastField, FieldArray, useField } from "formik"
import { Alert, Button, Grid, Paper } from "@mui/material"
import { InputExample } from "../FormFields/InputExample"
import { SelectField } from "../FormFields"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import React from "react"
import { useSelector } from "react-redux"

const DiplomaItem = ({ index }) => {

  const [EstablishmentNameField] = useField({ name: `AcademicInformation__c[${index}].EstablishmentName__c`})
  const [LocationField] = useField({ name: `AcademicInformation__c[${index}].Location__c`})
  const [YearOfGraduationField] = useField({ name: `AcademicInformation__c[${index}].YearOfGraduation__c`})
  const optionsCountries = useSelector(state => state.salesforce.countries)
  const currentYear = new Date().getFullYear()

  return (
    <>
      <Grid item sm={4} xs={12}>
        <FastField component={InputExample} label="Nom de l'établissement*" name={EstablishmentNameField.name} />
      </Grid>
      <Grid item sm={4} xs={12}>
        <SelectField data={optionsCountries} fullWidth label="Pays*" name={LocationField.name} />
      </Grid>
      <Grid item sm={3} xs={12}>
        <FastField component={InputExample} InputProps={{ inputProps: { min: 1970, max: currentYear } }} label="Année d'obtention du diplôme*" name={YearOfGraduationField.name} type="number" />
      </Grid>
    </>
  )
}

const Diplomas = (props) => {

  const accountId = useSelector(state => state.salesforce.account.Id)

  return (
    <FieldArray name="AcademicInformation__c">
      {({ form, ...fieldArrayHelpers }) => {
        const onAddClick = (e) => {
          e.preventDefault()
          fieldArrayHelpers.push({
            // Id: form.values.AcademicInformation__c.length,
            Applicant__c: accountId,
            EstablishmentName__c: '',
            Location__c: '',
            YearOfGraduation__c: ''
          })
        }
        const onRemoveClick = (e, index) => {
          console.log(e)
          e.preventDefault()
          fieldArrayHelpers.remove(index)
        }
        return (
          <>
            {form.values.AcademicInformation__c.length === 0 && <Alert elevation={6} severity="info">Si vous avez obtenu des diplômes lors de votre scolarité, cliquez sur le bouton <strong>Ajouter un diplôme</strong>.</Alert>}
            <Paper sx={{ display: form.values.AcademicInformation__c.length === 0 ? 'none' : 'block' }}>
              <Grid alignItems="center" container spacing={3} sx={{ m:0, pl: 0, pr: 12, pt: 3, pb: 3 }}>
                {form.values.AcademicInformation__c.map((diploma, index) => (
                  <React.Fragment key={index}>
                    <DiplomaItem
                    index={index}
                    />
                    <Grid item sm={1}>
                      <Button color="error" onClick={(e) => { onRemoveClick(e, index)}} startIcon={<DeleteForeverIcon />} variant="text">Supprimer</Button>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Paper>
            <Button onClick={onAddClick} variant="outlined" sx={{ mt: 2 }}>Ajouter un {form.values.AcademicInformation__c.length > 0 ? 'autre' : ''} diplôme</Button>
          </>
        )
      }}
    </FieldArray>
  )
}

export default Diplomas
