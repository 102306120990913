import { Alert, Button, CircularProgress, Snackbar, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useSelector } from "react-redux"
import SendIcon from '@mui/icons-material/Send';
import Salesforce from "../../services/Salesforce"
import { InputField } from "../FormFields"
// import { InputExample } from "../FormFields/InputExample"
import useStyles from "../Portal/styles"
import validationSchema from "./FormModel/validationSchema"

const ContactCommercial = ({ opportunityId }) => {

  const classes = useStyles()
  const payload = useSelector(state => state.salesforce.token)
  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  async function _handleSubmit(values, actions) {
    console.log(values)
    const message = values.message
    await Salesforce.createTask(payload, opportunityId, message)
    setOpen(true)
    actions.setSubmitting(false)
    actions.setFieldValue('message', '', false)
  }

  return (
    <>
      <Typography gutterBottom variant="h6">Vous souhaitez modifier votre candidature ou poser une question ? Remplissez le formulaire ci-dessous.</Typography>
      <Formik
        initialValues={{ message: '' }}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting }) => (
        <Form id="contactCommercial" style={{ marginTop : '30px' }}>
          <InputField fullWidth label="Votre message" minRows={4} multiline name="message" />
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button color="primary" className={classes.button} disabled={isSubmitting} startIcon={<SendIcon />} type="submit" variant="contained">
                Envoyer mon message
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
        </Form>
        )}
      </Formik>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} onClose={handleClose} open={open}>
        <Alert elevation={6} onClose={handleClose} severity="success" sx={{ width: '100%' }} variant="filled">
          Votre message a bien été envoyé.
        </Alert>
      </Snackbar>
    </>
  )
}

export default ContactCommercial
