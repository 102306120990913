import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import ExpandMoreIcon  from "@mui/icons-material/ExpandMore"
import { useSelector } from "react-redux"
import moment from "moment"

const Course = () => {

  const academicInformation = useSelector(state => state.salesforce.academicInformation)
  const workExperience = useSelector(state => state.salesforce.workExperience)

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1a-content"
          aria-controls={`panel2a-content`}
          // id="panel1a-header"
          id={`panel2a-header`}
        >
          Mon parcours
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h5">Mes derniers diplômes</Typography>
          {academicInformation.length === 0 && <p>Vous n'avez pas renseigné de diplômes</p>}
          {academicInformation.length > 0 && <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom de l'établissement</TableCell>
                  <TableCell>Pays</TableCell>
                  <TableCell>Année d'obtention du diplôme</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {academicInformation.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.EstablishmentName__c}</TableCell>
                    <TableCell>{row.Location__c}</TableCell>
                    <TableCell>{row.YearOfGraduation__c}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}

          <Typography variant="h5" sx={{ mt: 2 }}>Mon expérience professionnelle</Typography>
          {workExperience.length === 0 && <p>Vous n'avez pas renseigné d'expérience profesionnelle</p>}
          {workExperience.length > 0 && <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Employeur</TableCell>
                  <TableCell>Pays</TableCell>
                  <TableCell>Poste</TableCell>
                  <TableCell>Date de début</TableCell>
                  <TableCell>Date de fin</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workExperience.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.Employer__c}</TableCell>
                    <TableCell>{row.Location__c}</TableCell>
                    <TableCell>{row.Position__c}</TableCell>
                    <TableCell>{moment(row.StartDate__c).format('MM/YY')}</TableCell>
                    <TableCell>{moment(row.EndDate__c).format('MM/YY')}</TableCell>
                    <TableCell>{row.Description__c}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Course
