import { Box, Button } from "@mui/material"
import { Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from "react-redux"
import { updateIonisConnect } from "../../Store/salesforceSlice"
import queryString from "query-string"
import { useEffect, useRef, useState } from "react"
import Salesforce from "../../services/Salesforce"
import { Link } from "react-router-dom"

const ConfirmationEmail = () => {

  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const token = useSelector(state => state.salesforce.token)
  const token_validation = queryString.parse(window.location.search).token
  const mountedRef = useRef(true)

  useEffect(() => {

    async function verifyConfirmationToken() {
      await Salesforce.verifyConfirmationToken(token, token_validation)
        .catch(e => {
          if (!mountedRef.current) return null
          setError(e)
        })

      dispatch(updateIonisConnect(false))
    }

    if (token && token_validation) {
      verifyConfirmationToken()
    }

    return () => { mountedRef.current = false }

  }, [token, token_validation]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box mb={2}>
        { error === '' &&
        <Alert severity="success" variant="outlined">
          <AlertTitle>E-mail validé</AlertTitle>
          Votre adresse e-mail a été vérifiée avec succès.
        </Alert>
        }
        { error !== '' &&
        <Alert severity="error" variant="outlined">
          <AlertTitle>E-mail non validé</AlertTitle>
          Le lien de confirmation de votre adresse e-mail est incorrect ou a expiré.
        </Alert>
        }
      </Box>

      <Button component={Link} color="primary" to="/connexion" variant="contained">Cliquez ici pour reprendre la saisie du formulaire</Button>
    </>
  )
}

export default ConfirmationEmail
