import { Paper, CssBaseline, useMediaQuery } from "@mui/material"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Header from "../Header"
// import Footer from "../Footer"
import { useStyles } from "./styles"
import { createTheme } from "@mui/material/styles"
import { blue } from "@mui/material/colors"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"

const MaterialLayout = (props) => {
  const { children } = props
  const classes = useStyles()
  const themeMode = useSelector(state => state.theme.theme)
  const [finalTheme, setFinalTheme] = useState('light')
  const [finalColor, setFinalColor] = useState(900)
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = createTheme(
    {
      status: {
        danger: '#e53e3e'
      },
      palette: { mode: finalTheme, primary: { main: blue[finalColor] }, secondary: blue, neutral: { main: '#5c6ac4' } }
    }
  )

  useEffect(() => {
    if (themeMode === 'system') {
      console.log('isDarkModeEnabled', isDarkModeEnabled)
      if (isDarkModeEnabled) {
        setFinalTheme('dark')
        setFinalColor(700)
      } else {
        setFinalTheme('light')
        setFinalColor(900)
      }
    } else {
      setFinalTheme(themeMode)
      if (themeMode === 'dark') {
        setFinalColor(700)
      } else {
        setFinalColor(900)
      }
    }
  }, [themeMode, isDarkModeEnabled])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={3}>{children}</Paper>
        </div>
        {/* <Footer /> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MaterialLayout
