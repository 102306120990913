import { Typography } from "@mui/material"
import FormModifyPassword from "../components/IonisConnect/Forms/ModifyPassword"
import queryString from "query-string"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { updateIonisConnect } from "../Store/salesforceSlice"

const ModifyPassword = () => {

  const dispatch = useDispatch()
  const token = queryString.parse(window.location.search).token
  // const isLogged = useSelector(state => state.salesforce.isLogged)
  useEffect(() => {
    dispatch(updateIonisConnect(true))
  })

  return (
    <>
      <Typography gutterBottom variant="h2">Modification du mot de passe</Typography>
      <Typography gutterBottom variant="h6">Saisissez les champs ci-dessous pour modifier votre mot de passe</Typography>
      <FormModifyPassword token={token} />
    </>
  )
}

export default ModifyPassword
