import { Grid } from "@mui/material"
import { useSelector } from "react-redux"
import { InputField, SelectField } from "../../FormFields"

const Validation = (props) => {
  const {
    formField: {
      account: {
        Description,

      },
      opportunity: {
        First_information_source__c
      }
    }
  } = props

  const optionsFirstInformationSource = useSelector(state => state.salesforce.firstInformationSource)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <InputField fullWidth label={Description.label} multiline name={Description.name} minRows={3} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField data={optionsFirstInformationSource} fullWidth label={First_information_source__c.label} name={First_information_source__c.name} />
        </Grid>
      </Grid>
    </>
  )
}

export default Validation
