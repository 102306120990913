import * as Yup from "yup"
// import moment from "moment"
import portalFormModel from "./portalFormModel"
const currentYear = new Date().getFullYear()
let supportingDocumentList = ["Pièce d'identité", "Bulletins de notes année N", "Bulletins de notes année N-1", "Diplômes obtenus"];

const {
  formField: {
    account: {
      AcademicLevel__pc,
      BACYear__pc,
      CountryOfBirth__pc,
      FirstName,
      LastName,
      Nationalite__c,
      Native_Language__pc,
      Langue_1__c,
      Langue_2__c,
      Langue_3__c,
      Niveau_langue_1__c,
      Niveau_langue_2__c,
      Niveau_Langue_3__c,
      PersonBirthdate,
      PersonMobilePhone,
      Phone,
      // Salutation,
      Section__pc,
      SectionOfBAC__pc,
      Reports_Cards__c,
      Copy_of_Diploma__c,
      ID_Proof__c,
      Grant_Proof__c,
      ShippingCity,
      ShippingCountry,
      ShippingPostalCode,
      ShippingStreet
    },
    opportunity: {
      Session__c
    },
    SpecialisedEducation,
  }
} = portalFormModel

const validationSchema = [

  //step 1
  Yup.object().shape({
    account: Yup.object().shape({
      AcademicLevel__pc: Yup.string().required(`${AcademicLevel__pc.requiredErrorMsg}`),
      // BACYear__pc: Yup.number().required(`${BACYear__pc.requiredErrorMsg}`),
      BACYear__pc: Yup.number().when(
        'AcademicLevel__pc', {
          is: 'Terminale (BAC)',
          then: Yup.number(),
          otherwise: Yup.number().min(1970, BACYear__pc.minMaxErrorMsg).max(currentYear, BACYear__pc.minMaxErrorMsg).required(`${BACYear__pc.requiredErrorMsg}`)
        }
      ),
      CountryOfBirth__pc: Yup.string().required(`${CountryOfBirth__pc.requiredErrorMsg}`),
      FirstName: Yup.string().trim().max(40, FirstName.maxLengthErrorMsg).required(`${FirstName.requiredErrorMsg}`),
      LastName: Yup.string().trim().max(80, LastName.maxLengthErrorMsg).required(`${LastName.requiredErrorMsg}`),
      Nationalite__c: Yup.string().required(`${Nationalite__c.requiredErrorMsg}`),
      Native_Language__pc: Yup.string().required(`${Native_Language__pc.requiredErrorMsg}`),
      PersonBirthdate: Yup.date().required(`${PersonBirthdate.requiredErrorMsg}`),
      // PersonMailingCountry: Yup.string().required(`${PersonMailingCountry.requiredErrorMsg}`),
      Phone: Yup.string().trim().max(40, Phone.maxLengthErrorMsg).when('PersonMobilePhone', {
        is: (phone) => !phone || phone.length === 0,
        then: Yup.string().required(`${Phone.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      PersonMobilePhone: Yup.string().trim().max(40, PersonMobilePhone.maxLengthErrorMsg).when('Phone', {
        is: (phone) => !phone || phone.length === 0,
        then: Yup.string().required(`${PersonMobilePhone.requiredErrorMsg}`),
        otherwise: Yup.string()
      }),
      Section__pc: Yup.string().required(`${Section__pc.requiredErrorMsg}`),
      // SectionOfBAC__pc: Yup.string().required(`${SectionOfBAC__pc.requiredErrorMsg}`)
      SectionOfBAC__pc: Yup.string().when(
        'AcademicLevel__pc', {
          is: 'Terminale (BAC)',
          then: Yup.string(),
          otherwise: Yup.string().required(`${SectionOfBAC__pc.requiredErrorMsg}`)
        }
      ),
      ShippingCity: Yup.string().trim().max(40, ShippingCity.maxLengthErrorMsg).required(`${ShippingCity.requiredErrorMsg}`),
      ShippingCountry: Yup.string().trim().required(`${ShippingCountry.requiredErrorMsg}`),
      ShippingPostalCode: Yup.string().trim().max(20, ShippingPostalCode.maxLengthErrorMsg).required(`${ShippingPostalCode.requiredErrorMsg}`),
      ShippingStreet: Yup.string().trim().max(80, ShippingStreet.maxLengthErrorMsg).required(`${ShippingStreet.requiredErrorMsg}`)
    }, [['Phone', 'PersonMobilePhone']]),
    SpecialisedEducation: Yup.array().when(
      'account.Section__pc', {
        is: 'Générale',
        then: Yup.array().min(2, SpecialisedEducation.minErrorMsg).max(2, SpecialisedEducation.maxErrorMsg)
      }
    )
  }),
  //step 2
  Yup.object().shape({
    opportunity: Yup.object().shape({
      Session__c: Yup.string().required(`${Session__c.requiredErrorMsg}`)
    })
  }),
  //step 3
  null,
  //step 4
  Yup.object().shape({
    AcademicInformation__c: Yup.array()
      .of(
        Yup.object().shape({
          EstablishmentName__c: Yup.string().trim().required('Le nom de l\'établissement est requis'),
          Location__c: Yup.string().trim().required('Le pays est requis'),
          YearOfGraduation__c: Yup.number().min(1970, BACYear__pc.minMaxErrorMsg).max(currentYear, BACYear__pc.minMaxErrorMsg).required('L\'année est requise')
        })
      ),
    account: Yup.object().shape({
      Langue_1__c: Yup.string().required(`${Langue_1__c.requiredErrorMsg}`)
        .notOneOf([Yup.ref('Langue_2__c')], Langue_2__c.notSameValue)
        .notOneOf([Yup.ref('Langue_3__c')], Langue_3__c.notSameValue),
      Niveau_langue_1__c: Yup.string().required(`${Niveau_langue_1__c.requiredErrorMsg}`),
      Langue_2__c: Yup.string().when(
        'Niveau_langue_2__c', {
          is: (niveaulangue2) => niveaulangue2 !== undefined && niveaulangue2.length > 0,
          then: Yup.string().required(`${Langue_2__c.requiredErrorMsg}`)
            .notOneOf([Yup.ref('Langue_1__c')], Langue_1__c.notSameValue)
            .notOneOf([Yup.ref('Langue_3__c')], Langue_3__c.notSameValue),
          otherwise: Yup.string()
        }
      ),
      Niveau_langue_2__c: Yup.string().when(
        'Langue_2__c', {
          is: (langue2) => langue2 !== undefined && langue2.length > 0,
          then: Yup.string().required(`${Niveau_langue_2__c.requiredErrorMsg}`),
          otherwise: Yup.string()
        }
      ),
      Langue_3__c: Yup.string().when(
        'Niveau_Langue_3__c', {
          is: (niveaulangue3) => niveaulangue3 !== undefined && niveaulangue3.length > 0,
          then: Yup.string().required(`${Langue_3__c.requiredErrorMsg}`)
            .notOneOf([Yup.ref('Langue_1__c')], Langue_1__c.notSameValue)
            .notOneOf([Yup.ref('Langue_2__c')], Langue_2__c.notSameValue),
          otherwise: Yup.string()
        }
      ),
      Niveau_Langue_3__c: Yup.string().when(
        'Langue_3__c', {
          is: (langue3) => langue3 !== undefined && langue3.length > 0,
          then: Yup.string().required(`${Niveau_Langue_3__c.requiredErrorMsg}`),
          otherwise: Yup.string()
        }
      ),
    }, [['Niveau_langue_2__c', 'Langue_2__c'], ['Langue_3__c', 'Niveau_Langue_3__c']]),
    WorkExperience__c: Yup.array()
      .of(
        Yup.object().shape({
          Description__c: Yup.string().trim().required('La description brève du poste est requise'),
          // Employer__c: Yup.string().trim().required('L\'employeur est requis'),
          EndDate__c: Yup.date().default(() => new Date()).typeError('Le format de date est incorrect'),
            // .when("StartDate__c", (startDate, yup) => startDate && yup.min(startDate, "La date de fin ne peut pas être inférieure à la date de début")),
          Location__c: Yup.string().required('Le pays est requis'),
          // Position__c: Yup.string().trim().required('Le poste est requis'),
          StartDate__c: Yup.date().default(() => new Date()).typeError('Le format de date est incorrect')
        })
      )
  }),
  //step 5
  Yup.object().shape({
    account: Yup.object().shape({
      Grant_Proof__c: Yup.boolean().when('Grant_Student__c', {
        is: true,
        then: Yup.boolean().required(Grant_Proof__c.requiredErrorMsg).oneOf([true], Grant_Proof__c.requiredErrorMsg),
        otherwise: Yup.boolean()
      })
    }),
    SupportingDocument__c : Yup.array()
      .of(
        Yup.object().shape({
          supportingDocument : Yup.string().required().test('check-mandatory-supporting-document', "Vous n'avez pas ajouté toutes les pièces jointes obligatoires", async (supportingDocument) => {
            console.log('String : check-mandatory-supporting-document');
            console.log(supportingDocument);
            if( supportingDocument === "Pièce d'identité" && !supportingDocumentList.includes(supportingDocument)) {
              supportingDocumentList.push(supportingDocument)
            }
            if( supportingDocument === "Bulletins de notes année N" && !supportingDocumentList.includes(supportingDocument)) {
              supportingDocumentList.push(supportingDocument)
            }
            if( supportingDocument === "Bulletins de notes année N-1" && !supportingDocumentList.includes(supportingDocument)) {
              supportingDocumentList.push(supportingDocument)
            }
            if( supportingDocument === "Diplômes obtenus" && !supportingDocumentList.includes(supportingDocument)) {
              supportingDocumentList.push(supportingDocument)
            }
            if( supportingDocumentList.includes(supportingDocument) ) {
              let index = supportingDocumentList.indexOf(supportingDocument);
              supportingDocumentList.splice(index, 1)
            }
            console.log(supportingDocumentList);
            console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
            console.log(supportingDocumentList.length)
            return true
          }),
          files : Yup.array()
            .required().test({name : 'check-files-type', message : "Nous acceptons uniquement les fichiers de type images et pdf", test: (files) => {
                let valid;
                if (files) {
                  valid = files.map(file => {
                    if(file.Id !== null ) {
                      return true
                    }
                    if ( ( (file.type === "" || file.type === null ) || !['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpg', 'image/jpeg', 'image/webp'].includes(file.type) ) ) {
                      console.log('get type error')
                      return false
                    }
                    else {
                      return true
                    }
                  })
                }
                if ( valid.includes(false) ) {
                  return false
                }
                else {
                  return true
                }
              }})
            .test('check-files-weight', "Nous acceptons uniquement des fichiers de poids inférieurs à 5 Mo", async (files) => {
              let valid;
              if (files) {
                valid = files.map(file => {
                  const size = file.size / 1024 / 1024
                  if (size > 5) {
                    return false
                  }
                  else {
                    return true
                  }
                })
              }
              if ( valid.includes(false) ) {
                return false
              }
              else {
                return true
              }
            })
            .min(1, 'Un fichier minimum est nécessaire')
            .max(5, 'Pas plus de 5 fichiers sont permis'),
        })
      )
      .required('Toutes les pièces jointe doivent être ajoutées')
      .test('check-mandatory-supporting-document', "Vous n'avez pas ajouté toutes les pièces jointes obligatoires", async (supportingDocument) => {
        console.log('check-mandatory-supporting-document', supportingDocument);
        const arrayClone = []
        console.log('*********************************************')
        console.log(supportingDocumentList)
        console.log(supportingDocumentList.length)
        console.log(supportingDocument)
        if (supportingDocument.find(x => x.supportingDocument === "Pièce d'identité") !== undefined && !arrayClone.includes("Pièce d'identité")) {
          console.log('a')
          arrayClone.push("Pièce d'identité")
        }
        if (supportingDocument.find(x => x.supportingDocument === "Bulletins de notes année N") !== undefined && !arrayClone.includes("Bulletins de notes année N")) {
          console.log('b')
          arrayClone.push("Bulletins de notes année N")
        }
        if (supportingDocument.find(x => x.supportingDocument === "Bulletins de notes année N-1") !== undefined && !arrayClone.includes("Bulletins de notes année N-1")) {
          console.log('c')
          arrayClone.push("Bulletins de notes année N-1")
        }
        if (supportingDocument.find(x => x.supportingDocument === "Diplômes obtenus") !== undefined && !arrayClone.includes("Diplômes obtenus")) {
          console.log('d')
          arrayClone.push("Diplômes obtenus")
        }
        console.log(arrayClone);
        console.log('€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€€')
        console.log(arrayClone.length)
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        if(arrayClone.length === 4 ) {
          return true;
        }
        else {
          return false
        }
      })
      .min(4, 'Les 4 différents types de pièces jointe doivent être présent'),
  }),
]

export default validationSchema
