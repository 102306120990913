import { Alert, Button, CircularProgress, Snackbar } from "@mui/material"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useSelector } from "react-redux"
import Salesforce from "../../../services/Salesforce"
import { InputField } from "../../FormFields"
import useStyles from "../../Portal/styles"
import { Link } from "react-router-dom"

const ForgotPassword = () => {

  const classes = useStyles()
  const payload = useSelector(state => state.salesforce.token)
  const division = useSelector(state => state.salesforce.division)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  async function _handleSubmit(values, actions) {
    // console.log(actions, values)
    const email = values.email
    const resetPassword = await Salesforce.resetPassword(payload, email, division)
    console.log('reseeeeeeeeet', resetPassword)
    if (resetPassword.success) {
      setSeverity('success')
      setMessage('Un email a été envoyé à ' + email + ' afin de définir un nouveau mot de passe.')
    } else {
      setSeverity('error')
      setMessage('Nous avons trouvé aucun compte correspondant à votre adresse email.')
    }
    setOpen(true)
    actions.setSubmitting(false)
  }

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting }) => (
        <Form id="forgot-form">
          <InputField fullWidth label="Mon adresse email" name="email" type="email" required />
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button className={classes.button} component={Link} to="/connexion">Se connecter</Button>
              <Button color="primary" className={classes.button} disabled={isSubmitting} type="submit" variant="contained">
                Réinitialiser mon mot de passe
              </Button>
              {isSubmitting && (
                <CircularProgress className={classes.buttonProgress} size={24} />
              )}
            </div>
          </div>
        </Form>
        )}
      </Formik>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} onClose={handleClose} open={open}>
        <Alert elevation={6} onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ForgotPassword
