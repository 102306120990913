import { Link, Typography } from "@mui/material"
import { useHistory } from "react-router"

const ConfirmationCandidature = () => {

  const history = useHistory()

  const goToForm = () => {
    history.push('/')
  }

  return (
    <>
      <Typography gutterBottom variant="h3">Candidature en ligne</Typography>

      <Typography color="green" paragraph variant="body1"><strong>Votre candidature en ligne a bien été enregistrée.</strong></Typography>

      <Typography paragraph variant="body2">Vous pouvez compléter votre dossier à tout moment en retournant sur le <Link component="button" onClick={goToForm}>formulaire de candidature</Link> et en vous connectant avec votre email et votre mot de passe.<br /><br />

      Pour tout renseignement complémentaire, n'hésitez pas à <Link href="https://www.isg.fr/contacts-ecole" rel="noopener" target="_blank">contacter</Link> notre Service des Admissions.</Typography>
    </>
  )
}

export default ConfirmationCandidature
