import { useState, useEffect } from "react"
import { useField } from "formik"
import { Grid, TextField } from "@mui/material"
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DatePicker from "@mui/lab/DatePicker"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import { useSelector } from "react-redux"
import moment from "moment"
// import DateFnsUtils from "@date-io/date-fns/"

const localeMap = {
  en: enLocale,
  fr: frLocale
}

const DatePickerField = (props) => {
  const {datemax, ...rest} = props
  const [field, meta, helper] = useField(props)
  const { touched, error } = meta
  const { setValue } = helper
  const isError = touched && error && true
  const { value } = field
  const [selectedDate, setSelectedDate] = useState(null)
  // const [locale, setLocale] = useState('fr')
  const locale = useSelector(state => state.salesforce.language)

  useEffect(() => {
    if (value) {
      const date = new Date(value)
      setSelectedDate(date)
    }
  }, [value])

  function _onChange(date) {
    if (date) {
      setSelectedDate(date)
      try {
        // const ISODateString = date.toISOString()
        // setValue(ISODateString)
        const convertDate = moment(date).format('YYYY-MM-DD')
        setValue(convertDate)
      } catch (error) {
        setValue(date)
      }
    } else {
      setValue(date)
    }
  }

  // console.log(isError, error)
  // console.log(field, meta, helper)

  // console.log('biidfigjgd', field)
  // console.log('jgfnnjsfd', props)
  // console.log('value', value)

  return (
    <Grid container>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
        {/* <DatePicker
          {...field}
          {...props}
          error={isError}
          helperText={isError && error}
          invalidDateMessage={isError && error}
          onChange={_onChange}
          renderInput={props => <TextField {...field} />}
          value={selectedDate}
        /> */}
        <DatePicker
        // label="Basic example"
          // {...props}
          // {...field}
          {...rest}
          // helperText={isError && error}
          // inputFormat="dd/MM/yyyy"
          // invalidDateMessage={isError && error}
          cancelText="Annuler"
          maxDate={datemax}
          onChange={_onChange}
          onError={(value, test, test2) => console.log('onerror', value, test, test2)}
          renderInput={(params) => <TextField {...props} {...params} error={isError} helperText={isError ? error : ''} value="" />}
          value={selectedDate}
        />
      </LocalizationProvider>
    </Grid>
  )
}

export default DatePickerField
