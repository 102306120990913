import * as Yup from "yup"
import ionisConnectFormModel from "./ionisConnectFormModel"

const {
  formField: {
    academicLevel,
    cgu,
    email,
    firstName,
    lastName,
    nationality,
    password,
    passwordConfirm
  }
} = ionisConnectFormModel

const validationSchema =
  Yup.object().shape({
    [academicLevel.name]: Yup.string().required(`${academicLevel.requiredErrorMsg}`),
    [cgu.name]: Yup.bool().oneOf([true], cgu.requiredErrorMsg),
    // [cgu.name]: Yup.boolean().required(`${cgu.requiredErrorMsg}`),
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
    [firstName.name]: Yup.string().trim().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().trim().required(`${lastName.requiredErrorMsg}`),
    [nationality.name]: Yup.string().required(`${nationality.requiredErrorMsg}`),
    [password.name]: Yup.string().trim().min(8, password.invalidErrorMsg).required(`${password.requiredErrorMsg}`),
    [passwordConfirm.name]: Yup.string().trim().min(8, passwordConfirm.invalidErrorMsg).required(`${passwordConfirm.requiredErrorMsg}`).oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas.'),
  })

export default validationSchema
