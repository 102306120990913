import IconMoreVert from "@mui/icons-material/MoreVert"
import { Button, Drawer, FormControl, FormControlLabel, FormLabel, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Radio, RadioGroup, Typography, useMediaQuery } from "@mui/material"
import IconPassword from "@mui/icons-material/Password"
import IconLightMode from "@mui/icons-material/LightMode"
import IconNightLight from "@mui/icons-material/Nightlight"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { modifyTheme } from "../../Store/themeSlice"

const Parameters = () => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const dispatch = useDispatch()
  const [defaultTheme, setDefaultTheme] = useState('')
  const theme = useSelector(state => state.theme)
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')

  useEffect(() => {
    setDefaultTheme(theme.theme)
  }, [])


  return (
    <>
      <IconButton onClick={handleOpen}>
        <IconMoreVert />
      </IconButton>
      <Box
        onKeyDown={handleClose}
      >
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
        >
          <Box sx={{ padding: 3 }}>
            <Typography variant="h5">Mes paramètres</Typography>
            <Button component={Link} onClick={handleClose} to="/modification-mot-passe" sx={{ margin: '30px 0' }} variant="contained">Modifier mon mot de passe</Button>
            <Box>
              <FormControl component="fieldset">
                <FormLabel component="legend">Thème</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  defaultValue={defaultTheme}
                  name="radio-buttons-group"
                  onClick={e => {
                    dispatch(modifyTheme(e.target.value))
                    setDefaultTheme(e.target.value)
                  }}
                >
                  <FormControlLabel value="system" control={<Radio />} label="Thème du système" />
                  <FormControlLabel value="light" control={<Radio />} label="Thème clair" />
                  <FormControlLabel value="dark" control={<Radio />} label="Thème sombre" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  )
}

export default Parameters
