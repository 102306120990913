import { Divider, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { NewApplication } from "../Buttons"
import Opportunities from "./Opportunities"
// import Summary from "./Summary"
import Summary from "../Summary"
import { useSelector } from "react-redux"
import JPC from "../Buttons/JPC"

const ApplicationPortal = () => {

  const firstName = useSelector(state => state.salesforce.account.FirstName)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={5}>
          <Typography variant="h6" sx={{ mb: 2 }}>Bonjour {firstName}</Typography>

          <Summary />
          <br />
          <JPC />
        </Grid>
        <Grid item sm={7}>
          <Typography variant="h6" sx={{ mb: 2 }}>Mes candidatures</Typography>
          {/* <Box sx={{ width: '100%', bgcolor: 'Background.paper'}}>
            <Box sx={{ my: 3, mx: 2}}>
              <Badge badgeContent="Terminé" color="success">
                ISG Programme Business & Management
              </Badge>
            </Box>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ width: '100%', bgcolor: 'Background.paper'}}>
            <Box sx={{ my: 3, mx: 2}}>
              <Badge badgeContent="En cours" color="warning">
                ISG Programme Grande Ecole
              </Badge>
            </Box>
          </Box> */}
          <Opportunities />
          <Divider variant="middle" />
          <Box sx={{ my: 3 }}>
            {/* <Button variant="contained">Ajouter une nouvelle candidature</Button> */}
            <NewApplication />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ApplicationPortal
