import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Snackbar } from "@mui/material"
import { Login, Registration } from "./components/IonisConnect"
import MaterialLayout from "./components/Layout/MaterialLayout"
import Loading from "./components/Loading/Loading"
import { Salesforcelogin, SalesforceGetData, isLogged } from "./Store/salesforceSlice"
import ConfirmationEmail from "./components/ConfirmationEmail"
import Portal from "./components/Portal"
import { IdleTimeoutManager } from "idle-timer-manager"
import ApplicationPortal from "./components/ApplicationPortal"
import './services/i18n'
import IsOnline from "./components/IsOnline"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import ForgotPassword from "./pages/forgot-password"
import ModifyPassword from "./pages/modify-password"
import { hide, show } from "./Store/buttonsSlice"
import ConfirmationCandidature from "./pages/confirmation-candidature"
import ReglementFraisJPC from "./pages/reglement-frais-jpc"
import persistStore from "redux-persist/es/persistStore"
import store from './Store/Store'

function App() {

  const dispatch = useDispatch()
  const token = useSelector(state => state.salesforce.token)
  const loading = useSelector(state => state.salesforce.status)
  const userLogged = useSelector(state => state.salesforce.isLogged)
  const hasOneOppyCompleted = useSelector(state => state.salesforce.hasOneOppyCompleted)
  const currentStage = useSelector(state => state.salesforce.account.StageStop__c)
  let persistor = persistStore(store)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (token === null) {
      dispatch(Salesforcelogin())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (token === undefined) { // @todo faire en sorte de ne pas charger les données au reload
    if(token !== null && loading !== 'success') {
      dispatch(SalesforceGetData(token))
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('currentStage', currentStage)
    if (currentStage === 2 || currentStage === '2' || currentStage === 4  || currentStage === '4') {
      dispatch(hide())
    } else {
      dispatch(show())
    }
  }, [currentStage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpen(false)
    if (userLogged) {
      console.log('coucou tu vas etre inactif !!!!')
      const manager = new IdleTimeoutManager({
        timeout: 30 * 3600,
        onExpired: (time) => {
          console.log('expiré', time)
          persistor.purge().then(() => {
            console.log('purgé')
            dispatch(isLogged(false))
            // document.location.href = '/'
            setOpen(true)
          })
        }
      })

      return () => {
        manager.clear()
      }
    }
  }, [userLogged]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} open={open}>
        <Alert elevation={6} severity="info" sx={{ width: '100%' }} variant="filled">
          Vous avez été déconnecté après une inactivité de 30 minutes.
        </Alert>
      </Snackbar>

      <IsOnline />
      <Router >
        <MaterialLayout>
          { loading === 'loading' && <Loading /> }
          { loading === 'failed' && <Alert severity="error">Une erreur est survenue lors du chargement du formulaire. Veuillez rafraichir la page ou revenir dans quelques instants.</Alert> }
          <Switch>
            <Route exact path="/">
              { loading === 'success' && !userLogged && <Login /> }
              { loading === 'success' && userLogged && !hasOneOppyCompleted && <Portal /> }
              { loading === 'success' && userLogged && hasOneOppyCompleted && <ApplicationPortal /> }
            </Route>
            <Route exact path="/inscription">
              { loading === 'success' && !userLogged && <Registration /> }
            </Route>
            <Route exact path="/connexion">
              { loading === 'success' && !userLogged && <Login /> }
            </Route>
            <Route exact path="/confirmation-email">
            { loading === 'success' && <ConfirmationEmail /> }
            </Route>
            <Route exact path="/mot-passe-oublie">
              { loading === 'success' && <ForgotPassword /> }
            </Route>
            <Route exact path="/modification-mot-passe">
              { loading === 'success' && <ModifyPassword /> }
            </Route>
            <Route exact path="/confirmation-candidature">
              { loading === 'success' && <ConfirmationCandidature /> }
            </Route>
            <Route exact path="/reglement-frais-jpc">
              { loading === 'success' && userLogged && <ReglementFraisJPC /> }
            </Route>
          </Switch>
        </MaterialLayout>
      </Router>
    </div>
  );
}

export default App;
