import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Grid } from "@mui/material"
import ExpandMoreIcon  from "@mui/icons-material/ExpandMore"
import { useSelector } from "react-redux"
import ContactCommercial from "./ContactCommercial"
import LogicYear from "../../services/LogicYear"
import moment from "moment"
import NewSupportingDocument from "../Buttons/NewSupportingDocument"

const Opportunities = () => {

  const opportunities = useSelector(state => state.salesforce.opportunities)

  const getColor = (value) => {
    let color = 'default'
    switch(value) {
      case 'Qualification':
        color = 'primary'
        break
      case 'En cours':
        color = 'info'
        break
      case 'Candidature Complète':
        color = 'success'
        break
      case 'En étude':
        color = 'warning'
        break
      case 'En cours d\'inscription':
        color = 'warning'
        break
      default:
        color = 'default'
    }

    return color
  }

  const renderContentOpportunity = (opportunity) => {
    const data = LogicYear.find(el => el.division === opportunity.Division__c)
    const year = data.years.find(el => el.year.value === opportunity.ProgramYear__c)
    const rentree = moment(opportunity.SessionStartDate__c).format('MMMM yy')
    return (
      // <div>
        <ul style={{ marginLeft: '20px', marginBottom: '10px' }}>
          <li>Programme : {data.name}</li>
          <li>Année d'entrée souhaité : {year.year.label}</li>
          <li>Campus : {opportunity.SessionCampus__c}</li>
          <li>Rentrée : {rentree}</li>
        </ul>
      // </div>
    )
  }

  return (
    <>
      {opportunities.map((opportunity, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            // aria-controls="panel1a-content"
            aria-controls={`panel${index}a-content`}
            // id="panel1a-header"
            id={`panel${index}a-header`}
          >
            {/* <Typography> */}
              <Grid container>
                <Grid item sm={9} xs={12}>
                  {opportunity.ProgramFullName__c} { opportunity.SessionCampus__c}
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Chip color={getColor(opportunity.StageName)} label={opportunity.StageName} variant="filled"></Chip>
                </Grid>
              </Grid>
              {/* <Badge badgeContent={opportunity.StageName} color="success">
                {opportunity.ProgramFullName__c} { opportunity.SessionCampus__c}
              </Badge> */}
            {/* </Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            {renderContentOpportunity(opportunity)}
            {opportunity.Probability < 50 && <Divider sx={{ mt: 2, mb: 2 }} />}
            {opportunity.Probability < 50 && <NewSupportingDocument opportunity={opportunity} />}
            {opportunity.Probability < 50 && <Divider sx={{ mt: 2, mb: 2 }} />}
            <ContactCommercial opportunityId={opportunity.Id} />
          </AccordionDetails>
        </Accordion>
      ))}
      {/* <Box sx={{ width: '100%', bgcolor: 'Background.paper'}}>
        <Box sx={{ my: 3, mx: 2}}>
          <Badge badgeContent="Terminé" color="success">
            ISG Programme Business & Management
          </Badge>
        </Box>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ width: '100%', bgcolor: 'Background.paper'}}>
        <Box sx={{ my: 3, mx: 2}}>
          <Badge badgeContent="En cours" color="warning">
            ISG Programme Grande Ecole
          </Badge>
        </Box>
      </Box> */}
    </>
  )
}

export default Opportunities
