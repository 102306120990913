export const STAGE_QUALIFICATION = 'Qualification'
export const STAGE_COURS = 'En cours'
export const STAGE_COMPLETE = 'Candidature Complète'
export const STAGE_ETUDE = 'En étude'
export const STAGE_ADMIS = 'Admis'
export const STAGE_INSCRIPTION = 'En cours d\'inscription'
export const STAGE_INSCRIT = 'Inscrit'
export const STAGE_QUALIFICATION_POURCENT = 10
export const STAGE_COURS_POURCENT = 30
export const STAGE_COMPLETE_POURCENT = 50
export const STAGE_ETUDE_POURCENT = 60
export const STAGE_ADMIS_POURCENT = 70
export const STAGE_INSCRIPTION_POURCENT = 80
export const STAGE_INSCRIT_POURCENT = 100
