import { Backdrop, Box, Button, CircularProgress, MobileStepper, Snackbar, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { Alert, AlertTitle } from '@mui/material';
import { Formik, Form } from "formik"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Salesforce from "../../services/Salesforce"
// import formInitialValues from "../Portal/FormModel/formInitialValues"
import validationSchema from "../Portal/FormModel/validationSchema"
import portalFormModel from "./FormModel/portalFormModel"
import Application from "./Forms/Application"
import PersonnalInformations from "./Forms/PersonnalInformations"
import { ButtonBack, ButtonNext } from "../Buttons/StepMobile"
import { isLogged, removeOpportunities, SalesforceUpdateStageStop, updateAcademicInformation, updateAccount, updateCampaignMember, updateOpportunities, updateOpportunity, updateSupportingDocument, updateWorkExperience } from "../../Store/salesforceSlice"
import useStyles from "./styles"
import Payment from "./Forms/Payment"
import Course from "./Forms/Course"
import { STAGE_COMPLETE, STAGE_QUALIFICATION } from "../../services/StageNameOpportunity"
import Validation from "./Forms/Validation"
import ConfirmationApplication from "../ConfirmationApplication"
import { persistStore } from "redux-persist"
import Store from "../../Store/Store"
import SupportingDocument from "./Forms/SupportingDocument"
import { useHistory } from "react-router"

const { formId, formField } = portalFormModel

function _renderStepContent(step) {
  switch(step) {
    case 0:
      return <PersonnalInformations formField={formField} />
    case 1:
      return <Application formField={formField} />
    case 2:
      return <Payment formField={formField} />
    case 3:
      return <Course formField={formField} />
    case 4:
      return <SupportingDocument formField={formField} />
    case 5:
      return <Validation formField={formField} />
    default:
      return <div>Not found</div>
  }
}

const Portal = () => {

  const paymentType = useSelector(state => state.salesforce.paymentType)
  const steps = ['Mes informations personnelles', 'Ma candidature', paymentType === 'Candidature' ? 'Paiement des frais de candidature' : 'Paiement de la JPC', 'Mon parcours', 'Justificatifs', 'Validation']

  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [criticalErrors, setCriticalErrors] = useState(false)
  const [opportunityValidationFailed, setOpportunityValidationFailed] = useState(false)
  const [isSubmittingBack, setIsSubmittingBack] = useState(false)
  const currentValidationSchema = validationSchema[activeStep]
  const [opportunity, setOpportunity] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const isLastStep = activeStep === steps.length - 1
  const payload = useSelector(state => state.salesforce.token)
  const step = useSelector(state => state.salesforce.account.StageStop__c)
  const accountId = useSelector(state => state.salesforce.account.Id)
  const buttonsDisabled = useSelector(state => state.buttons.enabled)
  const buttonsVisible = useSelector(state => state.buttons.visible)
  const statusPayment = useSelector(state => state.salesforce.statusPayment)
  const SupportingDocument__c = useSelector(state => state.salesforce.supportingDocument)
  const account = useSelector(state => state.salesforce.account)
  const opportunities = useSelector(state => state.salesforce.opportunities)
  const uniqueOpportunity = useSelector(state => state.salesforce.opportunity)
  const AcademicInformation__c = useSelector(state => state.salesforce.academicInformation)
  const WorkExperience__c = useSelector(state => state.salesforce.workExperience)
  const returnPageConfirmation = useSelector(state => state.salesforce.returnPages.confirmation)
  const recordTypeId = useSelector(state => state.salesforce.recordTypeOpportunity)
  const campaignId = useSelector(state => state.salesforce.campaignId)
  const campaignMember = useSelector(state => state.salesforce.campaignMember)
  let persistor = persistStore(Store)
  const formRef = useRef()

  const handleStep = (newStep) => () => {
    console.log(formRef.current)
    if (activeStep > newStep) {
      console.log('step', newStep)
      // setActiveStep(step)
      dispatch(SalesforceUpdateStageStop({ payload, accountId, newStep }))
    } else {
      // if (formRef.current) {
      //   formRef.current.submitForm()
      // }
      // console.log(steo, activeStep)
      const stepDiff = 0
      console.log('stepDiff', stepDiff)
      // @todo il faudrait au final un json pour stocker le step max qu'il peut atteindre. Sinon il pourrait sauter des étapes
      if (stepDiff === 1 && formRef.current) {
        formRef.current.submitForm()
      } else {
        dispatch(SalesforceUpdateStageStop({ payload, accountId, newStep }))
      }
    }
  }

  useEffect(() => {
    const opportunity = {
      AccountId: accountId,
      CloseDate: Date.now(),
      OnlineApplication__c: true,
      OwnerId: '',
      RecordTypeId: recordTypeId,
      Session__c: '',
      StageName: STAGE_QUALIFICATION,
    }
    console.log('uniqueOpportunity', uniqueOpportunity)
    if (uniqueOpportunity && Object.keys(uniqueOpportunity).length === 0 && Object.getPrototypeOf(uniqueOpportunity) === Object.prototype) {
      setOpportunity(opportunity)
    } else {
      setOpportunity(uniqueOpportunity)
    }
  }, [accountId, uniqueOpportunity, recordTypeId])

  useEffect(() => {
    const currentStep = parseInt(step)
    setActiveStep(currentStep)
  }, [step])

  async function _submitForm(values, actions) {
    actions.setSubmitting(false)
    // setActiveStep(activeStep + 1)
    setOpenPopup(true)
  }

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async function sleep() {
      await timeout(20000); //wait 20secs
      console.log("** on va attendre un peu ici ! **");
      return true;
  }

  async function _handleSubmit(values, actions) {
    // Pour le paiement, on ne doit pas valider via le bouton traditionnel
    console.log("** handle submit **");
    setCriticalErrors(false)
    console.log(values)
    const account = values.account
    const opportunity = values.opportunity
    const JPCWished = values.JPCWished
    const academicInformation = values.AcademicInformation__c
    const workExperience = values.WorkExperience__c
    const supportingDocument = values.SupportingDocument__c
    console.log("academicInformation");
    console.log(academicInformation);
    console.log(supportingDocument);
    if ( activeStep === 4 ) {
      await sleep();
    }
    // setActiveStep(activeStep + 1)
    // actions.setTouched({})
    // actions.setSubmitting(false)
    const accountUpdated = await Salesforce.updateAccount(payload, account, activeStep, JPCWished, paymentType, isLastStep).catch(e => setCriticalErrors(true))
    console.log('MAJ Oppoy', opportunity)
    let opportunityUpdated = undefined
    if (opportunity.Session__c !== undefined && opportunity.Session__c !== '') {
      console.log('yyyyyyyyyyyysfhjfhshfjksdhgshdshsghs')
      opportunityUpdated = await Salesforce.updateOpportunity(payload, opportunity, JPCWished, paymentType).catch(e => setCriticalErrors(true))
      console.log(opportunityUpdated)
      dispatch(updateOpportunity(opportunityUpdated.opportunity))
      dispatch(updateOpportunities(opportunityUpdated.opportunities))
    }
    const academicInformationUpdated = await Salesforce.updateAcademicInformation(payload, academicInformation, accountId).catch(e => setCriticalErrors(true))
    const workExperienceUpdated = await Salesforce.updateWorkExperience(payload, workExperience, accountId).catch(e => setCriticalErrors(true))
    console.log(" ** academicInformationUpdated ** ")
    console.log(academicInformationUpdated)
    // const supportingDocumentUpdated = await Salesforce.getAttachmentFromAccount(payload, account).catch(e => setCriticalErrors(true))
    console.log(" ** supportingDocumentUpdated ** ");
    // MAJ du store
    if (academicInformationUpdated.success) {
      dispatch(updateAcademicInformation(academicInformationUpdated.academicInformation))
    }
    if (workExperienceUpdated.success) {
      dispatch(updateWorkExperience(workExperienceUpdated.workExperience))
    }
    // if (supportingDocumentUpdated.success) {
    //   console.log(supportingDocumentUpdated.supportingDocument);
    //   dispatch(updateSupportingDocument(supportingDocumentUpdated.supportingDocument))
    // }
    if (JPCWished) {
      const campaignMemberCreated = await Salesforce.createCampaignMember(payload, account.PersonContactId, campaignId)
      if (campaignMemberCreated.success) {
        dispatch(updateCampaignMember(campaignMemberCreated.campaignMember))
      }
    }
    console.log('yo', accountUpdated)
    console.log('oppy updated', opportunityUpdated)
    if (accountUpdated !== undefined && accountUpdated.success !== undefined) {
      dispatch(updateAccount(accountUpdated.account))
      if (isLastStep) {
        _submitForm(values, actions)
      } else {
        setActiveStep(activeStep + 1)
        actions.setTouched({})
        actions.setSubmitting(false)
      }
    }
  }

  async function _handleBack() {
    setCriticalErrors(false)
    setOpportunityValidationFailed(false)
    setIsSubmittingBack(true)
    let stepGap = 1
    if (activeStep === 3 && Object.keys(campaignMember).length === 0 && paymentType === 'JPC') {
      stepGap = 2
    }
    console.log('stepGap', stepGap)
    const newStep = activeStep - stepGap
    await dispatch(SalesforceUpdateStageStop({ payload, accountId, newStep }))
    setActiveStep(newStep)
    setIsSubmittingBack(false)
  }

  async function closeOpportunity (close) {
    // alert('oppy close')
    if (close) {
      const updateStageName = await Salesforce.updateStageName(payload, opportunity.Id, STAGE_COMPLETE)
        .catch(e => {
          console.log(e)
          // alert(e)
          setOpenPopup(false)
          if(e.toString().indexOf('FIELD_CUSTOM_VALIDATION_EXCEPTION') > -1) {
            setOpportunityValidationFailed(true)
          } else {
            setCriticalErrors(true)
          }
          // console.log(e.indexOf('FIELD_CUSTOM_VALIDATION_EXCEPTION'))
        })

      if (updateStageName) {
        dispatch(updateAcademicInformation([]))
        dispatch(updateAccount({}))
        dispatch(updateCampaignMember({}))
        dispatch(updateOpportunity({}))
        dispatch(removeOpportunities())
        dispatch(updateSupportingDocument([]))
        dispatch(updateWorkExperience([]))
        persistor.purge().then(() => {
          // document.location.href = '/'
          dispatch(isLogged())
          history.push(returnPageConfirmation)
        })
      }
    } else {
      setOpenPopup(false)
    }
  }

  const SpecialisedEducation = Salesforce.getSpecialisedEducation(account)
  const application = {
    campaignDisabled: true,
    campusDisabled: true,
    optionsCampus: [],
    // optionsPrograms: [],
    optionsSessions: [],
    optionsYears: [],
    sessionsDisabled: true,
    trackMajor: [],
    trackMajorDisabled: true,
    yearDisabled: true
  }
  // const opportunity = {
  //   AccountId: account.Id,
  //   CloseDate: Date.now(),
  //   OnlineApplication__c: true,
  //   RecordTypeId: '0129E000000qTq0QAE', // @todo ne pas mettre en dur
  //   Session__c: '',
  //   StageName: STAGE_QUALIFICATION
  // })

  return (
    <>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Stepper activeStep={activeStep} className={classes.stepper} nonLinear orientation="horizontal">
          {steps.map((label, index) =>(
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              {/* <StepButton color="inherit" onClick={handleStep(index)}>{label}</StepButton> */}
            </Step>
          ))}
        </Stepper>
      </Box>
      <>
        {activeStep === steps.length ? (
          <div>Bravo</div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{AcademicInformation__c, account, application, opportunities, opportunity, SpecialisedEducation, SupportingDocument__c, WorkExperience__c}}
            innerRef={formRef}
            onSubmit={_handleSubmit}
            validationSchema={currentValidationSchema}
          >
            {({ errors, isSubmitting, submitCount }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}
                {Object.keys(errors).length !== 0 && submitCount > 0 && <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={true}>
                  <Alert severity="error">Certains champs requis n'ont pas été remplis.</Alert>
                </Snackbar>}
                { criticalErrors && <Box mt={2}>
                  <Alert severity="error" variant="outlined">
                    <AlertTitle>Une erreur est survenue</AlertTitle>
                    Une erreur est survenue lors de la soumission du formulaire. Veuillez réessayer ou retenter un peu plus tard.
                  </Alert>
                </Box> }
                { opportunityValidationFailed && <Box mt={2}>
                  <Alert severity="error" variant="outlined">
                    <AlertTitle>Impossible de valider votre candidature</AlertTitle>
                    <Typography gutterBottom variant="body2">Il semblerait qu'il manque des pièces justificatives pour valider votre candidature. Merci de cliquer sur le bouton ci-dessous pour ajouter les pièces manquantes.</Typography>
                    <Button onClick={_handleBack} sx={{ mt: 2 }} variant="outlined">Ajouter des pièces justificatives</Button>
                  </Alert>
                </Box> }
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {buttonsVisible && <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <div className={classes.wrapper}>
                        <Button className={classes.button} disabled={isSubmittingBack || isSubmitting} onClick={_handleBack}>
                          Précédent
                        </Button>
                        {isSubmittingBack && (
                          <CircularProgress className={classes.buttonProgress} size={24} />
                        )}
                      </div>
                    )}
                    <div className={classes.wrapper}>
                      <Button color="primary" className={classes.button} disabled={isSubmitting || isSubmittingBack || buttonsDisabled} type="submit" variant="contained">
                        {isLastStep ? 'Confirmer ma candidature' : 'Suivant'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress className={classes.buttonProgress} size={24} />
                      )}
                    </div>
                  </div>
                }
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isSubmitting || isSubmittingBack}
                    // onClick={handleClose}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
                <MobileStepper
                  variant="dots"
                  steps={steps.length}
                  position="bottom"
                  activeStep={activeStep}
                  nextButton={<ButtonNext activeStep={activeStep} handleNext={_handleSubmit} isSubmitting={isSubmitting || !buttonsVisible} maxSteps={steps.length} />}
                  backButton={<ButtonBack activeStep={activeStep} handleBack={_handleBack} isSubmitting={isSubmitting || !buttonsVisible} isSubmittingBack={isSubmittingBack} />}
                  sx={{ display: { sm: 'none' } }}
                >
                </MobileStepper>
              </Form>
            )}
          </Formik>
        )}
        <ConfirmationApplication openPopup={openPopup} closeOpportunity={closeOpportunity} hasErrors={opportunityValidationFailed} />
      </>
    </>
  )
}

export default Portal
