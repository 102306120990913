import { CircularProgress, Grid } from "@mui/material"

const Loading = () => {
  return (
    <>
      <Grid alignItems="center" container direction="column" justifyContent="center">
        <Grid item xs={6}>
          <CircularProgress />
        </Grid>
        <Grid item xs={6}>
          Chargement du formulaire
        </Grid>
      </Grid>
    </>
  )
}

export default Loading
