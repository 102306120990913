import { Grid, Stepper, Step, StepLabel, Typography, StepContent, Box, Alert, Button } from "@mui/material"
import { ErrorMessage, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import 'moment/locale/fr'
import RadioButtonExample from "../../FormFields/RadioButtonExample"
import SelectField from "../../FormFields/SelectField"
import { TrackMajor, TrackProgram } from "../../TrackProgram"
import LogicApplication from "../../../services/LogicApplication"
import LogicYear from "../../../services/LogicYear"
import { CheckboxField } from "../../FormFields"
import { updatePaymentType } from "../../../Store/salesforceSlice"

const Application = (props) => {
  const {
    formField: {
      opportunity: {
        Session__c
      }
    }
  } = props

  const dispatch = useDispatch()
  const academicLevel = useSelector(state => state.salesforce.account.AcademicLevel__pc)
  const nationality = useSelector(state => state.salesforce.account.Nationalite__c)
  const sessions = useSelector(state => state.salesforce.sessions)
  const opportunities = useSelector(state => state.salesforce.opportunities)
  const hasPayed = useSelector(state => state.salesforce.statusPayment) === "OK"
  const paymentType = useSelector(state => state.salesforce.paymentType)
  const hasOneOppyCompleted = useSelector(state => state.salesforce.hasOneOppyCompleted)
  // const [activeStep, setActiveStep] = useState(0)
  const [optionsPrograms, setOptionsPrograms] = useState([])
  const [disableValueProgramme, setDisableValueProgramme] = useState([])
  const [disableValueCampus, setDisableValueCampus] = useState([])
  const [parcoursup, setParcoursup] = useState(false)
  const {
    values : {
      application: {
        campaignDisabled,
        campusDisabled,
        optionsCampus,
        optionsSessions,
        optionsYears,
        sessionsDisabled,
        yearDisabled,
      },
      campus,
      program,
      year
    }
  } = useFormikContext()

  const formikProps = useFormikContext()

  // Au démarrage
  useEffect(() => {
    const programs = LogicApplication.getPrograms(academicLevel, nationality)
    setOptionsPrograms(programs)
    checkExistingProgram()
  }, [])

  const yearFilled = (optionsCampus, program, year) => {
    formikProps.setFieldValue('application.campusDisabled', false)
    // On ne peut pas choisir de JPC si on est année 4 et + ou si le program et MSC et MBA
    if ((year === '4' && program === 'ISG BS France') || program === 'ISG MSC et MBA') {
      dispatch(updatePaymentType('Candidature'))
    } else {
      dispatch(updatePaymentType('JPC'))
    }
    if (optionsCampus.length === 1) {
      formikProps.setFieldValue('campus', optionsCampus[0].value)
      campusFilled(program, year, optionsCampus[0].value)
    }
    // On vérifie si un campus a déjà été sélectionné dans une autre candidature
    checkExistingCampus(program, year)
    // On vérifie si le programme / année est soumis à Parcoursup
    const formattedAcademicLevel = academicLevel.split('_')[0]
    const isParcoursup = LogicApplication.isParcoursup(program, formattedAcademicLevel, year)
    setParcoursup(isParcoursup)
  }

  const campusFilled = (program, year, campus) => {
    if (program !== 'ISG MSC et MBA') {
      formikProps.setFieldValue('application.sessionsDisabled', false)
      const _sessions = LogicApplication.getSessions(program, year, campus, sessions)
      formikProps.setFieldValue('application.optionsSessions', _sessions)
      if (_sessions.length === 1) {
        formikProps.setFieldValue('opportunity.Session__c', _sessions[0].value)
        sessionFilled()
      }
    }
  }

  const sessionFilled = () => {
    formikProps.setFieldValue('application.trackMajorDisabled', false)
    formikProps.setFieldValue('application.campaignDisabled', false)
  }

  const checkExistingCampus = (program, year) => {
    console.log('program', program)
    const existingOpportunities = opportunities.filter(el => el.Division__c === program && el.ProgramYear__c === year)
    if (existingOpportunities !== undefined) {
      let campus = []
      for (let i = 0; i < existingOpportunities.length; i++) {
        campus.push(existingOpportunities[i].SessionCampus__c)
      }
      console.log(campus)
      setDisableValueCampus(campus)
    }
  }

  const checkExistingProgram = () => {
    // On grise un programme si et seulement si le nombre de candidatures est supérieur au nombre de campus pour ce programme
    disableProgram('ISG BS France')
    disableProgram('ISG BS International')
    disableProgram('ISG PBM')
    disableProgram('ISG MSC et MBA')
  }

  const disableProgram = (division) => {
    const existingOpportunities = opportunities.filter(el => el.Division__c === division)
    const numberOfCampus = LogicYear.find(el => el.division === division).campus.length
    const years = LogicYear.find(el => el.division === division).years
    const academicLevelFilter = academicLevel.split('_')[0]
    const numberOfYears = years.filter(el => el.academicLevel.indexOf(academicLevelFilter) > -1).length
    if (existingOpportunities !== undefined && existingOpportunities.length > 0 && existingOpportunities.length === numberOfCampus * numberOfYears) {
      setDisableValueProgramme(oldArray => [...oldArray, existingOpportunities[0].Division__c])
    }
  }

  return (
    <>
      <Typography variant="h6">Ma candidature</Typography>
      {(opportunities.length === 0 || hasOneOppyCompleted) && <Grid container spacing={3}>
        <Grid item sm={12}>
          <Stepper orientation="vertical">
            <Step active={true}>
              <StepLabel>
                Je souhaite candidater au programme :
              </StepLabel>
              <StepContent>
              {optionsPrograms.length > 0 && <RadioButtonExample
                  disableValue={disableValueProgramme}
                  id="program"
                  name="program"
                  onChange={e => {
                    const { value } = e.target
                    // On reset les champs
                    formikProps.setFieldValue('year', '')
                    formikProps.setFieldValue('campus', '')
                    formikProps.setFieldValue('opportunity.Session__c', '')
                    formikProps.setFieldValue('opportunity.OwnerId', '')
                    formikProps.setFieldValue('opportunity.Track__c', '')
                    formikProps.setFieldValue('opportunity.TrackMajor__c', '')
                    formikProps.setFieldValue('application.optionsSessions', [])
                    formikProps.setFieldValue('application.campusDisabled', true)
                    formikProps.setFieldValue('application.sessionsDisabled', true)
                    console.log('**********************************', value)
                    const formattedAcademicLevel = academicLevel.split('_')[0]
                    const _yearCampus = LogicApplication.getYearCampus(value, formattedAcademicLevel)
                    const optionsCampus = _yearCampus.campus
                    const optionsYears = _yearCampus.years
                    const yearDisabled = optionsYears.length === 0

                    formikProps.setFieldValue('program', value)
                    formikProps.setFieldValue('application.optionsYears', optionsYears)
                    formikProps.setFieldValue('application.yearDisabled', yearDisabled)
                    formikProps.setFieldValue('application.optionsCampus', optionsCampus)

                    if (optionsYears.length === 1) { // Inutile pour le moment car quand je met direct la valeur j'aimerai que ça déclenche une fonction
                      formikProps.setFieldValue('year', optionsYears[0].value)
                      yearFilled(optionsCampus, value, optionsYears[0].value)
                    }
                  }}
                  options={optionsPrograms}
                  row
                />}
              {optionsPrograms.length === 0 && <Alert elevation={6} severity="error">Nous n'avons pas trouvé aucun programme correspondant à votre niveau d'étude et votre nationalité.</Alert>}
              </StepContent>
            </Step>
            {optionsPrograms.length > 0 && <Step active={true}>
              <StepLabel>
                en année :
              </StepLabel>
              <StepContent>
                <SelectField
                  data={optionsYears}
                  disabled={yearDisabled}
                  id="year"
                  fullWidth
                  label="Année d'entrée souhaitée"
                  name="year"
                  onChange={e => {
                    const { value } = e.target
                    // On reset les champs
                    formikProps.setFieldValue('campus', '')
                    formikProps.setFieldValue('application.optionsSessions', [])
                    formikProps.setFieldValue('opportunity.Session__c', '')
                    formikProps.setFieldValue('opportunity.OwnerId', '')

                    console.log('**********************************', value)


                    formikProps.setFieldValue('year', value)
                    yearFilled(optionsCampus, formikProps.values.program, value)
                  }}
                />
              </StepContent>
            </Step>}
            {optionsPrograms.length > 0 && <Step active={true}>
              <StepLabel>
                sur le campus de :
              </StepLabel>
              <StepContent>
                <SelectField
                  data={optionsCampus}
                  disabled={campusDisabled}
                  disableValue={disableValueCampus}
                  fullWidth
                  label="Campus"
                  name="campus"
                  onChange={e => {
                    const { value } = e.target
                    // On reset les champs
                    formikProps.setFieldValue('application.optionsSessions', [])
                    formikProps.setFieldValue('opportunity.Session__c', '')
                    formikProps.setFieldValue('opportunity.OwnerId', '')

                    formikProps.setFieldValue('campus', value)
                    campusFilled(formikProps.values.program, formikProps.values.year, value)
                  }}
                />
              </StepContent>
            </Step>}
            {/* Juste pour Msc & MBA */}
            {program === 'ISG MSC et MBA' && <Step active={true}>
              <StepLabel>
                en filière
              </StepLabel>
              <StepContent>
                <TrackProgram campus={campus} formField={props.formField} program={program} sessions={sessions} year={year} />
              </StepContent>
            </Step>}
            {optionsPrograms.length > 0 && <Step active={true}>
              <StepLabel>
                pour la rentrée de :
              </StepLabel>
              <StepContent>
                {(sessionsDisabled || optionsSessions.length > 0) && <SelectField
                  data={optionsSessions}
                  disabled={sessionsDisabled}
                  fullWidth
                  label={Session__c.label}
                  name={Session__c.name}
                  onChange={e => {
                    const { value } = e.target
                    formikProps.setFieldValue('opportunity.Session__c', value)
                    const commercialId = LogicApplication.getCommercialId(sessions, value)
                    console.log('commercialId', commercialId)
                    formikProps.setFieldValue('opportunity.OwnerId', commercialId)
                    sessionFilled()
                  }}
                />}
                {!sessionsDisabled && optionsSessions.length === 0 && <Alert elevation={6} severity="error">Nous n'avons pas trouvé de rentrée pour ce programme et ce campus.</Alert>}
              </StepContent>
            </Step>}
            {/* Juste pour Msc & MBA */}
            {program === 'ISG MSC et MBA' && <Step active={true}>
              <StepLabel>
                Sélectionnez au maximum 3 programmes
              </StepLabel>
              <StepContent>
                <TrackMajor formField={props.formField} year={year} />
              </StepContent>
            </Step>}
            {!hasPayed && paymentType === 'JPC' && <Step active={true}>
              <StepLabel>
                Participation aux Journées Préparation au Concours
              </StepLabel>
              <StepContent>
                <CheckboxField disabled={campaignDisabled} label="Je souhaite participer à une JPC" name="JPCWished" />
              </StepContent>
            </Step>}
          </Stepper>
        </Grid>
      </Grid>}
      <Box mt={2}>
        <ErrorMessage name={Session__c.name} render={msg => <Alert severity="error">{msg}</Alert>} />
      </Box>
      {opportunities.length > 0 && !hasOneOppyCompleted &&
      <Alert elevation={6} severity="info">
        Vous avez candidaté au programme suivant: {opportunities[0].ProgramFullName__c}. Si vous souhaitez apporter une modification, merci de compléter celle-ci.
      </Alert>}
      {parcoursup && (opportunities.length === 0 || hasOneOppyCompleted) && <Box sx={{ mt: 2}}>
        <Alert severity="info" variant="outlined">
          <p>Pour candidater aux Programme Grande École ou Bachelor en Management International de l'ISG en 1re année, merci de prendre connaissance des différents cas de figure présentés ci-dessous :<br /><br />

          <strong>Inscription en 1re année via Parcoursup</strong><br />
          L’admission en 1ère année qui se fait par le concours STEP est ouvert aux élèves de terminale ou déjà titulaires du baccalauréat en France métropolitaine et DOM-TOM, aux lycéens préparant le baccalauréat français et résidant à l’étranger et aux candidats déjà titulaires du baccalauréat français et résidant à l’étranger.
          L’inscription au concours STEP de l’ISG se fait sur Parcoursup entre mi-janvier et mi-mars 2022 où vous devez sélectionner votre vœux ISG Programme Grande Ecole ou Bachelor en Management International ou bien les deux.<br /><br />

          <strong>Inscription en 1re année hors Parcoursup</strong><br />
          L’admission en 1ère année hors Parcoursup se fait par le concours STEP pour les étudiants préparant un baccalauréat international et résidant à l’étranger ou les candidats déjà titulaires d’un baccalauréat international et résidant à l’étranger.<br />
          Pour les inscriptions en 1re année hors Parcoursup, merci de remplir le formulaire ci-dessous. Vous pourrez enregistrer votre candidature à tout moment et la compléter plus tard, ajouter des documents en pièces jointes et choisir de régler les frais de candidature par chèque ou par carte bancaire via un système de paiement sécurisé.</p>
        </Alert>
      </Box>}
    </>
  )
}

export default Application
