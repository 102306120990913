import hmacSHA512 from "crypto-js/hmac-sha512"
import CryptoJS from "crypto-js"
import { Alert, Button, CircularProgress, Typography } from "@mui/material"
import { CheckboxField } from "../../FormFields"
import { useDispatch, useSelector } from "react-redux"
import { show } from "../../../Store/buttonsSlice"
import useStyles from "../styles"
import { useEffect, useRef, useState } from "react"
import { useFormikContext } from "formik"
import { SalesforceGoToPayment, SalesforceUpdateStageStop, updateAccount, updateOpportunities, updateOpportunity } from "../../../Store/salesforceSlice"
import { persistStore } from "redux-persist"
import store from '../../../Store/Store'
import { Box } from "@mui/system"
import Salesforce from "../../../services/Salesforce"

const Payment = (props) => {

  const dispatch = useDispatch()
  const classes = useStyles()
  const refForm = useRef()
  const [priceEuro, setPriceEuro] = useState(0)
  const payload = useSelector(state => state.salesforce.token)
  const statusBank = useSelector(state => state.salesforce.statusPayment)
  // const opportunityId = useSelector(state => state.salesforce......)
  const opportunityId = useSelector(state => state.salesforce.opportunities[0].Id) // @todo ou alors récupérer opportunity.Id après création de l'oppy
  const opportunities = useSelector(state => state.salesforce.opportunities)
  const account = useSelector(state => state.salesforce.account)
  const price = useSelector(state => state.salesforce.applicationPrice)
  const scholar = useSelector(state => state.salesforce.account.Grant_Student__c)
  const hasOneOppyCompleted = useSelector(state => state.salesforce.hasOneOppyCompleted)
  const paymentJPC = useSelector(state => state.salesforce.paymentType) === 'JPC'
  const campaignMemberId = useSelector(state => state.salesforce.campaignMember.Id || state.salesforce.campaignMember.id)
  let persistor = persistStore(store)
  const {
    formField: {
      account: {
        CGV__c
      }
    }
  } = props
  const {
    values : {
      account: {
        CGV__c: CGV,
        Id,
        PersonEmail
      }
    }
  } = useFormikContext()

  const getRandomNumber = max => {
    return Math.floor(Math.random() * max).toString().padStart(6, '0')
  }

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pbxCmd, setPbxCmd] = useState('')
  const [pbxTime, setPbxTime] = useState('')
  const [pbxHmac, setPbxHmac] = useState('')
  const [pbxTotal, setPbxTotal] = useState(0)
  const pbxSite = process.env.REACT_APP_PAYBOX_SITE
  const pbxRang = process.env.REACT_APP_PAYBOX_RANG
  const pbxIdentifiant = process.env.REACT_APP_PAYBOX_IDENTIFIANT
  const pbxDevise = '978'
  const pbxShoppingCart = '<shoppingcart><total><totalQuantity>15</totalQuantity></total></shoppingcart>'
  const pbxPorteur = PersonEmail
  const pbxBilling = '<Billing><Address><FirstName>Jean</FirstName><LastName>Dupont</LastName><Address1>12 rue Paul Dautier</Address1><ZipCode>78140</ZipCode><City>Velizy-Villacoublay</City><CountryCode>250</CountryCode></Address></Billing>'
  const pbxRetour = 'montant:M;maref:R;auto:A;trans:T;paiement:P;carte:C;idtrans:S;erreur:E;date:W;heure:Q;sign:K'
  const pbxHash = 'SHA512'
  const pbxEffectue = process.env.REACT_APP_PAYMENT_EFFECTUE
  const pbxRefuse = process.env.REACT_APP_PAYMENT_REFUSE
  const pbxAnnule = process.env.REACT_APP_PAYMENT_ANNULE
  const pbxRepondreA = process.env.REACT_APP_PAYMENT_TRAITEMENT
  const hmac = process.env.REACT_APP_PAYMENT_HMAC

  useEffect(() => {
    // dispatch(hide())
    setPbxCmd('CAND-' + process.env.REACT_APP_SCHOOL + '-' + Id + '-' + getRandomNumber(899999))
    setPbxTime(new Date().toISOString())
  }, [Id])

  useEffect(() => {
    if (statusBank === 'OK') {
      dispatch(show())
    }
  }, [statusBank]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let finalPrice = 0
    if (scholar) {
      finalPrice = parseInt(price) / 2 / 100
    } else {
      finalPrice = parseInt(price) / 100
    }
    setPriceEuro(finalPrice)
    setPbxTotal(finalPrice + '00')
  }, [price, scholar])

  useEffect(() => {
    if (CGV) {
      const data = "PBX_SITE=" + pbxSite +
      "&PBX_RANG=" + pbxRang +
      "&PBX_IDENTIFIANT=" + pbxIdentifiant +
      "&PBX_TOTAL=" + pbxTotal +
      "&PBX_DEVISE=" + pbxDevise +
      "&PBX_CMD=" + pbxCmd +
      "&PBX_SHOPPINGCART=" + pbxShoppingCart +
      "&PBX_PORTEUR=" + pbxPorteur +
      "&PBX_BILLING=" + pbxBilling +
      "&PBX_RETOUR=" + pbxRetour +
      "&PBX_HASH=" + pbxHash +
      "&PBX_TIME=" + pbxTime +
      "&PBX_EFFECTUE=" + pbxEffectue +
      "&PBX_REFUSE=" + pbxRefuse +
      "&PBX_ANNULE=" + pbxAnnule +
      "&PBX_REPONDRE_A=" + pbxRepondreA;

      const hmac_bin = CryptoJS.enc.Hex.parse(hmac)

      const mac = hmacSHA512(data, hmac_bin)
      setPbxHmac(mac.toString().toUpperCase())
    }
    setButtonDisabled(!CGV)
  }, [CGV]) // eslint-disable-line react-hooks/exhaustive-deps

  const goToBank = e => {
    e.preventDefault()
    setButtonDisabled(true)
    setLoading(true)
    dispatch(SalesforceGoToPayment({ payload, opportunityId }))
    persistor.purge().then(() => {
      refForm.current.submit()
    })
  }

  const simulatePayment = async () => {
    console.log(account)
    const accountId = account.Id
    const newStep = parseInt(account.StageStop__c) + 1
    // const newAccount = Object.assign({}, account, { StageStop__c: newStep })
    // console.log(newAccount)
    const opportunityUpdated = await Salesforce.simulatePayment(payload, opportunities, accountId, campaignMemberId, hasOneOppyCompleted)
    dispatch(updateOpportunity(opportunityUpdated.opportunity))
    dispatch(updateOpportunities(opportunityUpdated.opportunities))
    // dispatch(updateAccount(newAccount))
    if (!hasOneOppyCompleted) {
      await dispatch(SalesforceUpdateStageStop({ payload, accountId, newStep }))
    }
  }

  return (
    <>
      <Typography variant="h5">
        {paymentJPC ? 'Paiement des frais de JPC' : 'Paiement des frais de candidature'}
      </Typography>
      {statusBank === 'reached_bank_website' && <Alert severity="info" sx={{ mt: 2 }}>{paymentJPC ? `Vous n'avez pas encore effectué le paiement des frais de JPC.` : `Vous n'avez pas encore effectué le paiement des frais de candidature.`} Cliquez sur le bouton ci-dessous pour effectuer le paiement des frais de candidature.</Alert> }
      {statusBank === 'KO' && <Alert severity="error" sx={{ mt: 2 }}>{paymentJPC ? 'Nous vous informons que le paiement de vos frais de JPC a échoué.' : 'Nous vous informons que le paiement de vos frais de candidature a échoué.'} Cliquez sur le bouton ci-dessous pour effectuer le paiement des frais de candidature.</Alert>}
      {statusBank === 'OK' && <Alert severity="success" sx={{ mt: 2 }}>{paymentJPC ? 'Nous avons bien reçu le paiement de vos frais de JPC.' : 'Nous avons bien reçu le paiement de vos frais de candidature.'} Cliquez sur le bouton suivant pour continuer.</Alert>}

      {statusBank !== 'OK' && <Box>
        <p>{paymentJPC ? 'Les frais de JPC' : 'Les frais de candidature'} sont de {priceEuro}€</p>

        <CheckboxField
          label={<div>En cochant cette case, je reconnais avoir pris connaissance des <a href={process.env.REACT_APP_URL_CGV} className={classes.link} rel="noreferrer" target="_blank">Conditions Générales de Vente</a> du site <a href={process.env.REACT_APP_SITE_URL} className={classes.link} rel="noreferrer" target="_blank">{process.env.REACT_APP_SITE_URL}</a> et je les accepte.</div>}
          name={CGV__c.name}
        />
        <form method="POST" action={process.env.REACT_APP_PAYBOX_URL} ref={refForm}>
          <input type="hidden" name="PBX_SITE" value={pbxSite} />
          <input type="hidden" name="PBX_RANG" value={pbxRang} />
          <input type="hidden" name="PBX_IDENTIFIANT" value={pbxIdentifiant} />
          <input type="hidden" name="PBX_TOTAL" value={pbxTotal} />
          <input type="hidden" name="PBX_DEVISE" value={pbxDevise} />
          <input type="hidden" name="PBX_CMD" value={pbxCmd} />
          <input type="hidden" name="PBX_SHOPPINGCART" value={pbxShoppingCart} />
          <input type="hidden" name="PBX_PORTEUR" value={pbxPorteur} />
          <input type="hidden" name="PBX_BILLING" value={pbxBilling} />
          <input type="hidden" name="PBX_RETOUR" value={pbxRetour} />
          <input type="hidden" name="PBX_HASH" value={pbxHash} />
          <input type="hidden" name="PBX_TIME" value={pbxTime} />
          <input type="hidden" name="PBX_EFFECTUE" value={pbxEffectue} />
          <input type="hidden" name="PBX_REFUSE" value={pbxRefuse} />
          <input type="hidden" name="PBX_ANNULE" value={pbxAnnule} />
          <input type="hidden" name="PBX_REPONDRE_A" value={pbxRepondreA} />
          <input type="hidden" name="PBX_HMAC" value={pbxHmac} />
          <Button onClick={simulatePayment} variant="contained">Simuler paiement en ligne</Button>
          <div className={classes.buttons}>
            <div className={classes.wrapper}>
              <Button color="primary" className={classes.button} disabled={buttonDisabled} onClick={goToBank} type="submit" variant="contained">
                {paymentJPC ? 'Payer les frais de JPC' : 'Payer les frais de candidature'}
              </Button>
              { loading && <CircularProgress className={classes.buttonProgress} size={24} /> }
            </div>
          </div>
        </form>
      </Box>}

    </>
  )
}

export default Payment
